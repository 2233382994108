import { axios } from '@/lib/axios';

export const getProductsByCategory = async (id: number) => {
    // const { data } = await axios.get(`v1/get-products-by-category/${id}`);
    // return data.products;
    return [
        {
            id: 6,
            product_category_id: 3,
            product_type_id: 1,
            product_name: 'Chocolate White Chocolate Chip',
            product_description:
                'Chocolate haters need not apply. Double the Chocolate. Double the deliciousness.',
            product_image:
                'https://res.cloudinary.com/oper-hotbox-cookies/image/upload/v1657015653/cookies/CWC_u1m9g1.png',
            product_upccode: '',
            product_active: 1,
            date_created: null,
            date_updated: '2022-11-07T09:56:58.000Z',
            price: 1.75,
        },
    ];
};
export const getLocaionProductsByCategory = async (id: number) => {
    const { data } = await axios.get(`v1/get-products-by-menu/${id}`);
    return data.products;
};
