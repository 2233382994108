// import { DeleteOutlineOutlined, Person } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

import OrderType from './OrderType';
import { Button } from '@/components/Elements';
import { handleDateFormat } from '@/utils/commonHelperFunc';

function ShowCorps({
    usersData,
    showList,
    closeDialog,
    handleSelected,
    SelectedData,
    handleOrder,
    orderData,
    showNewOrder,
    setOrderData,
}:any) {
    const [list, setList] = useState<string>(usersData);
    useEffect(() => {
        setList(usersData);
    }, [usersData]);

    return (
        <>
            {!SelectedData ? (
                <ul
                    className="showuser-list"
                    style={{
                        width: '100%',
                        maxHeight: '40vh',
                        boxShadow:
                            '0px 0px 4px rgba(0, 0, 0, 0.1), 0px 16px 20px rgba(0, 0, 0, 0.06)',
                        padding: '4px',
                        margin: '0 auto',
                        border: '1px solid #7AAD37',
                        overflow: 'auto',
                        marginBottom: '10px',
                        borderRadius: '6px',
                    }}
                >
                    {showList ? (
                        list.length > 0 ? (
                            list.map((user:any) => {
                                const { id, corporate_name, corporate_creation_date } = user;
                                return (
                                    <li key={id} onClick={() => handleSelected(user)}>
                                        {`${corporate_name} - ${handleDateFormat(
                                            corporate_creation_date
                                        )}`}
                                    </li>
                                );
                            })
                        ) : (
                            <li style={{ background: '#fff' }}>not found</li>
                        )
                    ) : null}
                </ul>
            ) : (
                <div className="view-user-details">
                    <div className="info-header" style={{ paddingBottom: '10px' }}>
                        <div className="left-info">
                            <i className="far  fa-user" style={{ marginRight: '8px' }}></i>
                            <h4>{SelectedData.corporate_name}</h4>
                        </div>
                    </div>
                    <ul className="user-details-list" style={{ paddingBottom: '15px' }}>
                        <li>
                            <strong>Phone:</strong> <span>{SelectedData.corporate_phone}</span>
                        </li>
                        <li>
                            <strong>Email:</strong> <span>{SelectedData.corporate_email}</span>
                        </li>
                        <li>
                            <strong>Created:</strong>
                            <span>{handleDateFormat(SelectedData.corporate_creation_date)}</span>
                        </li>
                        <li>
                            <strong>Last order:</strong>
                            <span>
                                {handleDateFormat(SelectedData.corporate_last_ordered) ||
                                    'no order'}
                            </span>
                        </li>
                    </ul>
                    <hr />
                    <OrderType
                        handleOrder={handleOrder}
                        orderData={orderData}
                        setOrderData={setOrderData}
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '1rem 0',
                        }}
                    >
                        <Button
                            style={{ width: 'fit-content' }}
                            data-bs-dismiss="modal"
                            onClick={() => showNewOrder(true)}
                            disabled={
                                !(orderData.order_type_id === '18'
                                    ? !!orderData.store && !!orderData.address
                                    : true)
                            }
                        >
                            Continue
                        </Button>
                        <Button
                            onClick={() => handleSelected(false)}
                            style={{ width: 'fit-content' }}
                            variant="secondary"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ShowCorps;
