import {
    CorporateFare,
    DeleteOutline,
    EditOutlined,
    PersonAddAltOutlined,
    StarBorderOutlined,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';

// import invoiceSrc from '../../../assets/invoice-ic.png';
import cardSrc from '../../../assets/user-card.png';
import { getCorpUsers } from '../api/getCorpUsers';
import { getLoyaltyData } from '../api/getLoyaltyData';
import { Corporation, User } from '../type';
import { updateUser } from '../api/updateUser';
import { getCorpOrders } from '../api/getCorpOrders';
import { getOrderDetails } from '../api/getOrderDetails';
import { handleDateFormat } from '@/utils/commonHelperFunc';
import Delete from '@/components/Icons/Delete';
import { Button, Spinner } from '@/components/Elements';
// import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

const ViewCorporation = ({
    corpData,
    closeDialog,
    selectedUserAction,
    setAddCredit,
    setViewCorp,
}) => {
    const [data, setData] = useState<Corporation | null>(null);
    const [corpUsers, setCorpUsers] = useState<User[]>([]);
    const [loyaltyData, setLoyaltyData] = useState<any>(null);
    const [orderData, setOrderData] = useState<any[]>([]);
    const [orderDetails, setOrderDetails] = useState<any>(null);
    const [corpCredits, setCorpCredits] = useState();
    const { addNotification } = useNotificationStore();
    const getCorpUsersFunc = async () => {
        const query = await getCorpUsers(corpData.id);
        setCorpUsers(query);
        const loyaltyQuery = await getLoyaltyData(corpData.id);
        setLoyaltyData(loyaltyQuery);
        const orderQuery = await getCorpOrders(corpData.id);
        setOrderData(orderQuery);
    };
    const getOrdeDetailsData = async () => {
        const orderDetailsQuery = await getOrderDetails(orderData[0]?.id);
        setOrderDetails(orderDetailsQuery);
    };
    const deleteCorpUser = async (id) => {
        await updateUser(id, { active: false });
        addNotification({
            type: 'success',
            title: 'User Deleted',
        });
    };
    useEffect(() => {
        setData(corpData);
        getCorpUsersFunc();
    }, [corpData]);

    useEffect(() => {
        if (orderData.length != 0) {
            getOrdeDetailsData();
        }
    }, [orderData]);

    // function for getting the dollar price
    const getDollarPrice = (priceInCents) => {
        if (priceInCents == 0) {
            return priceInCents;
        } else if (priceInCents == '-') {
            return '0.00';
        } else {
            return (priceInCents / 100).toFixed(2);
        }
    };
    //////////

    // get corp credits
    const getCorpCredits = async () => {
        // axios.get(`v1/get-corporation-credits/${corpData.id}`).then((res) => {
        //     setCorpCredits(res.data);
        // });
        setCorpCredits([
            {
                applied_credits: 10,
                date_created: '2022-11-01 06:03:00 pm',
                order_id: 3,
            },
            {
                applied_credits: 10,
                date_created: '2022-11-01 06:02:22 pm',
                order_id: 3,
            },
        ]);
    };
    /////////////

    useEffect(() => {
        getCorpCredits();
    }, []);

    return loyaltyData == null ? (
        <>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="h-full w-full flex items-center justify-center">
                        <Spinner size="xl" />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        Corporation
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <div
                            className="info-header"
                            style={{ paddingTop: '5px', paddingBottom: '22px' }}
                        >
                            <div className="left-info">
                                <CorporateFare className="pColor" />
                                <h4>{data?.corporate_name}</h4>
                                <p>
                                    <span className="vip-tx"> VIP</span>
                                </p>
                            </div>
                        </div>
                        <ul className="user-details-list" style={{ paddingBottom: '10px' }}>
                            <li>
                                <strong>Email:</strong> <span>{data?.corporate_email}</span>
                            </li>
                            <li>
                                <strong>Tax Exemp:</strong>{' '}
                                <span>{data?.tax_exempt == 1 ? 'Yes' : 'No'}</span>
                            </li>
                            <li>
                                <strong>Phone:</strong> <span>{data?.corporate_phone}</span>
                            </li>
                            <li>
                                <strong>Tax ID:</strong> <span>{data?.corporate_tax_id}</span>
                            </li>
                            <li>
                                <strong>Created:</strong>
                                <span>{handleDateFormat(data?.corporate_creation_date)}</span>
                            </li>
                            <li>
                                <strong>Last order:</strong> <span></span>
                            </li>
                            <li>
                                <strong>Active:</strong>
                                <span>{data?.corporate_active ? 'Yes' : 'No'}</span>
                            </li>
                        </ul>
                        <p className="view-notes-tx" style={{ marginBottom: '28px' }}>
                            <strong>Notes: </strong>
                            {data?.notes || 'No note!'}
                        </p>

                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOne"
                                    >
                                        <p className="box-cookie-tx">
                                            <span>
                                                <strong>Associated Users: </strong>
                                                <span className="dark-tx">{corpUsers.length}</span>
                                            </span>
                                            <span
                                                className="dark-tx"
                                                onClick={() =>
                                                    selectedUserAction({
                                                        action: 'add-user',
                                                        data: data.id,
                                                    })
                                                }
                                            >
                                                <PersonAddAltOutlined className="pColor" />
                                                Add User
                                            </span>
                                        </p>
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body">
                                        <ul className="corporation-list">
                                            {corpUsers.map(
                                                ({
                                                    id,
                                                    user_birthday,
                                                    first_name,
                                                    last_name,
                                                    user_email,
                                                    is_primary,
                                                }) => (
                                                    <li
                                                        key={id}
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: is_primary
                                                                    ? '#FFAF60'
                                                                    : 'transparent',
                                                                position: 'absolute',
                                                                left: '0',
                                                            }}
                                                        >
                                                            <StarBorderOutlined />
                                                        </span>
                                                        <span
                                                            style={{
                                                                paddingLeft: '24px',
                                                                fontWeight: '600',
                                                            }}
                                                        >
                                                            {first_name} {last_name}
                                                        </span>
                                                        <span>{user_birthday}</span>
                                                        <span>{user_email}</span>
                                                        <span
                                                            className="!cursor-pointer"
                                                            style={{
                                                                color: '#7AAD37',
                                                            }}
                                                            onClick={() => {
                                                                deleteCorpUser(id);
                                                                closeDialog();
                                                            }}
                                                        >
                                                            <DeleteOutline />
                                                        </span>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwo"
                                    >
                                        <p className="box-cookie-tx">
                                            <span>
                                                <strong>Credit:</strong>
                                                <span className="dark-tx">
                                                    $
                                                    {corpCredits?.points
                                                        ? getDollarPrice(corpCredits?.points || 0)
                                                        : 0}
                                                </span>
                                            </span>
                                            <span className="dark-tx d-flex align-items-center">
                                                <img
                                                    className={'invoive-img'}
                                                    src={cardSrc}
                                                    alt="cardIcon"
                                                />
                                                Add Credit
                                            </span>
                                        </p>
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingTwo"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body">
                                        <button
                                            onClick={() => {
                                                setAddCredit(corpData);
                                                setViewCorp(null);
                                            }}
                                        >
                                            Add Credit
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFour">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFour"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFour"
                                    >
                                        <p className="box-cookie-tx">
                                            <span>
                                                <strong>Last Order:</strong>
                                                <span className="dark-tx">
                                                    #
                                                    {orderData
                                                        ? orderData[0]?.id || 'No order'
                                                        : 'No order'}
                                                </span>
                                                <span style={{ color: 'rgba(17, 17, 17, 0.65)' }}>
                                                    /{' '}
                                                    {orderData
                                                        ? orderData[0]?.order_creation_date || ''
                                                        : ''}{' '}
                                                </span>
                                            </span>
                                            <span className="add-tx">
                                                $
                                                {orderData.length != 0
                                                    ? getDollarPrice(orderData[0]?.order_total) ||
                                                      '0'
                                                    : '0'}
                                            </span>
                                        </p>
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseFour"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingFour"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body">
                                        <ul className="order-listing">
                                            {orderDetails
                                                ? orderDetails.items.map((item) => (
                                                      <li key={item.id}>
                                                          <p className="cookie-flx">
                                                              <strong>{item.product_name}</strong>
                                                              <span>
                                                                  {item.menu_item_quantity} x{' '}
                                                                  <strong>
                                                                      $
                                                                      {getDollarPrice(
                                                                          item.menu_item_price
                                                                      )}
                                                                  </strong>
                                                              </span>
                                                          </p>
                                                      </li>
                                                  ))
                                                : ''}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFive">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFive"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFive"
                                    >
                                        <p className="box-cookie-tx">
                                            <span>
                                                <strong>Loyalty Points:</strong>
                                                <span className="dark-tx">#3347895</span>
                                            </span>
                                            <span className="add-tx">
                                                {loyaltyData.points} points
                                            </span>
                                        </p>
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseFive"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingFive"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body">
                                        <ul className="order-listing">
                                            {loyaltyData?.history?.map(
                                                ({ applied_points, date_created, order_id }) => (
                                                    <li key={order_id}>
                                                        <p className="cookie-flx">
                                                            <strong>{applied_points} points</strong>
                                                            <span>
                                                                {handleDateFormat(date_created)}
                                                            </span>
                                                        </p>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        onClick={() =>
                            selectedUserAction({
                                action: 'edit',
                                data: data,
                            })
                        }
                        startIcon={<EditOutlined />}
                        style={{ width: 'fit-content' }}
                    >
                        Edit information
                    </Button>
                    <Button
                        startIcon={<Delete />}
                        style={{ width: 'fit-content' }}
                        variant="secondary"
                        onClick={() =>
                            selectedUserAction({
                                action: 'delete',
                                data: data,
                            })
                        }
                    >
                        Delete Corporation
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ViewCorporation;
