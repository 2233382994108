import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import editCheck from '../../../assets/edit-check.png';

import {
    Button,
    // Spinner
} from '@/components/Elements';
import {
    Form,
    // InputField
} from '@/components/Form';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({
    multiplier: z.string().nonempty('Please enter multiplier'),
    base: z.string().nonempty({ message: 'Please enter a valid base' }),
    active: z.number(),
});

type LoginValues = {
    order_type: string;
    location_name: string;
    category_name: string;
    multiplier: number;
    base: number;
    active: number;
};

const EditLoyalty = ({ loyaltyData, closeDialog, loyaltyQuery }) => {
    const intialLoyaltyData = {
        id: loyaltyData.id,
        category_name: loyaltyData.category_name,
        order_type: loyaltyData.subcategory,
        location_name: loyaltyData.location_name,
        multiplier: loyaltyData.multiplier,
        base: loyaltyData.base,
        active: loyaltyData.active,
    };
    const [data, setData] = useState(intialLoyaltyData);

    useEffect(() => {}, []);

    const updateLoyalty = async (data: any) => {
        const updateData = {
            id: data.id,
            multiplier: data.multiplier,
            base: data.base,
            active: data.active == true ? true : false,
        };

        const { success, message } = await axios.post(
            `v1/admin/loyalties/update-loyally-multiplier`,
            updateData
        );
        showSuccess();
        loyaltyQuery.refetch();
        return { success, message };
    };

    const showSuccess = () => {
        useNotificationStore.getState().addNotification({
            type: 'success',
            title: 'Success',
            message: 'Loyalty data updated!',
        });
    };

    const handleSubmit = async () => {
        updateLoyalty(data).then().catch();
        closeDialog();
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        switch (name) {
            case 'active':
                setData({ ...data, [name]: e.target.checked });
                break;
            default:
                setData({ ...data, [name]: value });
                break;
        }

        // setData({ ...data, [name]: value });
    };
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="invoiceModalLabel">
                        Edit {data.category_name} {data.order_type}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="add-user-form">
                        <Form<LoginValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
                            {({ register, formState }) => (
                                <>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="transaction_type"
                                                className="control-label"
                                            >
                                                Category Name
                                            </label>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="transaction_type"
                                                    required
                                                    value={data.category_name}
                                                    readOnly
                                                    error={formState.errors['category_name']}
                                                    registration={register('category_name')}
                                                >
                                                    <option value="">{data.category_name}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="order_type" className="control-label">
                                                Order Type
                                            </label>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="order_type"
                                                    required
                                                    value={data.order_type}
                                                    readOnly
                                                    error={formState.errors['order_type']}
                                                    registration={register('order_type')}
                                                >
                                                    <option value="">{data.order_type}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="multiplier" className="control-label">
                                                Multiplier
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="multiplier"
                                                    value={data.multiplier}
                                                    placeholder="Multiplier"
                                                    onChange={handleChange}
                                                    max={100}
                                                    required
                                                />
                                            </div>
                                            {/* <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="multiplier"
                                                    required
                                                    onChange={handleChange}
                                                    value={data.multiplier}
                                                    error={formState.errors['multiplier']}
                                                    registration={register('multiplier')}
                                                >
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                </select>
                                            </div> */}
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="base" className="control-label">
                                                Base
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Multiplier"
                                                    onChange={handleChange}
                                                    required
                                                    name="base"
                                                    max={100}
                                                    value={data.base}
                                                />
                                            </div>
                                            {/* <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="base"
                                                    required
                                                    onChange={handleChange}
                                                    value={data.base}
                                                    error={formState.errors['base']}
                                                    registration={register('base')}
                                                >
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                </select>
                                            </div> */}
                                        </div>

                                        <div className="col-md-6">
                                            <label
                                                htmlFor="location_name"
                                                className="control-label"
                                            >
                                                Location
                                            </label>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="location_name"
                                                    required
                                                    value={data.location_name}
                                                    readOnly
                                                    error={formState.errors['location_name']}
                                                    registration={register('location_name')}
                                                >
                                                    <option value="">{data.location_name}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex gap-3">
                                        <label htmlFor="active">Active:</label>
                                        {data?.active ? 'Yes' : 'No'}
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckChecked"
                                                name="active"
                                                defaultChecked={data?.active || false}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="modal-footer"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            startIcon={<img src={editCheck} alt="editCheck" />}
                                            style={{ width: 'fit-content' }}
                                            onClick={() => handleSubmit()}
                                        >
                                            Save Changes
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => closeDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditLoyalty;
