// import { AddCircleOutline } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { getAllWebeditor, useWebeditor } from '../api/CRUD/getAllWebeditor';
// import EditWebeditor from '../components/edit';
// import ViewWebeditor from '../components/view';
import { dataFields, HeadCells } from '../constant';
import { Webeditor } from '../type';
import { useNotificationStore } from '@/stores/notifications';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
// import TableWithSort from '@/components/Elements/Table/TableWithSort';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { Confirm } from '@/components/Elements/Dialog/Confirm';
import { Button, Dialog, Spinner } from '@/components/Elements';
//import { HeadCells } from '@/features/users/constant';

import '../styles/styles.css';
import { axios } from '@/lib/axios';

export const WebEditorPage = () => {
    const { close, open, isOpen } = useDisclosure();
    const { addNotification } = useNotificationStore();
    const webeditorQuery = useWebeditor();

    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [webeditor, setWebeditor] = useState<Webeditor[]>([]);
    const [createWebeditor, setCreateWebeditor] = useState<boolean>(false);
    const [editWebeditor, setEditWebeditor] = useState<Webeditor | null>(null);
    const [viewWebeditor, setviewWebeditor] = useState<Webeditor | null>(null);
    const [locationFilter, setLocationFilter] = useState();
    const [confirmMsg, setConfirmMsg] = useState<User | null>(null);

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    async function getWebeditor() {
        setIsLoading(true);
        const resMod = [];
        const res = await getAllWebeditor();

        setWebeditor(res);
        console.log(webeditor);
        setIsLoading(false);
    }
    useEffect(() => {
        getWebeditor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webeditorQuery.data, close]);

    if (webeditorQuery.isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!webeditor) return null;

    const filteredEmployees = webeditor?.filter((webeditor: Webeditor) => {
        if (searchVal || locationFilter) {
            if (webeditor?.location_name === locationFilter) {
                return webeditor;
            } else if (webeditor?.location_name === locationFilter) {
                return webeditor;
            }
            if (searchVal) {
                return webeditor?.location_name?.toLowerCase()?.includes(searchVal.toLowerCase());
            }
        } else {
            return webeditor;
        }
    });

    const deleteWebeditor = async (id) => {
        const updateData = {
            active: 0,
        };

        const { success, message } = await axios.post(`v1//${id}`, updateData);
        webeditorQuery.refetch();
        return { success, message };
    };

    const handleDelete = async (webeditorData: Webeditor) => {
        await deleteWebeditor(webeditorData.id).then(() => {
            addNotification({
                type: 'success',
                title: 'Webeditor Deleted',
            });
        });
        getWebeditor();
        setConfirmMsg(null);
    };

    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case 'delete':
                setConfirmMsg(data);
                break;
            default:
                return null;
        }
    };

    const closeDialog = () => {
        setviewWebeditor(null);
        setEditWebeditor(null);
        setCreateWebeditor(false);
        close();
    };

    const LocationOptions = new Set(webeditor?.map((item) => item?.location_name));

    return (
        <ContentLayout title="" topNavBarData={{ num: webeditor.length, title: 'Webeditor' }}>
            {confirmMsg ? (
                <Confirm
                    btnTitle={'Delete'}
                    onClose={() => setConfirmMsg(null)}
                    onSuccess={() => handleDelete(confirmMsg)}
                />
            ) : null}
            <div className="table-header">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="flex pos-devces">
                                <div className="input-group search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        value={searchVal}
                                        size={50}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <CustomTable
                tableData={filteredEmployees}
                headCells={HeadCells}
                dataFields={dataFields}
                selectedUserAction={selectedUserAction}
                // rowOptions={true}
                // optionMenu={['edit', 'delete']}
            />
            <Dialog isOpen={isOpen} onClose={closeDialog} paperWidth={'60%'}>
                <div className="modals-contents">
                    {/* {createWebeditor && <CreatePos closeDialog={closeDialog} />} */}
                    {/* {viewWebeditor && (
                        <ViewWebeditor
                            webeditorData={viewWebeditor}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )} */}
                    {/* {editWebeditor && <EditWebeditor webeditorQuery={webeditorQuery} webeditorData={editWebeditor} closeDialog={closeDialog} />} */}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
