import { UserResponse } from '../types';
import { axios } from '@/lib/axios';

export type LoginCredentialsDTO = {
    user_email: string;
    user_password: string;
};

export const loginWithEmailAndPassword = (data: LoginCredentialsDTO): Promise<UserResponse> => {
    return axios.post('/v1/auth/admin-login', data);
    // return axios.post('/v1/login', data);
};
