// import { CorporateFare, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';

// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import moment from 'moment';
import { Menu, User } from '../type';
import { Button } from '@/components/Elements';
import Delete from '@/components/Icons/Delete';
// import { handleDateFormat } from '@/utils/commonHelperFunc';

// import { axios } from '@/lib/axios';

const ViewReports = ({ inquiryData, closeDialog, selectedUserAction }) => {
    const [data, setData] = useState<Menu | null>(null);

    useEffect(() => {
        setData(inquiryData);
    }, [inquiryData]);

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        {data?.req_type} #{data?.id}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <div className="flex justify-between">
                            <div>
                                <strong>Created:</strong>{' '}
                                {moment(data?.created_at).format('MM/DD/YYYY, HH:mm A')}
                            </div>
                            <div>
                                <strong>{data?.inquiry_active ? 'Active' : 'Deleted'}</strong>
                            </div>
                        </div>

                        <hr className="mt-2 mb-4" />
                        {data?.req_type == 'Custom Order Request' && (
                            <div>
                                <ul className="" style={{ paddingBottom: '10px' }}>
                                    <li>
                                        <strong>Full Name:</strong> <span>{data?.customer}</span>
                                    </li>
                                    <li>
                                        <strong>Email:</strong> <span>{data?.custom_email}</span>
                                    </li>
                                    <li>
                                        <strong>Phone:</strong> <span>{data?.custom_phone}</span>
                                    </li>
                                </ul>
                                <hr className="mt-2 mb-4" />
                                <strong>What type of order request is this?</strong>
                                <p>{data?.custom_request_type}</p>
                                <hr className="mt-2 mb-4" />
                                <strong>What type of order is this?</strong>
                                <p>{data?.custom_order_type}</p>
                                <hr className="mt-2 mb-4" />
                                <strong>What is your desired quantities of coockies?</strong>
                                <p>{data?.custom_cookie_quantity}</p>
                                <hr className="mt-2 mb-4" />
                                <strong>Tell us more about your order</strong>
                                <p>{data?.custom_special_instructions}</p>
                            </div>
                        )}
                        {data?.req_type == 'Corporate Account Request' && (
                            <div>
                                <strong>Company Information</strong>
                                <hr className="mt-2 mb-4" />
                                <ul className="mb-2" style={{ paddingBottom: '10px' }}>
                                    <li>
                                        <strong>Company:</strong>{' '}
                                        <span>{data?.corporate_request_corporate_name}</span>
                                    </li>
                                    <li>
                                        <strong>Primary Contact:</strong>{' '}
                                        <span>{data?.corporate_request_corporate_name}</span>
                                    </li>
                                    <li>
                                        <strong>Position:</strong>{' '}
                                        <span>{data?.corporate_request_user_position}</span>
                                    </li>
                                    <li>
                                        <strong>Email:</strong>{' '}
                                        <span>{data?.corporate_request_email}</span>
                                    </li>
                                    <li>
                                        <strong>Phone:</strong>{' '}
                                        <span>{data?.corporate_request_phone}</span>
                                    </li>
                                </ul>
                                <strong>Billing Information</strong>
                                <hr className="mt-2 mb-4" />
                                <ul className="" style={{ paddingBottom: '10px' }}>
                                    <li>
                                        <strong>Address 1:</strong>{' '}
                                        <span>{data?.corporate_request_address_1}</span>
                                    </li>
                                    <li>
                                        <strong>Address 2:</strong>{' '}
                                        <span>{data?.corporate_request_address2}</span>
                                    </li>
                                    <li>
                                        <strong>City :</strong>{' '}
                                        <span>{data?.corporate_request_city}</span>
                                    </li>
                                    <li>
                                        <strong>State:</strong>{' '}
                                        <span>{data?.corporate_request_state}</span>
                                    </li>
                                    <li>
                                        <strong>Zip:</strong>{' '}
                                        <span>{data?.corporate_request_zip}</span>
                                    </li>
                                </ul>
                            </div>
                        )}
                        {data?.req_type == 'Contact Inquiries' && (
                            <div>
                                <ul className="" style={{ paddingBottom: '10px' }}>
                                    <li>
                                        <strong>First Name:</strong>{' '}
                                        <span>{data?.contact_full_name.split(' ')[0]}</span>
                                    </li>
                                    <li>
                                        <strong>Last Name:</strong>{' '}
                                        <span>{data?.contact_full_name.split(' ')[1]}</span>
                                    </li>
                                    <li>
                                        <strong>Email:</strong> <span>{data?.contact_email}</span>
                                    </li>
                                    <li>
                                        <strong>Phone:</strong> <span>{data?.contact_phone}</span>
                                    </li>
                                </ul>
                                <hr className="mt-2 mb-4" />
                                <strong>Give us the details</strong>
                                <hr className="mt-2 mb-4" />
                                <p>{data?.contact_comment}</p>
                            </div>
                        )}

                        {data?.req_type == 'Donation Request' && (
                            <div>
                                <div className="flex gap-4">
                                    <div className="flex-1">
                                        <strong>Customer</strong>
                                        <hr className="mt-2 mb-4" />
                                        <ul className="" style={{ paddingBottom: '10px' }}>
                                            <li>
                                                <strong>First Name:</strong>{' '}
                                                <span>{data?.donation_first_name}</span>
                                            </li>
                                            <li>
                                                <strong>Last Name:</strong>{' '}
                                                <span>{data?.donation_last_name}</span>
                                            </li>
                                            <li>
                                                <strong>Email:</strong>{' '}
                                                <span>{data?.donation_email}</span>
                                            </li>
                                            <li>
                                                <strong>Phone:</strong>{' '}
                                                <span>{data?.donation_phone}</span>
                                            </li>
                                            <li>
                                                <strong>City:</strong>{' '}
                                                <span>{data?.donation_city}</span>
                                            </li>
                                        </ul>

                                        <strong>Event Information</strong>
                                        <hr className="mt-2 mb-4" />
                                        <ul className="" style={{ paddingBottom: '10px' }}>
                                            <li>
                                                <strong>Event:</strong>{' '}
                                                <span>{data?.donation_event_name}</span>
                                            </li>
                                            <li>
                                                <strong>Website:</strong>{' '}
                                                <span>{data?.donation_event_site}</span>
                                            </li>
                                        </ul>
                                        <strong>Event Description</strong>
                                        <p>{data?.donation_event_description}</p>
                                        <ul className="" style={{ paddingBottom: '10px' }}>
                                            <li>
                                                <strong>Event Date:</strong>{' '}
                                                <span>
                                                    {moment(data?.donation_event_datetime).format(
                                                        'MM/DD/YYYY'
                                                    )}
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Event Time:</strong>{' '}
                                                <span>
                                                    {moment(data?.donation_event_datetime).format(
                                                        'HH:mm A'
                                                    )}
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Event Location:</strong>{' '}
                                                <span>{data?.donation_event_location_name}</span>
                                            </li>
                                        </ul>
                                        <strong>Event Location Address</strong>
                                        <p>{data?.donation_event_location_address}</p>
                                        <strong>Number of People Attending</strong>
                                        <p>{data?.donation_event_attendance}</p>
                                    </div>
                                    <div className="flex-1">
                                        <strong>Organization Information</strong>
                                        <hr className="mt-2 mb-4" />
                                        <ul className="" style={{ paddingBottom: '10px' }}>
                                            <li>
                                                <strong>Organization:</strong>{' '}
                                                <span>{data?.donation_company}</span>
                                            </li>
                                            <li>
                                                <strong>Website:</strong>{' '}
                                                <span>{data?.donation_company_site}</span>
                                            </li>
                                        </ul>
                                        <strong>
                                            Is your organization a 501(c)(3) (non-profit
                                            organization)?
                                        </strong>
                                        <p>No</p>
                                        <ul>
                                            <li>
                                                <strong>Tax form/w9:</strong> <span>zxv.file</span>
                                            </li>
                                        </ul>

                                        <strong>Additional</strong>
                                        <hr className="mt-2 mb-4" />
                                        <strong>
                                            Has Health Nut Cookies donated to your organization
                                            before?
                                        </strong>
                                        <p>{data?.donation_is_previous_done ? 'Yes' : 'No'}</p>
                                        <strong>
                                            Will Health Nut Cookies be the exclusive DESSERT
                                            offering at your event?
                                        </strong>
                                        <p>{data?.donation_is_hbexclusivedessert ? 'Yes' : 'No'}</p>
                                        <strong>
                                            Will Health Nut Cookies be the exclusive COOKIE offering
                                            at your event?
                                        </strong>
                                        <p>{data?.donation_is_hbexclusivecookie ? 'Yes' : 'No'}</p>
                                        <strong>Desired Donation</strong>
                                        <p>{data?.donation_desired}</p>
                                        <strong>Additional Comments</strong>
                                        <p>{data?.donation_comments}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        onClick={() =>
                            selectedUserAction({
                                action: 'edit',
                                data: data,
                            })
                        }
                        startIcon={''}
                        style={{ width: 'fit-content' }}
                    >
                        Mark as Unread
                    </Button>
                    <Button
                        startIcon={<Delete />}
                        style={{ width: 'fit-content' }}
                        variant="secondary"
                        onClick={() =>
                            selectedUserAction({
                                action: 'delete',
                                data: data,
                            })
                        }
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ViewReports;
