import { CorporateFare } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';


import { useCorp } from '../api/CRUD/getCorp';
import { Corporation } from '../type';

import ShowUsers from './ShowUsers';
import { useUsers } from '@/features/users/api/getUsers';

const AddUser = ({ corpId, closeDialog }) => {
    const usersQuery = useUsers();
    const corpQuery = useCorp(corpId);
    const [searchVal, setSearchVal] = useState<string>('');
    const [corpData, setCorpData] = useState<Corporation | null>(null);

    const handleGetCorp = () => {
        if (!corpQuery.isLoading) setCorpData(corpQuery?.data);
    };

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    useEffect(() => {
        handleGetCorp();
    }, [corpQuery.isLoading, usersQuery.isLoading]);

    if (corpQuery.isLoading || usersQuery.isLoading) {
        return (
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Add user / Corporation
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => closeDialog()}
                        ></button>
                    </div>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </div>
            </div>
        );
    }

    const filteredUsers = usersQuery?.data?.filter((user) => {
        return (
            user?.first_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
            user?.last_name?.toLowerCase()?.includes(searchVal.toLowerCase())
        );
    });

    return (
        <>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Add user / Corporation
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => closeDialog()}
                        ></button>
                    </div>

                    <div className="modal-body">
                        <div className="add-user-head d-flex align-items-center">
                            <CorporateFare className="pColor" />
                            <h5 className="add">{corpData?.corporate_name}</h5>
                            <span className="vip-tx">VIP</span>
                        </div>
                        <div className="input-group search">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search Existing User"
                                value={searchVal}
                                onChange={(e) => handleSearch(e)}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i className="fas fa-search"></i>
                                </span>
                            </div>
                            <div className="input-group-close">
                                <span className="input-group-text">
                                    <i className="fas fa-times"></i>
                                </span>
                            </div>
                        </div>

                        {usersQuery?.data && (
                            <ShowUsers
                                usersData={filteredUsers}
                                corpId={corpId}
                                showList={searchVal ? true : false}
                                closeDialog={closeDialog}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddUser;
