import * as React from 'react';

import { Head } from '../Head';

import TopNavBar from './TopNavBar';

type ContentLayoutProps = {
    children: React.ReactNode;
    title: string;
    topNavBarData: {
        num: number;
        title: string;
    };
};

export const ContentLayout = ({ children, title, topNavBarData }: ContentLayoutProps) => {
    return (
        <>
            <Head title={title} />
            <TopNavBar topNavBarData={topNavBarData} />
            <div className="content-area">
                {title && (
                    <div className="mx-auto">
                        <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
                    </div>
                )}
                <div className="mx-auto">{children}</div>
            </div>
        </>
    );
};
