//import { useQuery } from 'react-query';

import { Corporation } from '../type';
// import { axios } from '@/lib/axios';

//import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getCorpUsers = async (id: number): Promise<Corporation[]> => {
    // const { data } = await axios.get(`v1/get-corporation-users/${id}`);
    // return data.users;
    return [
        {
            id: 7,
            corporate_id: 1,
            user_id: 22,
            is_primary: 0,
            date_created: '2022-09-27T07:03:13.000Z',
            date_updated: '2022-10-17T01:35:33.000Z',
            active: 1,
            first_name: 'Nitin',
            last_name: 'jha',
            user_email: 'nitintest@gmail.com',
            user_phone: '9694215262',
            user_birthday: '2022-05-07',
            user_active: 1,
        },
        {
            id: 8,
            corporate_id: 1,
            user_id: 63,
            is_primary: 0,
            date_created: '2022-10-17T00:50:39.000Z',
            date_updated: null,
            active: 1,
            first_name: 'frontend',
            last_name: 'test1',
            user_email: 'frontend@test1.com',
            user_phone: '9998887770',
            user_birthday: '1999-09-09',
            user_active: 0,
        },
    ];
};

//type QueryFnType = typeof getAllCorps;

//type UseCorpsOptions = {
//    config?: QueryConfig<QueryFnType>;
//};

//export const useCorps = ({ config }: UseCorpsOptions = {}) => {
//    return useQuery<ExtractFnReturnType<QueryFnType>>({
//        ...config,
//        queryKey: ['corps'],
//        queryFn: () => getAllCorps(),
//    });
//};
