// import { CorporateFare, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import * as z from 'zod';

// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { Menu, User } from '../type';
import { Form, InputField } from '@/components/Form';
import { Button, Spinner } from '@/components/Elements';
// import Delete from '@/components/Icons/Delete';
// import { handleDateFormat } from '@/utils/commonHelperFunc';

import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({});

type LoginValues = {
    menu_name: string;
    location_id: string;
    activation_time: string;
    expiration_time: string;
    status: boolean;
};

const CreateMenu = ({ menuData, action, closeDialog, selectedUserAction, getMenu }) => {
    const intialMenuData = {
        menu_group: menuData.id,
        menu_name: menuData?.menu_group_name || '',
        location_id: 1,
        activation_time: menuData?.activation_time
            ? menuData?.activation_time.split('T')[0]
            : '2023-06-22',
        expiration_time: menuData?.expiration_time
            ? menuData?.expiration_time.split('T')[0]
            : '2023-06-28',
        status: true,
    };
    const [data, setData] = useState(intialMenuData);
    const [products, setProducts] = useState([]);
    const [locations, setLocations] = useState();

    console.log(data);

    useEffect(() => {
        getAllLocations();
    }, []);

    //get all locations
    const getAllLocations = async () => {
        const { data } = await axios.get(`v1/admin/locations/get-locations`);
        setLocations(data.locations);
    };

    //////

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const createMenu = async (data) => {
        const { success, message } = await axios.post(``, data);
        return { success, message };
    };

    const handleSubmit = async () => {
        if (action === 'create') {
            axios
                .post('v1/admin/menus/add-menu-groups', {
                    name: data.menu_name,
                    active: true,
                })
                .then(() => {
                    getMenu();
                    closeDialog();
                    showSuccess('New Menu Added!');
                })
                .catch();
        } else if (action === 'copy') {
            axios
                .post('v1/admin/menus/add-menu-live', {
                    location_id: data.location_id,
                    menu_group: data.menu_group,
                    activation_time: data.activation_time,
                    expiration_time: data.expiration_time,
                })
                .then(() => {
                    getMenu();
                    closeDialog();
                    showSuccess('Menu is Live!');
                })
                .catch();
        }
    };

    const showSuccess = (msg) => {
        useNotificationStore.getState().addNotification({
            type: 'success',
            title: 'Success',
            message: msg,
        });
    };

    return locations == null ? (
        <>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="h-full w-full flex items-center justify-center">
                        <Spinner size="xl" />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        {action == 'create' ? 'New' : 'Copy'} Menu
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <Form<LoginValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
                            {({ register, formState }) => (
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="menu_name" className="control-label">
                                                Menu Name
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    name="menu_name"
                                                    placeholder="Health Nut Special Menu"
                                                    value={data.menu_name}
                                                    error={formState.errors['menu_name']}
                                                    registration={register('menu_name')}
                                                    disabled={action === 'copy' ? true : false}
                                                />
                                            </div>
                                        </div>
                                        {action === 'copy' && (
                                            <div className="col-md-12">
                                                <label
                                                    htmlFor="location_id"
                                                    className="control-label"
                                                >
                                                    Location
                                                </label>
                                                <div className="form-group">
                                                    <select
                                                        className="form-control"
                                                        name="location_id"
                                                        onChange={handleChange}
                                                        value={data.location_id}
                                                        error={formState.errors['location_id']}
                                                        registration={register('location_id')}
                                                    >
                                                        {locations.map(({ id, location_name }) => (
                                                            <option value={id} key={id}>
                                                                {location_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}

                                        {action === 'copy' && (
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="activation_time"
                                                    className="control-label"
                                                >
                                                    Activation Date
                                                </label>
                                                <div className="form-group">
                                                    <InputField
                                                        name="activation_time"
                                                        type="date"
                                                        value={data.activation_time}
                                                        className="form-control timePicker"
                                                        onChange={handleChange}
                                                        error={formState.errors['activation_time']}
                                                        registration={register('activation_time')}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {action === 'copy' && (
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="expiration_time"
                                                    className="control-label"
                                                >
                                                    Expiration Date
                                                </label>
                                                <div className="form-group">
                                                    <InputField
                                                        name="expiration_time"
                                                        type="date"
                                                        value={data.expiration_time}
                                                        className="form-control timePicker"
                                                        onChange={handleChange}
                                                        error={formState.errors['expiration_time']}
                                                        registration={register('expiration_time')}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {action === 'copy' && (
                                            <div className="flex gap-3">
                                                <label htmlFor="status">Status:</label>
                                                {data?.status ? 'Live' : 'Draft'}
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flexSwitchCheckChecked"
                                                        name="status"
                                                        defaultChecked={data?.status || false}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="modal-footer"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            startIcon={<CheckCircleOutlineIcon />}
                                            style={{ width: 'fit-content' }}
                                            // onClick={() => handleSubmit()}
                                        >
                                            Add Menu
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => closeDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateMenu;
