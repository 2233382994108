import { AddCircleOutline } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { axios } from '@/lib/axios';

const schema = z.object({
    location_id: z.string().nonempty('Please enter location'),
    serial_number: z.string().nonempty({ message: 'Please enter a valid serial number' }),
    name: z.string().nonempty('Please enter device name'),
    app_version: z.string().nonempty('Please enter app version'),
    print_address: z.string().nonempty('Please enter print address'),
    reader_id: z.string().nonempty('Please enter reader id'),
    status: z.number(),
});

type LoginValues = {
    location_id: string;
    serial_number: string;
    name: string;
    app_version: string;
    print_address: string;
    reader_id: string;
    status: number;
};

const EditPos = ({ posData, closeDialog, posQuery }) => {
    const intialPosData = {
        id: posData.id,
        location_id: posData.location_id,
        serial_number: posData.serial_number,
        name: posData.name,
        app_version: posData.app_version,
        print_address: posData.print_address,
        reader_id: posData.reader_id,
        status: posData.status,
    };
    const [data, setData] = useState(intialPosData);
    const [locations, setLocations] = useState();

    useEffect(() => {
        getAllLocations();
    }, []);

    //get all locations
    const getAllLocations = async () => {
        const { data } = await axios.get(`v1/admin/locations/get-locations`);
        setLocations(data.locations);
    };

    //////

    const updatePos = async (data) => {
        const updateData = {
            id: data.id,
            location_id: data.location_id,
            serial_number: data.serial_number,
            name: data.name,
            app_version: data.app_version,
            print_address: data.print_address,
            reader_id: data.reader_id,
            status: data.status,
        };

        const { success, message } = await axios.post(`v1/admin/pos/update-pos`, updateData);
        posQuery.refetch();
        return { success, message };
    };

    const handleSubmit = async () => {
        updatePos(data).then().catch();
        closeDialog();
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        switch (name) {
            case 'status':
                setData({ ...data, [name]: e.target.checked });
                break;
            default:
                setData({ ...data, [name]: value });
                break;
        }

        // setData({ ...data, [name]: value });
    };
    return locations == null ? (
        <>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="h-full w-full flex items-center justify-center">
                        <Spinner size="xl" />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="invoiceModalLabel">
                        Edit POS Device #{data.id}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="add-user-form">
                        <Form<LoginValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
                            {({ register, formState }) => (
                                <>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="serial_number"
                                                className="control-label"
                                            >
                                                Serial Number
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    name="serial_number"
                                                    placeholder="GHJ7489K"
                                                    value={data.serial_number}
                                                    error={formState.errors['serial_number']}
                                                    registration={register('serial_number')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="name" className="control-label">
                                                Device Name
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="name"
                                                    placeholder="richbrenson@gmail.com"
                                                    value={data.name}
                                                    onChange={handleChange}
                                                    error={formState.errors['name']}
                                                    registration={register('name')}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="location_id" className="control-label">
                                                Location
                                            </label>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="location_id"
                                                    required
                                                    onChange={handleChange}
                                                    value={data.location_id}
                                                    error={formState.errors['location_id']}
                                                    registration={register('location_id')}
                                                >
                                                    <option value="">Select or Type</option>
                                                    {locations.map(({ id, location_name }) => (
                                                        <option value={id} key={id}>
                                                            {location_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="app_version" className="control-label">
                                                App Version
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="app_version"
                                                    placeholder="1.1.0"
                                                    value={data.app_version}
                                                    onChange={handleChange}
                                                    error={formState.errors['app_version']}
                                                    registration={register('app_version')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="print_address"
                                                className="control-label"
                                            >
                                                Printer Address
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="print_address"
                                                    placeholder="252.101.193.216"
                                                    value={data.print_address}
                                                    onChange={handleChange}
                                                    error={formState.errors['print_address']}
                                                    registration={register('print_address')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="reader_id" className="control-label">
                                                Reader ID
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="reader_id"
                                                    placeholder="252.101.193.216"
                                                    value={data.reader_id}
                                                    onChange={handleChange}
                                                    error={formState.errors['reader_id']}
                                                    registration={register('reader_id')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex gap-3">
                                        <label htmlFor="status">Active:</label>
                                        {data?.status ? 'Yes' : 'No'}
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckChecked"
                                                name="status"
                                                defaultChecked={data?.status || false}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="modal-footer"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            startIcon={<AddCircleOutline />}
                                            style={{ width: 'fit-content' }}
                                            onClick={() => handleSubmit()}
                                        >
                                            Save Changes
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => closeDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditPos;
