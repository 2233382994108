import {
    //  CorporateFare,
    EditOutlined,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

import LanguageIcon from '@mui/icons-material/Language';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { Skeleton } from '@mui/material';
import moment from 'moment';
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import Typography from '@mui/material/Typography';
import {
    Coupon,
    // User
} from '../type';
// import { handleDateFormat } from '@/utils/commonHelperFunc';
import Delete from '@/components/Icons/Delete';
import { Button } from '@/components/Elements';

// import { axios } from '@/lib/axios';

const ViewCoupon = ({ couponData, closeDialog, selectedUserAction }) => {
    const [data, setData] = useState<Coupon | null>(null);

    useEffect(() => {
        setData(couponData);
    }, [couponData]);

    console.log(data);

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        Coupon #{data?.id}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <div
                            className="info-header"
                            style={{ paddingTop: '5px', paddingBottom: '22px' }}
                        >
                            <div className="left-info">
                                <LocalOfferOutlinedIcon
                                    className="pColor"
                                    style={{ transform: 'rotate(87deg)' }}
                                />
                                <h4>{data?.coupon_code}</h4>
                            </div>
                        </div>
                        <div
                            className="info-header "
                            style={{ paddingTop: '15px', paddingBottom: '15px' }}
                        >
                            <div className="left-info">
                                <h4>Details</h4>
                            </div>
                            <div className="left-info">
                                <LanguageIcon className="pColor" />
                                <strong>{data?.coupon_code__type_name}</strong>
                            </div>
                        </div>
                        <ul
                            className="user-details-list coupon-view"
                            style={{ paddingBottom: '1px' }}
                        >
                            <li>
                                <strong>Coupon Type:</strong>{' '}
                                <span>{data?.coupon_code__type_name}</span>
                            </li>
                            <li>
                                <strong>Deduction Type:</strong>{' '}
                                <span>{data?.deduction?.deduction_type}</span>
                            </li>
                            <li>
                                <strong>Activation Date:</strong>{' '}
                                <span>
                                    {moment(data?.coupon_activation_time).format(
                                        'MM/DD/YYYY, HH:mm A'
                                    )}
                                </span>
                            </li>
                            <li>
                                <strong>Deduction&nbsp;Min&nbsp;Order:</strong>

                                <span>
                                    &nbsp; ${(data?.deduction?.deduction_min_cart / 100).toFixed(2)}
                                </span>
                            </li>
                            <li>
                                <strong>Expiration Date:</strong>
                                <span>
                                    {moment(data?.coupon_expiration_time).format(
                                        'MM/DD/YYYY, HH:mm A'
                                    )}
                                </span>
                            </li>
                            <li>
                                <strong>Deduction&nbsp;Max&nbsp;Value:</strong>

                                <span>
                                    &nbsp; $
                                    {(data?.deduction?.deduction_max_dollar / 100).toFixed(2)}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="modal-body">
                    <div className="view-user-details">
                        <div
                            className="info-header"
                            style={{ paddingTop: '0px', paddingBottom: '5px' }}
                        >
                            <div className="left-info">
                                <h4 className="pb-2"> Advanced Options</h4>
                            </div>
                        </div>
                        <ul
                            className="user-details-list  coupon-view"
                            style={{ paddingBottom: '10px' }}
                        >
                            <li>
                                <strong>Uses:</strong> <span>{data?.coupon_code_uses || 0}</span>
                            </li>
                            <li>
                                <strong>Budget:</strong>{' '}
                                <span>$ {(data?.coupon_budget / 100).toFixed(2) || 0}</span>
                            </li>
                            <li>
                                <strong>Used:</strong> <span>{data?.coupon_code_used || 0}</span>
                            </li>
                            <li>
                                <strong>Budget Remaining:</strong>

                                <span>
                                    ${' '}
                                    {(
                                        (data?.coupon_budget - data?.coupon_budget_used) /
                                        100
                                    ).toFixed(2)}
                                </span>
                            </li>
                            <li>
                                <strong>Always Available:</strong>
                                <span>
                                    {data?.days?.coupon_days.split('').length == 7 ? 'Yes' : 'No'}
                                </span>
                            </li>
                        </ul>
                    </div>

                    {data?.days?.coupon_days.split('').length != 7 && (
                        <div className="flex gap-3 pt-2">
                            <div>
                                <strong>Active Time:</strong>
                            </div>
                            <div>
                                <p>
                                    {data?.coupon_days_start} - {data?.coupon_days_end}
                                </p>
                                <ul className="flex gap-3">
                                    {data?.days?.coupon_days.split('').map((i) => (
                                        <li>
                                            <DoneOutlinedIcon className="pColor" />
                                            <span>
                                                {i == 1
                                                    ? 'Sunday'
                                                    : i == 2
                                                    ? 'Monday'
                                                    : i == 3
                                                    ? 'Tuesday'
                                                    : i == 4
                                                    ? 'Wednesday'
                                                    : i == 5
                                                    ? 'Thursday'
                                                    : i == 6
                                                    ? 'Friday'
                                                    : 'Saturday'}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        onClick={() =>
                            selectedUserAction({
                                action: 'edit',
                                data: data,
                            })
                        }
                        startIcon={<EditOutlined />}
                        style={{ width: 'fit-content' }}
                    >
                        Edit information
                    </Button>
                    <Button
                        startIcon={<Delete />}
                        style={{ width: 'fit-content' }}
                        variant="secondary"
                        onClick={() =>
                            selectedUserAction({
                                action: 'delete',
                                data: data,
                            })
                        }
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ViewCoupon;
