import { CorporateFare } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useCorp } from '../api/CRUD/getCorp';
import { Corporation } from '../type';

import invoiceSrc from '../../../assets/invoice-w.png';
import { Button } from '@/components/Elements';

const AddInvoice = ({ corpId, closeDialog }) => {
    const corpQuery = useCorp(corpId);
    const [corpData, setCorpData] = useState<Corporation | null>(null);

    const handleGetCorp = () => {
        if (!corpQuery.isLoading) setCorpData(corpQuery?.data);
    };

    useEffect(() => {
        handleGetCorp();
    }, [corpQuery.isLoading]);

    if (corpQuery.isLoading) {
        return (
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Add Invoice
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => closeDialog()}
                        ></button>
                    </div>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </div>
            </div>
        );
    }

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="invoiceModalLabel">
                        Invoice Account / Corporation
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="add-user-form">
                        <div className="info-header">
                            <div className="left-info">
                                <CorporateFare className="pColor" />
                                <h4>{corpData?.corporate_name}</h4>
                                <p>
                                    <span className="vip-tx"> VIP</span>
                                </p>
                            </div>
                        </div>
                        <form className="user-form" action="" style={{ paddingTop: '15px' }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="cname" className="control-label">
                                        Contact Name
                                    </label>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="cname"
                                            placeholder="Richard Brenson"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="umail" className="control-label">
                                        Email
                                    </label>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="umail"
                                            placeholder="richbrenson@gmail.com"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="account" className="control-label">
                                        Account Number
                                    </label>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="account"
                                            placeholder="33679936"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="status" className="control-label">
                                        Terms
                                    </label>
                                    <div className="form-group">
                                        <select className="form-control" name="status" required>
                                            <option value="">Select or Type</option>
                                            <option value="active">Net 15</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        //onClick={handleAddUser}
                        startIcon={<img src={invoiceSrc} alt="editCheck" />}
                        style={{ width: 'fit-content' }}
                    >
                        Add Invoice
                    </Button>
                    <Button
                        variant="secondary"
                        style={{ width: 'fit-content' }}
                        onClick={() => closeDialog()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddInvoice;
