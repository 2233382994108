import { AddCircleOutline } from '@mui/icons-material';
import React, {
    // useEffect,
    useState,
} from 'react';
import * as z from 'zod';

import {
    Button,
    //  Spinner
} from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({
    gift_card_code: z.string().nonempty('Gift Code is required'),
    gift_card_amout: z.string().nonempty('Amount is required'),
    gift_card_recipient_first_name: z.string().nonempty('First Name is required'),
    gift_card_recipient_last_name: z.string().nonempty('Last Name is required'),
    gift_card_recipient_email: z.string().email().nonempty('Receiver Email is required'),
    gift_card_purchaser_first_name: z.string().nonempty('First Name is required'),
    gift_card_purchaser_last_name: z.string().nonempty('Last Name is required'),
    gift_card_purchaser_email: z.string().email().nonempty('Purchaser Email is required'),
});

type LoginValues = {
    gift_card_code: '';
    gift_card_amout: '';
    gift_card_recipient_first_name: '';
    gift_card_recipient_last_name: '';
    gift_card_recipient_email: '';
    gift_card_purchaser_first_name: '';
    gift_card_purchaser_last_name: '';
    gift_card_purchaser_email: '';
};

const CreateGiftCard = ({ getGiftCard, closeDialog }) => {
    const generateRandomCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let code = '';
        const codeLength = 16;

        for (let i = 0; i < codeLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters[randomIndex];
        }

        return code;
    };
    const intialGiftCardData = {
        gift_card_code: generateRandomCode(),
        gift_card_amout: '',
        gift_card_recipient_first_name: '',
        gift_card_recipient_last_name: '',
        gift_card_recipient_email: '',
        gift_card_purchaser_first_name: 'Health',
        gift_card_purchaser_last_name: 'Nut',
        gift_card_purchaser_email: 'Hello@healthnutla.com',
    };
    const [data, setData] = useState(intialGiftCardData);

    const updateGiftCard = async () => {
        const params: any = data;
        params.gift_card_amout = data?.gift_card_amout * 100;
        axios
            .post('/v1/admin/gift-cards/add-gift-card', data)
            .then(() => {
                getGiftCard();
                showSuccess();
            })
            .catch();

        // const { success, message } = await axios.post(`v1/`);
        // return { success, message };
    };

    const showSuccess = () => {
        useNotificationStore.getState().addNotification({
            type: 'success',
            title: 'Success',
            message: 'Gift Card Credited Successfully!',
        });
    };

    const handleSubmit = async () => {
        updateGiftCard();
        closeDialog();
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        switch (name) {
            case 'active':
                setData({ ...data, [name]: e.target.checked });
                break;
            default:
                setData({ ...data, [name]: value });
                break;
        }

        // setData({ ...data, [name]: value });
    };
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="invoiceModalLabel">
                        Create GiftCard
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="add-user-form">
                        <Form<LoginValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
                            {({ register, formState }) => (
                                <>
                                    <div
                                        className="info-header"
                                        style={{ paddingTop: '22px', paddingBottom: '5px' }}
                                    >
                                        <div className="left-info">
                                            <strong>New Card</strong>
                                        </div>
                                        <div className="flex gap-3">
                                            {/* <div className="all-bx">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="all"
                                                        name="all"
                                                        value="All"
                                                        checked={true}
                                                        onChange={() => {}}
                                                    />
                                                    <span>Create As Gift &nbsp; | &nbsp;</span>
                                                </label>
                                            </div> */}
                                            <label htmlFor="active">Active:</label>
                                            Yes
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                                    name="active"
                                                    defaultChecked={true}
                                                    checked={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label
                                                htmlFor="serial_number"
                                                className="control-label"
                                            >
                                                Card Id
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    onChange={handleChange}
                                                    // disabled
                                                    className="form-control"
                                                    name="gift_card_code"
                                                    placeholder="GHJ7489K7897UIOH"
                                                    value={data.gift_card_code}
                                                    error={formState.errors['gift_card_code']}
                                                    registration={register('gift_card_code')}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <label
                                                htmlFor="serial_number"
                                                className="control-label"
                                            >
                                                Amount
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    name="gift_card_amout"
                                                    placeholder="$200"
                                                    value={data.gift_card_amout}
                                                    error={formState.errors['gift_card_amout']}
                                                    registration={register('gift_card_amout')}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4"></div>

                                        <div className="col-md-4">
                                            <label
                                                htmlFor="serial_number"
                                                className="control-label"
                                            >
                                                Receiver First Name
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    name="gift_card_recipient_first_name"
                                                    placeholder="John"
                                                    value={data.gift_card_recipient_first_name}
                                                    error={
                                                        formState.errors[
                                                            'gift_card_recipient_first_name'
                                                        ]
                                                    }
                                                    registration={register(
                                                        'gift_card_recipient_first_name'
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <label
                                                htmlFor="serial_number"
                                                className="control-label"
                                            >
                                                Receiver Last Name
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    name="gift_card_recipient_last_name"
                                                    placeholder="Doe"
                                                    value={data.gift_card_recipient_last_name}
                                                    // error={
                                                    //     formState.errors[
                                                    //         'gift_card_recipient_last_name'
                                                    //     ]
                                                    // }
                                                    registration={register(
                                                        'gift_card_recipient_last_name'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label
                                                htmlFor="serial_number"
                                                className="control-label"
                                            >
                                                Receiver Email
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    name="gift_card_recipient_email"
                                                    placeholder="james@bns.com"
                                                    value={data.gift_card_recipient_email}
                                                    error={
                                                        formState.errors[
                                                            'gift_card_recipient_email'
                                                        ]
                                                    }
                                                    registration={register(
                                                        'gift_card_recipient_email'
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <label
                                                htmlFor="serial_number"
                                                className="control-label"
                                            >
                                                Purchaser First Name
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    name="gift_card_purchaser_first_name"
                                                    placeholder="Alex"
                                                    value={data.gift_card_purchaser_first_name}
                                                    // error={
                                                    //     formState.errors[
                                                    //         'gift_card_purchaser_first_name'
                                                    //     ]
                                                    // }
                                                    registration={register(
                                                        'gift_card_purchaser_first_name'
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <label
                                                htmlFor="serial_number"
                                                className="control-label"
                                            >
                                                Purchaser Last Name
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    name="gift_card_purchaser_last_name"
                                                    placeholder="Kim"
                                                    value={data.gift_card_purchaser_last_name}
                                                    // error={
                                                    //     formState.errors[
                                                    //         'gift_card_purchaser_last_name'
                                                    //     ]
                                                    // }
                                                    registration={register(
                                                        'gift_card_purchaser_last_name'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label
                                                htmlFor="serial_number"
                                                className="control-label"
                                            >
                                                Purchaser Email
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    name="gift_card_purchaser_email"
                                                    placeholder="kalex@ymail.com"
                                                    value={data.gift_card_purchaser_email}
                                                    // error={
                                                    //     formState.errors[
                                                    //         'gift_card_purchaser_email'
                                                    //     ]
                                                    // }
                                                    registration={register(
                                                        'gift_card_purchaser_email'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* need to be a button for add more card on click */}
                                    {/* <span className="dark-tx d-flex align-items-center">
                                        <AddCircleOutline /> &nbsp; Add One More
                                    </span> */}

                                    <div
                                        className="modal-footer"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            startIcon={<AddCircleOutline />}
                                            style={{ width: 'fit-content' }}
                                            // onClick={() => handleSubmit()}
                                        >
                                            Send Gift Card
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => closeDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateGiftCard;
