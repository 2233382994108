// import { AddCircleOutline } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { getAllPermission, usePermission } from '../api/CRUD/getAllPermission';
// import EditPermission from '../components/edit';
// import ViewPermission from '../components/view';
import { dataFields, HeadCells } from '../constant';
import { Permission } from '../type';
import { useNotificationStore } from '@/stores/notifications';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
// import TableWithSort from '@/components/Elements/Table/TableWithSort';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { Confirm } from '@/components/Elements/Dialog/Confirm';
import { Button, Dialog, Spinner } from '@/components/Elements';
//import { HeadCells } from '@/features/users/constant';

import '../styles/styles.css';
import { axios } from '@/lib/axios';

export const PermissionPage = () => {
    const { close, open, isOpen } = useDisclosure();
    const { addNotification } = useNotificationStore();
    const permissionQuery = usePermission();

    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [permission, setPermission] = useState<Permission[]>([]);
    const [createPermission, setCreatePermission] = useState<boolean>(false);
    const [editPermission, setEditPermission] = useState<Permission | null>(null);
    const [viewPermission, setviewPermission] = useState<Permission | null>(null);
    const [locationFilter, setLocationFilter] = useState();
    const [confirmMsg, setConfirmMsg] = useState<User | null>(null);

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    async function getPermission() {
        setIsLoading(true);
        const res = await getAllPermission();

        setPermission(res);

        setIsLoading(false);
    }
    useEffect(() => {
        getPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissionQuery.data, close]);

    if (permissionQuery.isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!permission) return null;

    const filteredEmployees = permission?.filter((permission: Permission) => {
        if (searchVal || locationFilter) {
            if (permission?.first_name === locationFilter) {
                return permission;
            } else if (permission?.first_name === locationFilter) {
                return permission;
            }
            if (searchVal) {
                return permission?.first_name?.toLowerCase()?.includes(searchVal.toLowerCase());
            }
        } else {
            return permission;
        }
    });

    const deletePermission = async (id) => {
        const updateData = {
            active: 0,
        };

        const { success, message } = await axios.post(`v1//${id}`, updateData);
        permissionQuery.refetch();
        return { success, message };
    };

    const handleDelete = async (permissionData: Permission) => {
        await deletePermission(permissionData.id).then(() => {
            addNotification({
                type: 'success',
                title: 'Permission Deleted',
            });
        });
        getPermission();
        setConfirmMsg(null);
    };

    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            default:
                return null;
        }
    };

    const closeDialog = () => {
        setviewPermission(null);
        setEditPermission(null);
        setCreatePermission(false);
        close();
    };

    const LocationOptions = new Set(permission?.map((item) => item?.location_name));

    return (
        <ContentLayout title="" topNavBarData={{ num: permission.length, title: 'Permission' }}>
            {confirmMsg ? (
                <Confirm
                    btnTitle={'Delete'}
                    onClose={() => setConfirmMsg(null)}
                    onSuccess={() => handleDelete(confirmMsg)}
                />
            ) : null}
            <div className="table-header">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="flex pos-devces">
                                <div className="input-group search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        value={searchVal}
                                        size={50}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <CustomTable
                tableData={filteredEmployees}
                headCells={HeadCells}
                dataFields={dataFields}
                selectedUserAction={selectedUserAction}
                // rowOptions={true}
                // optionMenu={['edit', 'delete']}
            />
            <Dialog isOpen={isOpen} onClose={closeDialog} paperWidth={'60%'}>
                <div className="modals-contents">
                    {/* {createPermission && <CreatePos closeDialog={closeDialog} />} */}
                    {/* {viewPermission && (
                        <ViewPermission
                            permissionData={viewPermission}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )} */}
                    {/* {editPermission && <EditPermission permissionQuery={permissionQuery} permissionData={editPermission} closeDialog={closeDialog} />} */}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
