// import { axios } from '@/lib/axios';

export const getLoyaltyData = async (id: number) => {
    // const { data } = await axios.get(`v1/get-corporation-loyalty-points/${id}`);
    // return data;
    return [
        {
            applied_points: 2,
            date_created: '2022-10-20 02:22:32 am',
            order_id: 283,
        },
        {
            applied_points: 2,
            date_created: '2022-10-20 02:07:50 am',
            order_id: 282,
        },
        {
            applied_points: 4,
            date_created: '2022-10-20 02:06:30 am',
            order_id: 281,
        },
    ];
};
