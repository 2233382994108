// import { CorporateFare, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import * as z from 'zod';

// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { Menu, User } from '../type';
import { Form, InputField } from '@/components/Form';
import { Button, Spinner } from '@/components/Elements';
// import Delete from '@/components/Icons/Delete';
// import { handleDateFormat } from '@/utils/commonHelperFunc';

import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({
    // location_id: z.string(),
    // activation_time: z.string().nonempty({ message: 'Please enter an activation date' }),
    // expiration_time: z.string().nonempty({ message: 'Please enter an activation date' }),
});

type LoginValues = {
    menu_name: string;
    location_id: string;
    menu_group: string;
    activation_time: string;
    expiration_time: string;
    status: boolean;
};

const EditMenu = ({ menuData, closeDialog, selectedUserAction, getMenu }:any) => {
    const intialMenuData = {
        id: menuData?.id,
        menu_group: menuData?.menu_group.id || '',
        menu_group_name: menuData?.menu_group?.menu_group_name,
        location_id: menuData?.location?.id,
        activation_time: menuData?.activation_time
            ? menuData?.activation_time.split('T')[0]
            : '2023-06-22',
        expiration_time: menuData?.expiration_time
            ? menuData?.expiration_time.split('T')[0]
            : '2023-06-28',
    };
    const [data, setData] = useState(intialMenuData);
    const [products, setProducts] = useState([]);
    const [locations, setLocations] = useState();

    console.log(menuData);

    useEffect(() => {
        getAllLocations();
    }, []);

    //get all locations
    const getAllLocations = async () => {
        const { data } = await axios.get(`v1/admin/locations/get-locations`);
        setLocations(data.locations);
    };

    //////

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const createMenu = async (data:any) => {
        const { success, message } = await axios.post(``, data);
        return { success, message };
    };

    const handleSubmit = async () => {
        axios
            .post('v1/admin/menus/update-menu-live', {
                id: data?.id,
                menu_group: data?.menu_group,
                location_id: data?.location_id,
                activation_time: data?.activation_time,
                expiration_time: data?.expiration_time,
            })
            .then(() => {
                getMenu();
                closeDialog();
                showSuccess();
            })
            .catch();
    };

    const showSuccess = () => {
        useNotificationStore.getState().addNotification({
            type: 'success',
            title: 'Success',
            message: 'Menu Updated!',
        });
    };

    return locations == null ? (
        <>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="h-full w-full flex items-center justify-center">
                        <Spinner size="xl" />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        Edit Menu
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <Form<LoginValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
                            {({ register, formState }) => (
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="menu_name" className="control-label">
                                                Menu Name
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="menu_name"
                                                    placeholder="Health Nut Special Menu"
                                                    value={data.menu_group_name}
                                                    error={formState.errors['menu_group_name']}
                                                    registration={register('menu_group_name')}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <label htmlFor="location_id" className="control-label">
                                                Location
                                            </label>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="location_id"
                                                    onChange={handleChange}
                                                    value={data.location_id}
                                                    error={formState.errors['location_id']}
                                                    registration={register('location_id')}
                                                >
                                                    <option value="">Select or Type</option>
                                                    {locations.map(({ id, location_name }) => (
                                                        <option value={id} key={id}>
                                                            {location_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <label
                                                htmlFor="activation_date"
                                                className="control-label"
                                            >
                                                Activation Date
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    name="activation_time"
                                                    type="date"
                                                    value={data.activation_time}
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                    error={formState.errors['activation_time']}
                                                    registration={register('activation_time')}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <label
                                                htmlFor="expiration_date"
                                                className="control-label"
                                            >
                                                Expiration Date
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    name="expiration_time"
                                                    type="date"
                                                    value={data.expiration_time}
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                    error={formState.errors['expiration_time']}
                                                    registration={register('expiration_time')}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex gap-3">
                                            <label htmlFor="status">Status:</label>
                                            Live
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                                    name="status"
                                                    defaultChecked={true}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal-footer"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            startIcon={<CheckCircleOutlineIcon />}
                                            style={{ width: 'fit-content' }}
                                            // onClick={() => handleSubmit()}
                                        >
                                            Update Menu
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => closeDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditMenu;
