export const HeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Order No',
        sort: true,
        type: 'text',
    },
    {
        id: 'location_name',
        numeric: true,
        disablePadding: false,
        label: 'Location',
        sort: true,
        type: 'text',
    },
    {
        id: 'order_type',
        numeric: true,
        disablePadding: false,
        label: 'Order Type',
        sort: true,
        type: 'text',
    },
    {
        id: 'first_name',
        numeric: true,
        disablePadding: false,
        label: 'First Name',
        sort: true,
        type: 'text',
    },
    {
        id: 'last_name',
        numeric: true,
        disablePadding: false,
        label: 'Last Name',
        sort: true,
        type: 'text',
    },
    {
        id: 'order_status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
        sort: true,
        type: 'text',
    },
    {
        id: 'order_total',
        numeric: true,
        disablePadding: false,
        label: 'Total',
        sort: true,
        type: 'text',
        prefix: '$',
    },

    {
        id: 'order_promised_time',
        numeric: true,
        disablePadding: false,
        label: 'Promised Time',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'order_creation_date',
        numeric: true,
        disablePadding: false,
        label: 'Order Placed',
        sort: true,
        type: 'dateTime',
    },
];
export const BodyCells = [
    {
        id: 'id',
    },
    {
        id: 'location_name',
    },
    {
        id: 'order_type',
    },
    {
        id: 'first_name',
    },
    {
        id: 'last_name',
    },
    {
        id: 'order_status',
        background: 'orders',
    },
    {
        id: 'order_total',
        prefix: '$',
    },
    {
        id: 'order_promised_time',
    },
    {
        id: 'order_creation_date',
    },
];
export const dataFields = [
    'id',
    'location_name',
    'order_type',
    'first_name',
    'last_name',
    'order_status',
    'order_total',
    'order_promised_time',
    'order_creation_date',
];

