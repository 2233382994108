import { useQuery } from 'react-query';

import { Pos } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getAllPos = async (): Promise<Pos[]> => {
    const { data } = await axios.get(`v1/admin/pos/get-pos`);
    return data.pos;
};

type QueryFnType = typeof getAllPos;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const usePos = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['pos'],
        queryFn: () => getAllPos(),
    });
};
