// import { CorporateFare, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';

// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import moment from 'moment';
import { Menu, User } from '../type';
import { Button } from '@/components/Elements';
import Delete from '@/components/Icons/Delete';
import { handleDateFormat } from '@/utils/commonHelperFunc';

import { axios } from '@/lib/axios';

const ViewInquiry = ({ inquiryData, closeDialog, selectedUserAction }) => {
    const [data, setData] = useState<any | null>(null);

    useEffect(() => {
        setData(inquiryData);
    }, [inquiryData]);

    const deleteCustomInquiry = async (id: any, isTrue?: any) => {
        await axios.post('v1/admin/inquiries/update-careers-inquiry', {
            id: id,
            inquiry_active: isTrue,
        });
    };
    const isReadCustomInquiry = async (id: any, isTrue?: any) => {
        await axios.post('v1/admin/inquiries/update-careers-inquiry', {
            id: id,
            inquiry_read: isTrue,
        });
    };
    const deleteContactInquiry = async (id: any, isTrue?: any) => {
        await axios.post('v1/admin/inquiries/update-contact-us-inquiry', {
            id: id,
            inquiry_active: isTrue,
        });
    };
    const isReadContactInquiry = async (id: any, isTrue?: any) => {
        await axios.post('v1/admin/inquiries/update-contact-us-inquiry', {
            id: id,
            inquiry_read: isTrue,
        });
    };

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        {inquiryData?.applicant_first_name
                            ? inquiryData?.applicant_first_name
                            : inquiryData?.contact_full_name}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                {inquiryData?.applicant_first_name ? (
                    <div className="modal-body">
                        <div className="view-user-details company-details">
                            <div className="flex justify-between">
                                <div>
                                    <strong>Name:</strong> {data?.applicant_first_name}{' '}
                                    {data?.applicant_last_name}
                                </div>
                                <div>
                                    <strong>{data?.inquiry_active ? 'Active' : 'Deleted'}</strong>
                                </div>
                            </div>
                            <div className="flex justify-between contact_comment mt-2">
                                <div>
                                    <strong>Email:</strong> {data?.applicant_email}
                                </div>
                            </div>
                            <div className="flex justify-between contact_comment mt-2">
                                <div>
                                    <strong>Phone:</strong> {data?.applicant_phone}
                                </div>
                            </div>
                            <div className="flex justify-between mt-2">
                                <div>
                                    <strong>Address:</strong> {data?.applicant_address1}{' '}
                                    {data?.applicant_address2} {data?.applicant_city}{' '}
                                    {data?.applicant_state} {data?.applicant_zip}
                                </div>
                            </div>
                            <div className="flex justify-between contact_comment mt-2">
                                <div>
                                    <strong>Applicant Position:</strong> {data?.applicant_position}
                                </div>
                            </div>
                            <div className="flex justify-between contact_comment mt-2">
                                <div>
                                    <strong>Applied at Location:</strong> {data?.applicant_location}
                                </div>
                            </div>

                            <div className="flex justify-between contact_comment mt-2">
                                <div>
                                    <strong>Applicant Employment Desired:</strong>{' '}
                                    {data?.applicant_employment_desired}
                                </div>
                            </div>
                            <div className="flex justify-between contact_comment mt-2">
                                <div>
                                    <strong>Applicant Availability:</strong>{' '}
                                    {data?.applicant_availability}
                                </div>
                                <Button
                                    onClick={() => {
                                        console.log('sachin');
                                    }}
                                    style={{ width: 'fit-content' }}
                                >
                                    Applicant CV
                                </Button>
                            </div>

                            <hr className="mt-3 mb-3" />
                        </div>
                    </div>
                ) : (
                    <div className="modal-body">
                        <div className="view-user-details company-details">
                            <div className="flex justify-between">
                                <div>
                                    <strong>Name:</strong> {data?.contact_full_name}
                                </div>
                                <div>
                                    <strong>{data?.inquiry_active ? 'Active' : 'Deleted'}</strong>
                                </div>
                            </div>
                            <div className="flex justify-between contact_comment mt-2">
                                <div>
                                    <strong>Email:</strong> {data?.contact_email}
                                </div>
                            </div>
                            <div className="flex justify-between contact_comment mt-2">
                                <div>
                                    <strong>Phone:</strong> {data?.contact_phone}
                                </div>
                            </div>
                            <div className="flex justify-between mt-2">
                                <div>
                                    <strong>Comment:</strong> {data?.contact_comment}
                                </div>
                            </div>

                            <hr className="mt-3 mb-3" />
                        </div>
                    </div>
                )}

                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                        border: 'none',
                    }}
                >
                    <Button
                        onClick={
                            () => {
                                inquiryData?.applicant_first_name
                                    ? isReadCustomInquiry(data.id, false)
                                    : isReadContactInquiry(data.id, false);
                            }
                            // selectedUserAction({
                            //     action: 'edit',
                            //     data: data,
                            // })
                        }
                        startIcon={''}
                        style={{ width: 'fit-content' }}
                    >
                        Mark as Unread
                    </Button>
                    <Button
                        startIcon={<Delete />}
                        style={{ width: 'fit-content' }}
                        variant="secondary"
                        onClick={
                            () => {
                                inquiryData?.applicant_first_name
                                    ? deleteCustomInquiry(data.id, false)
                                    : deleteContactInquiry(data.id, false);
                            }

                            // selectedUserAction({
                            //     action: 'delete',
                            //     data: data,
                            // })
                        }
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ViewInquiry;
