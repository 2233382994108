import './loginForm.css';
import * as z from 'zod';

import loginRight from '../../../assets/login-rght.png';
import logo from '../../../assets/logo.png';
import { LoginCredentialsDTO } from '../api/login';
import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({
    email: z.string().min(1, 'Please enter email address'),
    password: z.string().min(1, 'Please enter password'),
});

type LoginValues = {
    email: string;
    password: string;
};

type LoginFormProps = {
    onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
    const { login, isLoggingIn } = useAuth();

    const { addNotification } = useNotificationStore();
    return (
        <div>
            <Form<LoginValues, typeof schema>
                onSubmit={async (values) => {
                    const { email, password } = values;
                    const data: LoginCredentialsDTO = {
                        user_email: email,
                        user_password: password,
                    };
                    await login(data);
                    onSuccess();
                    addNotification({
                        type: 'success',
                        title: 'SignedIn Successfully.',
                    });
                }}
                schema={schema}
            >
                {({ register, formState }) => (
                    <div className="login-body-store">
                        <section className="login-section">
                            <div className="login-rw">
                                <div className="row login-details-all">
                                    <div className="col-12 col-md-6 col-lg-6 login-left">
                                        <div className="lgn-left">
                                            <div className="cstm-logo text-center">
                                                <img src={logo} alt="logo" />
                                            </div>
                                            <div className="lgn-frm">
                                                <div className="form">
                                                    <p>Admin Panel</p>
                                                    <h2 className="login-heading">Sign In</h2>

                                                    <p className="form-mail">
                                                        <label
                                                            style={{
                                                                color: '#7AAD37',
                                                                fontSize: '13px',
                                                                marginBottom: '4px',
                                                            }}
                                                        >
                                                            Email
                                                        </label>
                                                        <InputField
                                                            type="email"
                                                            placeHolder="kevin@gmail.com"
                                                            error={formState.errors['email']}
                                                            registration={register('email')}
                                                        />
                                                    </p>
                                                    <p className="form-mail">
                                                        <label
                                                            style={{
                                                                color: 'rgba(17, 17, 17, 0.48)',
                                                                fontSize: '13px',
                                                                marginBottom: '4px',
                                                            }}
                                                        >
                                                            Password
                                                        </label>
                                                        <InputField
                                                            type="password"
                                                            placeHolder="password"
                                                            error={formState.errors['password']}
                                                            registration={register('password')}
                                                        />
                                                    </p>
                                                    <div className="pass-remember">
                                                        <label className="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                id="online-store"
                                                                name="online-store"
                                                                defaultValue="Online Store"
                                                            />
                                                            <span> Remember Me</span>
                                                        </label>
                                                    </div>
                                                    <Button
                                                        isLoading={isLoggingIn}
                                                        type="submit"
                                                        className="w-full"
                                                    >
                                                        Sign in
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 right-login-form">
                                        <div className="right-login-img">
                                            <img src={loginRight} alt="logoRight" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )}
            </Form>
        </div>
    );
};
