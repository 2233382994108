import { useQuery } from 'react-query';

import { Loyalty } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getAllLoyalty = async (): Promise<Loyalty[]> => {
    const { data } = await axios.get(`v1/admin/loyalties/get-loyally-multiplier`);
    return data;
};

type QueryFnType = typeof getAllLoyalty;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useLoyalty = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['loyalty'],
        queryFn: () => getAllLoyalty(),
    });
};
