import { axios } from '@/lib/axios';

export const getProductsByCategory = async () => {
    const { data } = await axios.get(`v1/admin/products/get-products`);
    return data;
};
export const getLocaionProductsByCategory = async (id: number) => {
    const { data } = await axios.get(`v1/get-products-by-menu/${id}`);
    return data.products;
};
