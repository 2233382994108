
import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
    type?: 'text' | 'email' | 'password' | 'number';
    registration: Partial<UseFormRegisterReturn>;
    placeHolder?: string;
};

export const InputField = (props: InputFieldProps) => {
    const { type = 'text', label, registration, error, placeHolder, ...remain } = props;
    return (
        <FieldWrapper label={label} error={error}>
            <input type={type} placeholder={placeHolder} {...registration} {...remain} />
        </FieldWrapper>
    );
};
