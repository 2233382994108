 import { axios } from '@/lib/axios';

export const getLoyaltyPoints = async (id: number): Promise<any[]> => {
    const { data } = await axios.get(`v1/loyalty/get-loyalties?id=${id}`);
    return data;
    // return {
    //     points: 953,
    //     history: [
    //         {
    //             applied_points: 2,
    //             date_created: '2022-10-20 07:52:32 am',
    //             order_id: 283,
    //         },
    //         {
    //             applied_points: 2,
    //             date_created: '2022-10-20 07:37:50 am',
    //             order_id: 282,
    //         },
    //         {
    //             applied_points: 4,
    //             date_created: '2022-10-20 07:36:30 am',
    //             order_id: 281,
    //         },
    //         {
    //             applied_points: 40,
    //             date_created: '2022-10-20 07:30:33 am',
    //             order_id: 258,
    //         },
    //     ],
    // };
};
