// import { CorporateFare, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';

import orderImg from '../../../assets/ordericon.svg';

import { Button, Spinner } from '@/components/Elements';

// import { axios } from '@/lib/axios';

const SendTarget = ({ closeDialog, selectedUserAction, sendTargetData }) => {
    const intialMenuData = {
        menu_name: '',
        location_id: '',
        start_date: '',
        end_date: '',
        price_min: '',
        price_max: '',
        location_address: '',
    };
    const [data, setData] = useState(intialMenuData);

    const dummyEmails = [
        'brionna.mente@gmail.com',
        'moises.howell@yahoo.com',
        'devante_kuhic@hotmail.com',
        'maci_funk@yahoo.com',
        'daren42@gmail.com',
    ];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        Send Targets / {sendTargetData.type}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        {sendTargetData.type == 'Orders' && (
                            <div className="flex justify-between mb-2">
                                <div>
                                    <strong>Orders</strong>
                                </div>
                                <div className="left-info flex gap-2">
                                    <img src={orderImg} alt="orderImg" /> $
                                    {sendTargetData?.price_min} - ${sendTargetData?.price_max}
                                </div>
                            </div>
                        )}

                        {sendTargetData.type == 'Customers' && (
                            <div className="flex justify-between mb-2">
                                <div>
                                    <strong>Customers total value</strong>
                                </div>
                                <div className="left-info flex gap-2">
                                    <LocalAtmOutlinedIcon style={{ fill: '#0072ea' }} />
                                    Under ${sendTargetData?.price_max}
                                </div>
                            </div>
                        )}

                        {sendTargetData.type == 'Products' && (
                            <div className="flex justify-between mb-2">
                                <div>
                                    <strong>Ordered Products</strong>
                                </div>
                                <div className="left-info flex gap-2">
                                    <ShoppingCartOutlinedIcon style={{ fill: '#0072ea' }} />
                                    Over {sendTargetData?.price_min}
                                </div>
                            </div>
                        )}

                        {sendTargetData.type == 'Loyalty' && (
                            <div className="flex justify-between mb-2">
                                <div>
                                    <strong>Loyalty Points Value</strong>
                                </div>
                                <div className="left-info flex gap-2">
                                    <EmojiEventsOutlinedIcon style={{ fill: '#0072ea' }} />
                                    Under {sendTargetData?.price_max}
                                </div>
                            </div>
                        )}

                        {sendTargetData.type == 'Location' && (
                            <div className="flex justify-between mb-2">
                                <div>
                                    <strong>Users Passing Location</strong>
                                </div>
                                <div className="left-info flex gap-2">
                                    <img src={orderImg} alt="orderImg" /> 307, Embly Field, Norway.
                                </div>
                            </div>
                        )}

                        <hr />
                        <div className="flex justify-between mt-3 mb-4">
                            <div className="left-info flex gap-2">
                                <CalendarTodayOutlinedIcon style={{ fill: '#0072ea' }} />
                                {sendTargetData?.start_date} - {sendTargetData?.end_date}
                            </div>
                        </div>
                        <div className="flex justify-between mb-2">
                            <div>
                                <strong>Targets</strong>
                            </div>
                            <div className="left-info flex gap-2">
                                <PersonOutlineOutlinedIcon style={{ fill: '#0072ea' }} />
                                500 Customers
                            </div>
                        </div>
                        <hr />
                        <div className="mb-2 mt-2">
                            {dummyEmails.map((email) => (
                                <>
                                    <div className="left-info flex gap-2 mt-2 mb-2">
                                        <EmailOutlinedIcon style={{ fill: '#0072ea' }} />
                                        {email}
                                    </div>
                                    <hr />
                                </>
                            ))}
                        </div>
                    </div>
                    <div
                        className="modal-footer"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '1rem',
                        }}
                    >
                        <Button
                            type="submit"
                            startIcon={<CheckCircleOutlineIcon />}
                            style={{ width: 'fit-content' }}
                            onClick={() => closeDialog()}
                        >
                            Send
                        </Button>
                        <Button
                            variant="secondary"
                            style={{ width: 'fit-content' }}
                            onClick={() => closeDialog()}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendTarget;
