import { CorporateFare, EditOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { Skeleton } from '@mui/material';
// import moment from 'moment';
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import Typography from '@mui/material/Typography';
import { Loyalty, User } from '../type';
// import { handleDateFormat } from '@/utils/commonHelperFunc';
import Delete from '@/components/Icons/Delete';
import { Button } from '@/components/Elements';

import { axios } from '@/lib/axios';

const ViewLoyalty = ({ loyaltyData, closeDialog, selectedUserAction }: any) => {
    const [data, setData] = useState<any | null>(null);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [transactionData, setTransactionData] = useState<any | null>(null);

    // const getTransactionData = async (id: number) => {
    //     const { data } = await axios.get(`v1/get-user-loyalty-points/${id}`);
    //     setTransactionData(data);
    // };

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        setData(loyaltyData);
        // getTransactionData(loyaltyData.id);
    }, [loyaltyData]);

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        Loyalty {loyaltyData.category_name} {loyaltyData.subcategory}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <div
                            className="info-header"
                            style={{ paddingTop: '5px', paddingBottom: '22px' }}
                        >
                            <div className="left-info">
                                <CorporateFare className="pColor" />
                                <h4>{data?.location_name}</h4>
                            </div>
                        </div>
                        <ul
                            className="user-details-list loyalty-list"
                            style={{ paddingBottom: '10px' }}
                        >
                            <li>
                                <strong>Category Name:</strong> <span>{data?.category_name}</span>
                            </li>
                            <li>
                                <strong>Multiplier:</strong> <span>{data?.multiplier}</span>
                            </li>
                            <li>
                                <strong>Order Type:</strong> <span>{data?.subcategory}</span>
                            </li>
                            <li>
                                <strong>Base:</strong>
                                <span>{data?.base}</span>
                            </li>
                            <li>
                                <strong>Active:</strong>
                                <span>{data?.active ? 'Yes' : 'No'}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        onClick={() =>
                            selectedUserAction({
                                action: 'edit',
                                data: data,
                            })
                        }
                        startIcon={<EditOutlined />}
                        style={{ width: 'fit-content' }}
                    >
                        Edit information
                    </Button>
                    <Button
                        startIcon={<Delete />}
                        style={{ width: 'fit-content' }}
                        variant="secondary"
                        onClick={() =>
                            selectedUserAction({
                                action: 'delete',
                                data: data,
                            })
                        }
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ViewLoyalty;
