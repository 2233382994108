import * as React from 'react';
import 'intersection-observer';
import { Dialog as UIDialog } from '@mui/material';

type DialogProps = {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    paperWidth: string;
    initialFocus?: React.MutableRefObject<null>;
};

export const Dialog = ({ isOpen, onClose, paperWidth, children }: DialogProps) => {
    return (
        <>
            <UIDialog
                className="fixed z-10 inset-0 overflow-y-auto modal-outer-content"
                open={isOpen}
                onBackdropClick={onClose}
                onClose={onClose}
                scroll={'body'}
                sx={{
                    '& .MuiDialog-container': {
                        '& .MuiPaper-root': {
                            width: '100%',
                            background: 'transparent',
                            maxWidth: paperWidth, // Set your width here
                        },
                    },
                }}
            >
                {children}
            </UIDialog>
        </>
    );
};
