import Axios, { AxiosRequestConfig } from 'axios';

import { API_URL } from '@/config';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
    const token = storage.getToken();
    const API_KEY = process.env.REACT_APP_API_KEY;
    if (token) {
        config.headers.authorization = `Bearer ${token}`;
    }
    config.headers['api-key'] = `${API_KEY}`;
    config.headers.Accept = 'application/json';
    return config;
}

export const axios = Axios.create({
    baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem('health_nut_admin_token');
            window.location.href = '/';
        }
        const message = error.response?.data?.message || error.message;
        useNotificationStore.getState().addNotification({
            type: 'error',
            title: 'Error',
            message,
        });

        return Promise.reject(error);
    }
);
