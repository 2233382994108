import React, { useEffect, useState } from 'react';

import editCheck from '../../../assets/edit-check.png';
import { updateCorp } from '../api/CRUD/update';
import { Button } from '@/components/Elements';
import { useNotificationStore } from '@/stores/notifications';


const EditCorporte = ({ corpData, closeDialog, corpsQuery }) => {
    const { addNotification } = useNotificationStore();
    const [data, setData] = useState(false);
    const [isActive, setIsActive] = useState(true);
    useEffect(() => {
        setData(corpData);
    }, [corpData]);
    const handleSubmit = async () => {
        const { success, message } = await updateCorp(data.id, isActive);
        addNotification({
            type: success ? 'success' : 'error',
            title: message,
        });

        closeDialog();
        corpsQuery.refetch();
    };

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="editModalLabel">
                        Edit corporation
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="add-user-form">
                        <form className="user-form" action="">
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="fname" className="control-label">
                                        Corporation Name
                                    </label>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="fname"
                                            placeholder="Mitchell - Bartell"
                                            value={data?.corporate_name || ''}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="umail" className="control-label">
                                        Email
                                    </label>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="umail"
                                            placeholder="mitchellbartell@gmail.com"
                                            value={data?.corporate_email || ''}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="phone" className="control-label">
                                        Phone
                                    </label>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="phone"
                                            placeholder="642-280-6515"
                                            value={data?.corporate_phone || ''}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="date" className="control-label">
                                        Created
                                    </label>
                                    <div className="form-group">
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="date"
                                            placeholder="mm-dd-yyyy"
                                            value={
                                                data?.corporate_creation_date?.split('T')[0] || ''
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div
                                        className="add-user-check"
                                        style={{
                                            borderBottom: 'none',
                                            borderTop: '1px solid #dee2e6',
                                            paddingTop: '15px',
                                            marginTop: '15px',
                                        }}
                                    >
                                        <div
                                            className="add-user-left"
                                            style={{ borderRight: 'none', display: 'flex' }}
                                        >
                                            <label style={{ marginRight: '10px' }}>Active</label>
                                            <div className="form-check form-switch">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexSwitchCheckChecked"
                                                >
                                                    {isActive ? 'Yes' : 'No'}
                                                </label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    name="corporate_active"
                                                    id="flexSwitchCheckChecked"
                                                    checked={isActive}
                                                    onChange={() => setIsActive(!isActive)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        onClick={handleSubmit}
                        startIcon={<img src={editCheck} alt="editCheck" />}
                        style={{ width: 'fit-content' }}
                    >
                        Save Changes
                    </Button>
                    <Button
                        variant="secondary"
                        style={{ width: 'fit-content' }}
                        onClick={() => closeDialog()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditCorporte;
