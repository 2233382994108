import { useQuery } from 'react-query';

import { Coupon } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getAllCoupon = async (): Promise<Coupon[]> => {
    const { data } = await axios.get(`/v1/admin/coupon-code/get-coupon-codes`);
    return data.coupons;
};

type QueryFnType = typeof getAllCoupon;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useCoupon = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['coupon'],
        queryFn: () => getAllCoupon(),
    });
};
