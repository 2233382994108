import { useQuery } from 'react-query';

import { GiftCard } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getAllGiftCard = async (): Promise<GiftCard[]> => {
    const { data } = await axios.get(`/v1/admin/gift-cards/get-gift-cards`);
    return data;
};

type QueryFnType = typeof getAllGiftCard;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useGiftCard = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['giftcard'],
        queryFn: () => getAllGiftCard(),
    });
};
