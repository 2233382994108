import { AddCircleOutline } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import { deleteCorp } from '../api/CRUD/delete';
import { getAllCorps, useCorps } from '../api/CRUD/getAllCorps';
import AddCredit from '../components/AddCredit';
import AddInvoice from '../components/AddInvoice';
import AddUser from '../components/AddUser';
import CreateCorporation from '../components/create';
import EditCorporte from '../components/edit';
import ViewCorporation from '../components/view';
import { dataFields, HeadCells } from '../constant';
import { Corporation } from '../type';
import { useNotificationStore } from '@/stores/notifications';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
import TableWithSort from '@/components/Elements/Table/TableWithSort';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { Confirm } from '@/components/Elements/Dialog/Confirm';
import { Button, Dialog, Spinner } from '@/components/Elements';
//import { HeadCells } from '@/features/users/constant';

import '../styles/styles.css';

export const Corporations = () => {
    const { close, open, isOpen } = useDisclosure();
    const { addNotification } = useNotificationStore();
    // const corpsQuery = useCorps();
    const corpsQuery = {};

    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [corps, setCorps] = useState<Corporation[]>([]);
    const [createCorp, setCreateCorp] = useState<boolean>(false);
    const [editCorp, setEditCorp] = useState<Corporation | null>(null);
    const [viewCorp, setViewCorp] = useState<Corporation | null>(null);
    const [corpId, setCorpId] = useState<null | number>(null);
    const [invoice, setInvoice] = useState<null | number>(null);
    const [confirmMsg, setConfirmMsg] = useState<User | null>(null);
    const [addCredit, setAddCredit] = useState<Corporation | null>(null);

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    async function getCorps() {
        setIsLoading(true);
        // const res = await getAllCorps();
        const res = [
            {
                id: 1,
                corporate_name: 'Appentus Corp',
                corporate_email: 'admine@appentus.com',
                corporate_phone: '1231231er23',
                corporate_creation_date: '2022-07-04T09:18:25.000Z',
                corporate_last_ordered: '2022-06-03T18:30:00.000Z',
                corporate_active: 1,
                assigned_employees: 14,
            },
            {
                id: 3,
                corporate_name: 'Appentus',
                corporate_email: 'admin@appentus.com',
                corporate_phone: '123-123-123',
                corporate_creation_date: '2022-07-27T06:09:41.000Z',
                corporate_last_ordered: null,
                corporate_active: 1,
                assigned_employees: 1,
            },
            {
                id: 4,
                corporate_name: 'OPER',
                corporate_email: 'info@paywithoper.com',
                corporate_phone: '314-529-3948',
                corporate_creation_date: '2022-08-05T01:55:28.000Z',
                corporate_last_ordered: null,
                corporate_active: 1,
                assigned_employees: 0,
            },
        ];
        setCorps(res);
        setIsLoading(false);
    }
    useEffect(() => {
        console.log(corps);
        getCorps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close]);

    if (isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!corps) return null;

    const filteredEmployees = corps?.filter((corp: Corporation) => {
        return (
            corp?.corporate_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
            corp?.corporate_email?.toLowerCase()?.includes(searchVal.toLowerCase())
        );
    });

    const handleDelete = async (corpData: Corporation) => {
        await deleteCorp(corpData.id).then(() => {
            addNotification({
                type: 'success',
                title: 'Corporation Deleted',
            });
        });
        getCorps();
        setConfirmMsg(null);
    };

    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case 'view':
                setEditCorp(null);
                setViewCorp(data);
                setInvoice(null);
                setCreateCorp(false);
                open();
                break;
            case 'edit':
                setViewCorp(null);
                setEditCorp(data);
                setInvoice(null);
                setCreateCorp(false);
                open();
                break;
            case 'add-user':
                setViewCorp(null);
                setEditCorp(null);
                setCorpId(data);
                setInvoice(null);
                setCreateCorp(false);
                open();
                break;
            case 'add-invoice':
                setViewCorp(null);
                setEditCorp(null);
                setCorpId(null);
                setInvoice(data);
                setCreateCorp(false);
                open();
                break;
            case 'delete':
                //handleDelete(data);
                setConfirmMsg(data);
                break;
            default:
                return null;
        }
    };

    const closeDialog = () => {
        setViewCorp(null);
        setEditCorp(null);
        setCorpId(null);
        setCreateCorp(false);
        close();
    };

    return (
        <ContentLayout title="" topNavBarData={{ num: corps.length, title: 'Corporations' }}>
            {confirmMsg ? (
                <Confirm
                    btnTitle={'Delete'}
                    onClose={() => setConfirmMsg(null)}
                    onSuccess={() => handleDelete(confirmMsg)}
                />
            ) : null}
            <div className="table-header">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="employee-table-head">
                                <div className="input-group search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        value={searchVal}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="add-btn" style={{ float: 'right' }}>
                                <Button
                                    startIcon={<AddCircleOutline />}
                                    onClick={() => (setCreateCorp(!createCorp), open())}
                                >
                                    Add Corporation
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <CustomTable
                tableData={filteredEmployees}
                headCells={HeadCells}
                dataFields={dataFields}
                selectedUserAction={selectedUserAction}
                rowOptions={true}
                optionMenu={['edit', 'delete']}
            />
            <Dialog isOpen={isOpen} onClose={closeDialog} paperWidth={'fit-content'}>
                <div className="modals-contents">
                    {createCorp && <CreateCorporation closeDialog={closeDialog} />}
                    {viewCorp && (
                        <ViewCorporation
                            corpData={viewCorp}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                            setViewCorp={setViewCorp}
                            setAddCredit={setAddCredit}
                        />
                    )}
                    {editCorp && (
                        <EditCorporte
                            corpsQuery={corpsQuery}
                            corpData={editCorp}
                            closeDialog={closeDialog}
                        />
                    )}
                    {corpId && <AddUser corpId={corpId} closeDialog={closeDialog} />}
                    {invoice && <AddInvoice corpId={invoice} closeDialog={closeDialog} />}
                    {addCredit && (
                        <AddCredit
                            corpData={addCredit}
                            setAddCredit={setAddCredit}
                            closeDialog={closeDialog}
                        />
                    )}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
