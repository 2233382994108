import {
    // CorporateFare,
    DeleteOutline,
    //   EditOutlined
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Menu, User } from '../type';
import { getProductsByCategory } from '../api/getProducts';
import { getCategories } from '../api/getCategories';
import { useMenuProducts } from '../api/getMenuProducts';
import { Button, Spinner } from '@/components/Elements';
// import Delete from '@/components/Icons/Delete';
// import { handleDateFormat } from '@/utils/commonHelperFunc';

import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

const AssignProducts = ({ menuData, closeDialog, selectedUserAction }:any) => {
    const [data, setData] = useState<Menu | null>(null);
    const [products, setProducts] = useState([]);
    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<string>('');
    const [categories, setCategories] = useState([]);

    console.log(menuData);

    const menuProductsQuery = useMenuProducts(menuData?.id);

    const addProductToMenu = async (product_id:any) => {
        await axios
            .post('v1/admin/menus/add-menu', {
                menu_group: menuData?.id,
                products: [product_id],
            })
            .then(() => {
                menuProductsQuery.refetch();
            });
    };

    // function for getting the dollar price
    const getDollarPrice = (priceInCents:any) => {
        if (priceInCents == 0) {
            return priceInCents;
        } else if (priceInCents == '-') {
            return '0.00';
        } else {
            return (priceInCents / 100).toFixed(2);
        }
    };
    //////////

    useEffect(() => {
        setData(menuData);
    }, [menuData]);

    useEffect(() => {
        getCategories().then((list) => {
            setCategories(list);
            setIsActive(list[3].category_name);
            getProductsByCategory().then((list) => {
                setProducts(list);
            });
        });
    }, []);

    const handleCategoryChange = (category: any) => {
        setIsActive(category.category_name);
    };

    console.log(categories);

    const showAssignSuccess = () => {
        useNotificationStore.getState().addNotification({
            type: 'success',
            title: 'Success',
            message: 'Products Assigned to Menu!',
        });
    };

    const filteredProducts = products
        ?.filter((product:any) => {
            return product?.product_name?.toLowerCase()?.includes(searchVal.toLowerCase());
        })
        .filter((product:any) => {
            return product?.category?.category_name
                ?.toLowerCase()
                ?.includes(isActive.toLowerCase());
        });

    const filteredCategories = categories?.filter((category:any) =>
        category.category_name === 'Hero' || category.category_name === 'Recommended'
            ? null
            : category
    );

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        {data?.menu_group_name} / Assign Products
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12 col-md-8 border-r">
                            <div className="order-new-search pb-4 pt-4 d-flex justify-content-between align-items-center">
                                <p className="mb-0">
                                    <strong>Products</strong>
                                </p>
                                <div
                                    className="input-group search make-search"
                                    style={{ width: 'max-content' }}
                                >
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        style={{ margin: '0 !important' }}
                                        value={searchVal}
                                        onChange={(e) => setSearchVal(e.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="order-list-tabs">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    {categories?.map((category) => {
                                        const { id, category_name } = category;
                                        return (
                                            <li key={id} className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${
                                                        isActive === category_name && 'active'
                                                    }`}
                                                    type="button"
                                                    role="tab"
                                                    onClick={() =>
                                                        isActive !== category_name
                                                            ? handleCategoryChange(category)
                                                            : null
                                                    }
                                                >
                                                    {category_name}
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <div className="row">
                                    {products.length === 0 ? (
                                        <h2>No products avialable</h2>
                                    ) : (
                                        filteredProducts?.map((product) => (
                                            <div
                                                className="col-12 col-md-6"
                                                key={product?.id}
                                                onClick={(e) => {}}
                                            >
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="flex justify-between items-center">
                                                            <div className="flex gap-2">
                                                                <div>
                                                                    <img
                                                                        className="w-10"
                                                                        alt="cookie"
                                                                        src={product.product_image}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <h5
                                                                        className="card-title"
                                                                        style={{
                                                                            color: '#111111',
                                                                            fontWeight: '600',
                                                                            fontSize: '16px',
                                                                        }}
                                                                    >
                                                                        {product?.product_name}
                                                                    </h5>

                                                                    <p className="">
                                                                        $
                                                                        {getDollarPrice(
                                                                            product?.product_base_price
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="all-bx mt-2">
                                                                <label className="checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="selected_product"
                                                                        name="selected_product"
                                                                        value={product?.id}
                                                                        onChange={() => {
                                                                            addProductToMenu(
                                                                                product?.id
                                                                            );
                                                                        }}
                                                                    />
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="pb-2">
                                <strong>Selected Products</strong>
                            </div>
                            <hr />
                            <div>
                                <table className="min-w-full divide-y divide-gray-200">
                                    <tbody>
                                        {menuProductsQuery.isFetching ? (
                                            <div className="w-full h-48 flex justify-center items-center">
                                                <Spinner size="sm" />
                                            </div>
                                        ) : (
                                            menuProductsQuery?.data?.map((product) => (
                                                <tr key={product?.id} className="noborder">
                                                    <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                        {product?.product?.product_name} /{' '}
                                                        <span className="text-blue-600">
                                                            $
                                                            {product?.price_override || '(Not Set)'}
                                                        </span>
                                                    </td>

                                                    <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                        <span className="dark-tx">
                                                            <DeleteOutline />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        onClick={() => {
                            showAssignSuccess();
                            closeDialog();
                        }}
                        startIcon={<CheckCircleOutlineIcon />}
                        style={{ width: 'fit-content' }}
                    >
                        Assign Products
                    </Button>
                    <Button
                        style={{ width: 'fit-content' }}
                        variant="secondary"
                        onClick={() => closeDialog()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AssignProducts;
