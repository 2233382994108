import { useQuery } from 'react-query';

import { User } from '../types';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getUsers = async (): Promise<User[]> => {
    const { data } = await axios.post(`/v1/admin/users/get-users`);
    return data.users;
};

type QueryFnType = typeof getUsers;

type UseUsersOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useUsers = ({ config }: UseUsersOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['users'],
        queryFn: () => getUsers(),
    });
};
