import { CorporateFare, EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import { Pos, User } from '../type';
import { Button } from '@/components/Elements';
import Delete from '@/components/Icons/Delete';
import { handleDateFormat } from '@/utils/commonHelperFunc';

// import { axios } from '@/lib/axios';

const ViewPosDevice = ({ posData, closeDialog, selectedUserAction }) => {
    const [data, setData] = useState<Pos | null>(null);

    useEffect(() => {
        setData(posData);
    }, [posData]);

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        POS Device #{data?.id}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <div
                            className="info-header"
                            style={{ paddingTop: '5px', paddingBottom: '22px' }}
                        >
                            <div className="left-info">
                                <CorporateFare className="pColor" />
                                <h4>{data?.serial_number}</h4>
                            </div>
                        </div>
                        <ul className="user-details-list" style={{ paddingBottom: '10px' }}>
                            <li>
                                <strong>Serial Number:</strong> <span>{data?.serial_number}</span>
                            </li>
                            <li>
                                <strong>App Version:</strong> <span>{data?.app_version}</span>
                            </li>
                            <li>
                                <strong>Device Name:</strong> <span>{data?.name}</span>
                            </li>
                            <li>
                                <strong>Created:</strong>{' '}
                                <span>{handleDateFormat(data?.created_at)}</span>
                            </li>
                            <li>
                                <strong>Locaton:</strong>
                                <span>{data?.location_name}</span>
                            </li>
                            <li>
                                <strong>Last Update:</strong>{' '}
                                <span>{handleDateFormat(data?.updated_at)}</span>
                            </li>
                            <li>
                                <strong>Active:</strong>
                                <span>{data?.status ? 'Yes' : 'No'}</span>
                            </li>
                            <li>
                                <strong>Reader ID:</strong>
                                <span>{data?.reader_id}</span>
                            </li>
                            <li>
                                <strong>Printer Address:</strong>
                                <span>{data?.print_address}</span>
                            </li>

                            <li>
                                <strong>POS Key:</strong>
                                <span>{data?.poskey}</span>
                            </li>
                            <li>
                                <strong>Terminal Key:</strong>
                                <span>{data?.terminalkey}</span>
                            </li>
                            <li>
                                <strong>Boh Print Address:</strong>
                                <span>{data?.boh_print_address}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        onClick={() =>
                            selectedUserAction({
                                action: 'edit',
                                data: data,
                            })
                        }
                        startIcon={<EditOutlined />}
                        style={{ width: 'fit-content' }}
                    >
                        Edit information
                    </Button>
                    <Button
                        startIcon={<Delete />}
                        style={{ width: 'fit-content' }}
                        variant="secondary"
                        onClick={() =>
                            selectedUserAction({
                                action: 'delete',
                                data: data,
                            })
                        }
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ViewPosDevice;
