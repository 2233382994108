// import { CorporateFare, EditOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
// import { Skeleton } from '@mui/material';
import moment from 'moment';
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import Typography from '@mui/material/Typography';
import {
    GiftCard,
    //  User
} from '../type';
// import { handleDateFormat } from '@/utils/commonHelperFunc';
// import Delete from '@/components/Icons/Delete';
// import { Button } from '@/components/Elements';

// import { axios } from '@/lib/axios';

const ViewGiftCard = ({ giftcardData, closeDialog, selectedUserAction }) => {
    const [data, setData] = useState<GiftCard | null>(null);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [transactionData, setTransactionData] = useState<any | null>(null);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    // function for getting the dollar price
    const getDollarPrice = (priceInCents) => {
        if (priceInCents == 0) {
            return priceInCents;
        } else if (priceInCents == '-') {
            return '0.00';
        } else {
            return (priceInCents / 100).toFixed(2);
        }
    };
    //////////

    useEffect(() => {
        setData(giftcardData);
    }, [giftcardData]);

    console.log(data);

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        GiftCard
                        {/* #{data?.id} */}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <div
                            className="info-header"
                            style={{ paddingTop: '5px', paddingBottom: '22px' }}
                        >
                            <div className="left-info">
                                <PermIdentityOutlinedIcon className="pColor" />
                                <h4>{data?.customer}</h4>
                            </div>
                            <div className="left-info">
                                <CardGiftcardOutlinedIcon className="pColor" />
                                <strong>Remaining Budget:</strong>
                                <span className="pColor">
                                    &nbsp; $
                                    {getDollarPrice(
                                        data?.gift_card_amount_raw - data?.gift_card_redemption
                                    )}
                                </span>
                            </div>
                        </div>
                        <div
                            className="info-header"
                            style={{ paddingTop: '22px', paddingBottom: '5px' }}
                        >
                            <div className="left-info">
                                <strong>Details</strong>
                            </div>
                            <div className="flex gap-3">
                                <label htmlFor="active">Active:</label>
                                Yes
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        name="active"
                                        defaultChecked={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <ul className="user-details-list" style={{ paddingBottom: '10px' }}>
                            <li>
                                <strong>Card ID:</strong> <span>{data?.gift_card_code}</span>
                            </li>
                            <li>
                                <strong>Last Used:</strong> <span></span>
                            </li>
                            <li>
                                <strong>Purchased Amount:</strong>{' '}
                                <span>$ {getDollarPrice(data?.gift_card_redemption)}</span>
                            </li>
                            <li>
                                <strong>Purchased:</strong>
                                <span>
                                    {moment(data?.gift_card_purchased).format(
                                        'MM/DD/YYYY, HH:mm A'
                                    )}
                                </span>
                            </li>
                            <li>
                                <strong>Created By:</strong>
                                <span>{data?.gift_card_purchaser}</span>
                            </li>
                        </ul>
                    </div>

                    {/* <Accordion
                        expanded={expanded === 'panel2'}
                        onChange={handleChange('panel2')}
                        style={{
                            maxWidth: '100%',
                            boxShadow: 'none',
                            borderBottom: '1px solid #c8d4df',
                            padding: '10px 0',
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            style={{ width: '100%', padding: '0' }}
                        >
                            <p className="box-cookie-tx">
                                <span>
                                    <strong>Transaction History</strong>
                                </span>{' '}
                            </p>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{
                                padding: '8px 0',
                                borderTop: '1px solid #c8d4df',
                            }}
                        >
                            <Typography>
                                <ul className="order-listing">
                                    {data?.transaction.map(
                                        (
                                            {
                                                id,
                                                transaction_amount,
                                                transaction_time,
                                                transaction_status,
                                            },
                                            index
                                        ) => (
                                            <li key={index}>
                                                <p className="cookie-flx">
                                                    <span className="text-left">
                                                        Order <span className="pColor">#{id}</span>
                                                        &nbsp;&nbsp;
                                                        <span className="text-red-500">
                                                            -${transaction_amount}
                                                        </span>
                                                    </span>

                                                    <div className="flex">
                                                        <p className="text-left">
                                                            {moment(transaction_time).format(
                                                                'MM/DD/YYYY, hh:mm A'
                                                            )}{' '}
                                                            / &nbsp;
                                                        </p>

                                                        <span>{transaction_status}</span>
                                                    </div>
                                                </p>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion> */}
                </div>
            </div>
        </div>
    );
};

export default ViewGiftCard;
