import { Button } from '@/components/Elements';
import { svgPaths } from '@/components/Layout/SvgPaths';

export function TableHeader(
    searchVal: string,
    handleSearch: (e: any) => void,
    statusFilter: string,
    setStatusFilter: any,
    checkboxFilter: string,
    setCheckboxFilter: any,
    selectedUserAction: any
) {
    const order_status_list = [
        'new',
        'received',
        'packaging',
        'assigned',
        'dispatched',
        'delivered',
    ];
    return (
        <div className="table-header">
            <form>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="employee-table-head make-order flex gap-4">
                            <div className="checkboxes flex align-items-center gap-4">
                                <div className="all-bx">
                                    <label className="checkbox mb-0">
                                        <input
                                            type="checkbox"
                                            id="all"
                                            name="all"
                                            value="All"
                                            checked={checkboxFilter === 'all'}
                                            onChange={() =>
                                                checkboxFilter !== 'all' && setCheckboxFilter('all')
                                            }
                                        />
                                        <span>All</span>
                                    </label>
                                </div>
                                <div className="all-bx Pickup">
                                    <label className="checkbox mb-0">
                                        <input
                                            type="checkbox"
                                            id="Pickup"
                                            name="Pickup"
                                            value="Pickup"
                                            checked={checkboxFilter === 'Pickup'}
                                            onChange={() =>
                                                checkboxFilter === 'Pickup'
                                                    ? setCheckboxFilter('all')
                                                    : setCheckboxFilter('Pickup')
                                            }
                                        />
                                        <span>Pickup</span>
                                    </label>
                                </div>
                                <div className="all-bx Delivery">
                                    <label className="checkbox mb-0">
                                        <input
                                            type="checkbox"
                                            id="Delivery"
                                            name="Delivery"
                                            value="Delivery"
                                            checked={checkboxFilter === 'Delivery'}
                                            onChange={() =>
                                                checkboxFilter === 'Delivery'
                                                    ? setCheckboxFilter('all')
                                                    : setCheckboxFilter('Delivery')
                                            }
                                        />
                                        <span>Delivery</span>
                                    </label>
                                </div>
                                <div className="all-bx In-Store">
                                    <label className="checkbox mb-0">
                                        <input
                                            type="checkbox"
                                            id="In-Store"
                                            name="In-Store"
                                            value="In-Store"
                                            checked={checkboxFilter === 'Dine-In'}
                                            onChange={() =>
                                                checkboxFilter === 'Dine-In'
                                                    ? setCheckboxFilter('all')
                                                    : setCheckboxFilter('Dine-In')
                                            }
                                        />
                                        <span>Dine-In</span>
                                    </label>
                                </div>
                                <form>
                                    <div className="row">
                                        <div>
                                            <div className="flex pos-devces">
                                                <div className="input-group search">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search"
                                                        value={searchVal}
                                                        onChange={(e) => handleSearch(e)}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">
                                                            <i className="fas fa-search"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="input-group ">
                                <div className="form-group w-50">
                                    <select
                                        value={statusFilter}
                                        className="form-control w-100"
                                        onChange={(e) => setStatusFilter(e.target.value)}
                                    >
                                        <option value="">Select Status</option>
                                        {order_status_list.map((status) => (
                                            <option
                                                key={status}
                                                value={status}
                                                className="capitalize"
                                            >
                                                {status}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-12 col-md-4">
                        <div className="add-btn" style={{ float: 'right' }}>
                            <Button
                                startIcon={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={svgPaths['Orders'].width}
                                        height={svgPaths['Orders'].height}
                                        viewBox={svgPaths['Orders'].viewBox}
                                        fill="none"
                                    >
                                        <path d={svgPaths['Orders'].path} fill="#fff" />
                                    </svg>
                                }
                                onClick={() => selectedUserAction({ action: 'create', data: null })}
                            >
                                Make Order
                            </Button>
                        </div>
                    </div> */}
                </div>
            </form>
        </div>
    );
}
