import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
//import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import * as React from 'react';

import './index.css';
import { Button, Grid, TableFooter } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import ProductPlaceholder from '../../../assets/logo.png';
import { handleDateFormat } from '@/utils/commonHelperFunc';

interface Data {
    calories: number;
    carbs: number;
    fat: number;
    name: string;
    protein: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (typeof b[orderBy] == 'string' || typeof a[orderBy] == 'string') {
        if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
            return -1;
        }
        if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
            return 1;
        }

        return 0;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }

        return 0;
    }
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    sort: boolean;
}

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: [HeadCell];
}

// function for getting the dollar price
const getDollarPrice = (priceInCents: any) => {
    if (priceInCents == 0) {
        return priceInCents;
    } else if (priceInCents == '-') {
        return '0.00';
    } else {
        return (priceInCents / 100).toFixed(2);
    }
};
//////////

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells, rowCount } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const Icon = () => {
        return (
            <span
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'Center',
                    alignItems: 'Center',
                    marginLeft: '5px',
                }}
            >
                {/*{order === 'asc' ? (*/}
                <KeyboardArrowUp fontSize="inherit" />
                {/*) : (*/}
                <KeyboardArrowDown fontSize="inherit" />
                {/*)}*/}
            </span>
        );
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={
                            headCell.sort ? (orderBy === headCell.id ? order : false) : false
                        }
                        style={{ pointerEvents: headCell.sort ? 'auto' : 'none' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={
                                headCell.sort ? (orderBy === headCell.id ? order : 'asc') : order
                            }
                            onClick={headCell.sort ? createSortHandler(headCell.id) : null}
                            IconComponent={headCell.sort ? Icon : ''}
                        >
                            {headCell.label}
                            {/*{orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    <KeyboardArrowUp fontSize="inherit" />
                                    <KeyboardArrowDown fontSize="inherit" />
                                </Box>
                            ) : null}*/}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function CustomTable({
    tableData,
    headCells,
    dataFields,
    selectedUserAction,
    bodyCells,
    optionMenu,
    rowOptions,
}: any) {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>(headCells[0].id);
    const [page, setPage] = React.useState(0);
    const rowsPerPages = 100;
    const rowsPerPageOptions = [50, 100, 500, 1000, 5000];
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]);
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    React.useEffect(() => {
        setPage(0);
        // if (tableData[0]?.order_status) {
        //     setOrder('desc');
        // }
        // else if (tableData[0]?.product_Names) {
        //     setOrder('desc');
        // }
    }, [tableData]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const column = (columnData: any, index: number) => {
        switch (headCells[index]?.type) {
            case 'number':
                return columnData || '0';
            case 'text':
                return columnData || '-';
            case 'date':
                return handleDateFormat(columnData) || '-';
            case 'dateTime':
                if (columnData == null) {
                    return '-';
                }
                return moment(columnData).format('MM/DD/YYYY, HH:mm A') || '-';
            case 'boolean':
                return columnData ? 'Yes' : 'No';
            case 'image':
                return columnData ? (
                    <img src={/*columnData*/ columnData} alt={''} style={{ height: '50px' }} />
                ) : (
                    <img
                        src={/*columnData*/ ProductPlaceholder}
                        alt={''}
                        style={{ height: '50px' }}
                    />
                );
            default:
                return columnData || '-';
        }
    };
    const timeDiff = (promisedTime: string, CreatedTime: string) => {
        const a = moment(promisedTime);
        const b = moment(CreatedTime);
        const diff_s = a.diff(b, 'minutes');
        return diff_s + ' min';
        //return moment.utc(moment.duration(diff_s, 'seconds').asMilliseconds()).format('hh:mm');
    };
    // Function to handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page number when rows per page changes
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2, boxShadow: 'none' }}>
                <TableContainer sx={{ minHeight: '40vh' }}>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={tableData.length}
                            headCells={headCells}
                        />
                        {tableData[0]?.product_Names ? (
                            <TableBody
                            // sx={{'.css-1ex1afd-MuiTableCell-root':{textAlign:'center',display:'flex',alignItems:'center',justifyContent:'center'},'.css-11i54vy-MuiTableCell-root':{textAlign:'center',display:'inline-block',alignItems:'center',justifyContent:'center'}}}
                            >
                                {tableData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row: any) => {
                                        return (
                                            <TableRow hover tabIndex={-1} key={row.id}>
                                                {dataFields.map((fieldName: any, index: number) => {
                                                    return dataFields.length != index + 1 ? (
                                                        <TableCell
                                                            key={index}
                                                            // align="center"
                                                            sx={{
                                                                display:
                                                                    dataFields.length == index + 1
                                                                        ? 'flex'
                                                                        : 'table-cell',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                textAlign: 'center',
                                                            }}
                                                            onClick={() =>
                                                                selectedUserAction({
                                                                    action: 'view',
                                                                    data: row,
                                                                })
                                                            }
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    // width: 'max-content',
                                                                    whiteSpace: 'nowrap',
                                                                }}
                                                                className={`${
                                                                    index === 0 &&
                                                                    row?.is_ambassador
                                                                        ? 'is_ambassador-indicator'
                                                                        : ''
                                                                } ${
                                                                    index === 0 && row?.vip
                                                                        ? 'vip-indicator'
                                                                        : ''
                                                                } ${
                                                                    bodyCells
                                                                        ? `${bodyCells[index]?.background} ${fieldName}-${row[fieldName]}`
                                                                        : ''
                                                                }`}
                                                            >
                                                                {bodyCells &&
                                                                    bodyCells[index]?.prefix}
                                                                {fieldName === 'order_total'
                                                                    ? getDollarPrice(
                                                                          column(
                                                                              row[fieldName],
                                                                              index
                                                                          )
                                                                      )
                                                                    : column(row[fieldName], index)}
                                                                {/* {fieldName === 'order_promised_time'
                                                                ? timeDiff(
                                                                      row[fieldName],
                                                                      row['order_creation_date']
                                                                  )
                                                                : column(row[fieldName], index)} */}
                                                                {/*{bodyCells && bodyCells[index]?.postfix}*/}
                                                            </p>
                                                        </TableCell>
                                                    ) : (
                                                        // {optionMenu == [] ?
                                                        <TableCell
                                                            key={index + 1}
                                                            className="dropdown"
                                                        >
                                                            <span>
                                                                {column(row[fieldName], index)}
                                                            </span>
                                                            {rowOptions && optionMenu.length > 0 ? (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className="btn p-0 ml-4"
                                                                        data-bs-toggle="dropdown"
                                                                        style={{
                                                                            width: 'min-content',
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu">
                                                                        {optionMenu?.map(
                                                                            (
                                                                                option: any,
                                                                                index: any
                                                                            ) => (
                                                                                <button
                                                                                    className="dropdown-item capitalize"
                                                                                    key={index}
                                                                                    onClick={() =>
                                                                                        selectedUserAction(
                                                                                            {
                                                                                                action: option,
                                                                                                data: row,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {option}
                                                                                </button>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </>
                                                            ) : null}
                                                        </TableCell>
                                                        // :""}
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                {/* {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                            </TableBody>
                        ) : (
                            <TableBody
                            // sx={{'.css-1ex1afd-MuiTableCell-root':{textAlign:'center',display:'flex',alignItems:'center',justifyContent:'center'},'.css-11i54vy-MuiTableCell-root':{textAlign:'center',display:'inline-block',alignItems:'center',justifyContent:'center'}}}
                            >
                                {tableData
                                    .sort(getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row: any) => {
                                        return (
                                            <TableRow hover tabIndex={-1} key={row.id}>
                                                {dataFields.map((fieldName: any, index: number) => {
                                                    return dataFields.length != index + 1 ? (
                                                        <TableCell
                                                            key={index}
                                                            // align="center"
                                                            sx={{
                                                                display:
                                                                    dataFields.length == index + 1
                                                                        ? 'flex'
                                                                        : 'table-cell',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                textAlign: 'center',
                                                            }}
                                                            onClick={() =>
                                                                selectedUserAction({
                                                                    action: 'view',
                                                                    data: row,
                                                                })
                                                            }
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    // width: 'max-content',
                                                                    whiteSpace: 'nowrap',
                                                                }}
                                                                className={`${
                                                                    index === 0 &&
                                                                    row?.is_ambassador
                                                                        ? 'is_ambassador-indicator'
                                                                        : ''
                                                                } ${
                                                                    index === 0 && row?.vip
                                                                        ? 'vip-indicator'
                                                                        : ''
                                                                } ${
                                                                    bodyCells
                                                                        ? `${bodyCells[index]?.background} ${fieldName}-${row[fieldName]}`
                                                                        : ''
                                                                }`}
                                                            >
                                                                {bodyCells &&
                                                                    bodyCells[index]?.prefix}
                                                                {fieldName === 'order_total'
                                                                    ? getDollarPrice(
                                                                          column(
                                                                              row[fieldName],
                                                                              index
                                                                          )
                                                                      )
                                                                    : column(row[fieldName], index)}
                                                                {/* {fieldName === 'order_promised_time'
                                                                ? timeDiff(
                                                                      row[fieldName],
                                                                      row['order_creation_date']
                                                                  )
                                                                : column(row[fieldName], index)} */}
                                                                {/*{bodyCells && bodyCells[index]?.postfix}*/}
                                                            </p>
                                                        </TableCell>
                                                    ) : (
                                                        // {optionMenu == [] ?
                                                        <TableCell
                                                            key={index + 1}
                                                            className="dropdown"
                                                        >
                                                            <span>
                                                                {column(row[fieldName], index)}
                                                            </span>
                                                            {rowOptions && optionMenu.length > 0 ? (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className="btn p-0 ml-4"
                                                                        data-bs-toggle="dropdown"
                                                                        style={{
                                                                            width: 'min-content',
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu">
                                                                        {optionMenu?.map(
                                                                            (
                                                                                option: any,
                                                                                index: any
                                                                            ) => (
                                                                                <button
                                                                                    className="dropdown-item capitalize"
                                                                                    key={index}
                                                                                    onClick={() =>
                                                                                        selectedUserAction(
                                                                                            {
                                                                                                action: option,
                                                                                                data: row,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {option}
                                                                                </button>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </>
                                                            ) : null}
                                                        </TableCell>
                                                        // :""}
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                {/* {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    sx={{
                        display:
                            // subscribers.length < 0 ?
                            {
                                xs: 'block',
                                md: 'flex',
                            },
                        // : "none"
                        justifyContent: { xs: 'flex-end', md: 'flex-end' },
                        width: '100% !important',
                        alignItems: 'center',
                        background: '#FFF',
                        padding: '20px',
                    }}
                >
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                ActionsComponent={TablePaginationActions}
                                showFirstButton={true}
                                showLastButton={true}
                                colSpan={3}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                component="div"
                                count={tableData.length}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={rowsPerPageOptions}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                nextIconButtonProps={{
                                    style: {
                                        height: '40px',
                                        width: '40px',
                                        color: '#000',
                                        backgroundColor: 'grey',
                                        display: 'block !important',
                                    },
                                    'aria-hidden': false,
                                    color: 'primary',
                                }}
                                backIconButtonProps={{
                                    style: {
                                        height: '40px',
                                        width: '40px',
                                        color: '#000',
                                        backgroundColor: 'grey',
                                        marginRight: '10px',
                                    },
                                    'aria-hidden': false,
                                    color: 'primary',
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                    {/* <TableFooter>
                        <TableRow>
                            <TablePagination
                                ActionsComponent={TablePaginationActions}
                                showFirstButton={true}
                                showLastButton={true}
                                // nextIconButtonProps={{
                                //     style: {
                                //         height: '40px',
                                //         width: '40px',
                                //         color: '#000',
                                //         backgroundColor: 'grey',
                                //         display: 'block !important',
                                //     },
                                //     'aria-hidden': false,
                                //     color: 'primary',
                                // }}
                                // backIconButtonProps={{
                                //     style: {
                                //         height: '40px',
                                //         width: '40px',
                                //         color: '#000',
                                //         backgroundColor: 'grey',
                                //         marginRight: '10px',
                                //     },
                                //     'aria-hidden': false,
                                //     color: 'primary',
                                // }}
                                // component="div"
                                // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                // colSpan={3}
                                // count={100}
                                // rowsPerPage={rowsPerPage}
                                // page={page}
                                // SelectProps={{
                                //     inputProps: {
                                //         'aria-label': 'rows per page',
                                //     },
                                //     native: true,
                                // }}
                                // onPageChange={handleChangePage}
                                // onRowsPerPageChange={handleChangeRowsPerPage}
                                // ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter> */}
                    {/* <Box
                        sx={{
                            border: '2px solid #E7ECF7',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ml: '10px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (page > 1) {
                                console.log('dsvionm');
                            }
                        }}
                    >
                        <ArrowLeftIcon />
                    </Box>
                    <Box
                        sx={{
                            border: '2px solid #E7ECF7',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ml: '10px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            console.log('dsvionm');
                        }}
                    >
                        <ArrowRightIcon />
                    </Box> */}
                </Grid>
            </Paper>
        </Box>
    );
}
