import { useQuery } from 'react-query';

import { Delivery } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getAllDelivery = async (): Promise<Delivery[]> => {
    const { data } = await axios.get(`v1/admin/orders/get-orders`);
    const a = [];
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const statusLength = element.status.length;
        if (element.order_type.subcategory == 'Delivery') {
            const k = {
                id: element.id,
                order_creation_date: element.order_creation_date,
                //order_cart_group_id: element,
                //order_transaction_id: 1171,
                //order_subtotal: 60000,
                //order_tax: 4500,
                //order_delivery_fee: 800,
                //order_tip: 132,
                order_total: element.order_total,
                //order_instructions: null,
                //gift_card_id: null,
                //order_gift_card_amount: 0,
                //coupon_code_id: null,
                //order_coupon_code_discount: 0,
                order_user_id: element.user ? element.user.id : 0,
                // order_mode_id: 1,
                order_type_id: element.order_type.id,
                order_location_id: element.order_location.id,
                order_promised_time: element.order_promised_time,
                //credit_amount: 0,
                first_name: element.user
                    ? element.user.first_name
                    : element.guest[0]
                    ? element.guest[0].guest_first_name
                    : 0,
                last_name: element.user ? element.user.last_name : 0,
                user_email: element.user ? element.user.user_email : 0,
                user_phone: element.user ? element.user.user_phone : 0,
                location_name: element.order_location.location_name,
                // order_delivery_address:
                //     '3107 South Grand Boulevard, Saint Louis, MO 63118, United States of America',
                guest_name: element.guest[0] ? element.guest[0].guest_first_name : 0,
                guest_phone: element.guest[0] ? element.guest[0].guest_phone : 0,
                order_status: element.status[statusLength - 1].order_status,
                order_type: element.order_type.subcategory,
            };
            a.push(k);
        }
    }
    return a;
};

type QueryFnType = typeof getAllDelivery;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useDelivery = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['delivery'],
        queryFn: () => getAllDelivery(),
    });
};
