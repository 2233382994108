// import { axios } from '@/lib/axios';

export const getProduct = async (pId: number, groupId: number) => {
    // const { data } = await axios.get(`v1/admin/products/get-product?id=${pId}`);
    // return data;
    return {
        id: 20,
        product_category_id: 12,
        product_type_id: 3,
        product_name: 'Balloon & Bow Gift Packaging',
        product_description: '',
        product_image: '',
        product_upccode: '',
        product_base_price: 4,
        product_active: 1,
        modifiers: [
            {
                id: 1,
                product_id: 20,
                product_category_id: null,
                mod_group_id: 1,
                modification_text: 'Balloon Choice',
                is_required: 1,
                select_min: 1,
                select_max: 1,
                active: 1,
                pmg_active: 1,
                options: [
                    {
                        id: 1,
                        mod_group_id: 1,
                        option_name: 'Birthday Balloon',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 2,
                        mod_group_id: 1,
                        option_name: 'Congrats Balloon',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 3,
                        mod_group_id: 1,
                        option_name: 'Get Well Soon Balloon',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 4,
                        mod_group_id: 1,
                        option_name: 'Good Luck Balloon',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 5,
                        mod_group_id: 1,
                        option_name: 'I Love You Balloon',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 6,
                        mod_group_id: 1,
                        option_name: 'Thank You Balloon',
                        option_price: 0,
                        active: 1,
                    },
                ],
            },
            {
                id: 3,
                product_id: 20,
                product_category_id: null,
                mod_group_id: 2,
                modification_text: 'Card Choice',
                is_required: 1,
                select_min: 1,
                select_max: 1,
                active: 1,
                pmg_active: 1,
                options: [
                    {
                        id: 7,
                        mod_group_id: 2,
                        option_name: 'Love You More than Cookies Card',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 8,
                        mod_group_id: 2,
                        option_name: "You're the Milk to My Cookies Card",
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 9,
                        mod_group_id: 2,
                        option_name: 'I Love You Card',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 10,
                        mod_group_id: 2,
                        option_name: 'Birthday Card',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 11,
                        mod_group_id: 2,
                        option_name: 'Congrats Card',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 12,
                        mod_group_id: 2,
                        option_name: 'Thank You Card',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 13,
                        mod_group_id: 2,
                        option_name: 'Good Luck Card',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 14,
                        mod_group_id: 2,
                        option_name: 'Get Well Soon Card',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 15,
                        mod_group_id: 2,
                        option_name: 'Any Occasion Card',
                        option_price: 0,
                        active: 1,
                    },
                    {
                        id: 16,
                        mod_group_id: 2,
                        option_name: 'Happy Holidays',
                        option_price: 0,
                        active: 1,
                    },
                ],
            },
            {
                id: 5,
                product_id: 20,
                product_category_id: 3,
                mod_group_id: null,
                modification_text: 'Pick Cookie Dough',
                is_required: 1,
                select_min: 1,
                select_max: 6,
                active: 1,
                pmg_active: null,
                options: [
                    {
                        id: 3,
                        category_name: 'Cookies',
                        list_order: 2,
                        active: 1,
                    },
                ],
            },
        ],
    };
};
