import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { useEffect, useState } from 'react';
import { Button } from '../Button';
import Delete from '@/components/Icons/Delete';

type DeleteCommentProps = {
    btnTitle: string;
    onClose: () => void;
    onSuccess: () => void;
    orderPrice?: string;
};

export const Confirm = ({ btnTitle, onClose, onSuccess, orderPrice }: DeleteCommentProps) => {
    const [checkboxFilter, setCheckboxFilter] = useState<string>('');
    const [orderTotalInCents, setOrderTotalInCents] = useState<string>('');
    useEffect(() => {
        if (orderPrice) {
            setOrderTotalInCents((orderPrice / 100).toFixed(2));
            localStorage.setItem('amount', orderPrice);
        }
    }, []);
    return (
        <Dialog
            open={true}
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
        >
            <DialogTitle>Confirm action</DialogTitle>
            <DialogContent dividers>
                Are you sure you want to {btnTitle} ?{' '}
                <div
                    style={{
                        display: btnTitle == 'Refund Order' ? 'block' : 'none',
                        // justifyContent: 'space-between',
                        width: '100%',
                        padding: '10px',
                    }}
                >
                    {/* <label className="checkbox mt-3 ">
                        <input
                            style={{ width: '30px' }}
                            type="checkbox"
                            id="All"
                            name="ALl"
                            value="ALl"
                            checked={checkboxFilter === 'all'}
                            onChange={() => {
                                checkboxFilter === 'all'
                                    ? setCheckboxFilter('')
                                    : setCheckboxFilter('all');
                            }}
                        />
                        <span style={{ fontSize: '20px' }}>All</span>
                    </label> */}
                    <input
                        type="number"
                        className="form-control"
                        name="option_price"
                        value={orderTotalInCents}
                        placeholder="$0"
                        onChange={(e) => {
                            setOrderTotalInCents(e.target.value);
                            const data: any = localStorage.setItem('amount', e.target.value * 100);
                        }}
                        required
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '10px',
                    }}
                >
                    <Button
                        startIcon={<Delete color={'white'} />}
                        type="button"
                        onClick={() => {
                            if (btnTitle == 'Refund Order') {
                                if (orderPrice / 100 >= orderTotalInCents) {
                                    onSuccess();
                                } else {
                                    alert('Amount Should be less than order Total');
                                }
                            } else {
                                onSuccess();
                            }
                        }}
                        style={{ width: 'fit-content' }}
                    >
                        {btnTitle}
                    </Button>
                    <Button
                        type="button"
                        onClick={onClose}
                        variant="secondary"
                        style={{ width: 'fit-content' }}
                    >
                        Cancel
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};
