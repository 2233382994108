// import { AddCircleOutline } from '@mui/icons-material';
import { useEffect, useState } from 'react';
// import { Alert } from '../type';
import { useNotificationStore } from '@/stores/notifications';
// import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
// import TableWithSort from '@/components/Elements/Table/TableWithSort';
import {
    Button,
    // Dialog,
    Spinner,
} from '@/components/Elements';
//import { HeadCells } from '@/features/users/constant';

import '../styles/styles.css';
import { axios } from '@/lib/axios';

export const AlertPage = () => {
    const [alertVal, setAlertVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [alertActive, setAlertActive] = useState<boolean>(false);

    // const getAlert = () => {
    //     axios.get('/v1/users/get-alert').then((res) => {
    //         setAlertVal(res.data?.message);
    //         setAlertActive(res.data?.active);
    //     });
    // };

    const updateAlert = () => {
        axios
            .post('/v1/admin/users/update-alert', {
                message: alertVal,
                active: alertActive,
            })
            .then(() => {
                showSuccess();
            });
    };

    const showSuccess = () => {
        useNotificationStore.getState().addNotification({
            type: 'success',
            title: 'Success',
            message: 'Alert Updated!',
        });
    };

    // useEffect(() => {
    //     getAlert();
    // }, []);

    // function for getting the dollar price
    // const getDollarPrice = (priceInCents) => {
    //     if (priceInCents == 0) {
    //         return priceInCents;
    //     } else if (priceInCents == '-') {
    //         return '0.00';
    //     } else {
    //         return (priceInCents / 100).toFixed(2);
    //     }
    // };
    //////////

    const handleSearch = (e: any) => {
        setAlertVal(e.target.value);
    };

    if (isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: `${alertActive ? '#ffe0cb' : '#fff'}`,
            }}
        >
            <ContentLayout title="" topNavBarData={{ num: 1, title: 'Alert' }}>
                <div className="table-header">
                    <form>
                        <div className="row">
                            <div className="col-12 col-md-12 flex items-center justify-center  ">
                                <div
                                    className="  rounded-lg p-3"
                                    style={{
                                        border: alertActive
                                            ? '1px solid #fff  '
                                            : '1px solid #c8d4df',
                                    }}
                                >
                                    <div>
                                        <input
                                            style={{
                                                border: alertActive
                                                    ? '1px solid #fff  '
                                                    : '1px solid #c8d4df',
                                            }}
                                            type="text"
                                            className="form-control"
                                            placeholder="Alert Text"
                                            size={50}
                                            value={alertVal}
                                            onChange={(e) => handleSearch(e)}
                                            maxLength={140}
                                        />
                                        {/* <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div> */}
                                    </div>
                                    <div className="flex items-center justify-between w-full">
                                        <div className="form-check form-switch">
                                            <input
                                                className={`form-check-input `}
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckChecked"
                                                name="active"
                                                checked={alertActive == true ? true : false}
                                                onChange={() => setAlertActive(!alertActive)}
                                            />
                                        </div>
                                        <div>
                                            <Button
                                                style={{
                                                    // background: alertActive ? ' #fff  ' : '#7aad37',
                                                    paddingLeft: '40px',
                                                    paddingRight: '40px',
                                                    // color: alertActive ? '#7aad37' : ' #fff',
                                                }}
                                                onClick={() => updateAlert()}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 flex items-center justify-center  ">
                                <div
                                    className="  rounded-lg p-3"
                                    style={{
                                        border: '1px solid #fff  ',
                                    }}
                                >
                                    <strong style={{ color: 'red' }}>
                                        PLEASE NOTE: WHEN YOU TURN THIS ALERT ON, IT WILL SHOW ON
                                        BOTH THE WEBSITE AND THE MOBILE APP. PLEASE CHECK THE TEXT
                                        BEFORE SAVING ALERT
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </ContentLayout>
        </div>
    );
};
