import { CorporateFare, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    Menu,
    //  User
} from '../type';
import { Button } from '@/components/Elements';
import Delete from '@/components/Icons/Delete';
import { handleDateFormat } from '@/utils/commonHelperFunc';

import { axios } from '@/lib/axios';

const ViewMenu = ({ menuData, closeDialog, selectedUserAction }: any) => {
    const [data, setData] = useState<Menu | null>(null);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        setData(menuData);
        getProductsbyMenu(menuData);
    }, [menuData]);

    const getProductsbyMenu = async (data: any) => {
        console.log(data);
        axios
            .get(
                `v1/admin/menus/get-menu-by-group?menu_group=${
                    data.menu_group ? data?.menu_group?.id : data.id
                }`
            )
            .then((res) => {
                setProducts(res.data.menu);
                console.log(res.data.menu);
            });
    };

    const deleteMenuProduct = async (id: any) => {
        axios
            .post('v1/admin/menus/update-menu', {
                id: id,
                menu_group: data.menu_group ? data?.menu_group?.id : data.id,
                active: false,
            })
            .then(() => {
                getProductsbyMenu(data);
            });
    };

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        Menu #{data?.id}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <div
                            className="info-header"
                            style={{ paddingTop: '5px', paddingBottom: '22px' }}
                        >
                            <div className="left-info">
                                <CorporateFare className="pColor" />
                                <h4>{data?.menu_group_name}</h4>
                            </div>
                        </div>
                        <ul
                            className="user-details-list menus-listn"
                            style={{ paddingBottom: '10px' }}
                        >
                            <li>
                                <strong>Status:</strong>{' '}
                                <span>{data?.status || 'Not Applicable'}</span>
                            </li>
                            <li>
                                <strong>Activation Date:</strong>{' '}
                                <span>
                                    {handleDateFormat(data?.activation_time) ||
                                        '(Available for Live Menu)'}
                                </span>
                            </li>
                            <li>
                                <strong>Location:</strong>{' '}
                                <span>{data?.location_name || '(Available for Live Menu)'}</span>
                            </li>
                            <li>
                                <strong>Expiration Date:</strong>{' '}
                                <span>
                                    {handleDateFormat(data?.expiration_time) ||
                                        '(Available for Live Menu)'}
                                </span>
                            </li>
                            <li>
                                <strong>Created:</strong>
                                <span>{handleDateFormat(data?.date_created)}</span>
                            </li>
                            <li>
                                <strong>Last Update:</strong>{' '}
                                <span>{handleDateFormat(data?.date_updated)}</span>
                            </li>
                        </ul>
                    </div>
                    <hr />
                    <div className="modal-body">
                        <div className="view-user-details">
                            <p className="box-cookie-tx">
                                <span>
                                    <strong>Assigned Products</strong>
                                </span>
                                {data?.menu_group ? (
                                    ''
                                ) : (
                                    <div
                                        className="dark-tx d-flex items-end asgn-button"
                                        onClick={() =>
                                            selectedUserAction({
                                                action: 'assign-products',
                                                data: data,
                                            })
                                        }
                                    >
                                        <AddCircleOutlineIcon /> Assign Product
                                    </div>
                                )}
                            </p>
                        </div>

                        <div className="modal-body">
                            <hr />
                            {products != '' && (
                                <div className="flex flex-col">
                                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                            <div className="overflow-hidden border-b border-gray-200">
                                                <table className="min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-50">
                                                        <tr className="noborder">
                                                            <th
                                                                key={1}
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                            >
                                                                Product Name
                                                            </th>
                                                            <th
                                                                key={1}
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                            >
                                                                Base Price
                                                            </th>
                                                            <th
                                                                key={1}
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                            >
                                                                Active
                                                            </th>
                                                            <th
                                                                key={1}
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                            ></th>
                                                            <th
                                                                key={1}
                                                                scope="col"
                                                                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                            ></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {products?.map((product) => (
                                                            <tr
                                                                key={product?.id}
                                                                className="noborder"
                                                            >
                                                                <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                    {product.product.product_name}
                                                                </td>
                                                                <td className="px-6 py-2 text-sm text-blue-700 font-medium whitespace-nowrap">
                                                                    <span className="!text-blue-700">
                                                                        $
                                                                        {product.product
                                                                            .product_base_price /
                                                                            100 || ' Not Set'}
                                                                    </span>
                                                                </td>
                                                                <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                    {product.active == true
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </td>
                                                                <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                    <span className="dark-tx !cursor-pointer">
                                                                        <EditOutlinedIcon
                                                                            onClick={() =>
                                                                                selectedUserAction({
                                                                                    action: 'edit-product',
                                                                                    data: {
                                                                                        ...data,
                                                                                        ...product,
                                                                                    },
                                                                                })
                                                                            }
                                                                        />
                                                                    </span>
                                                                </td>
                                                                <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                    <span
                                                                        className="dark-tx !cursor-pointer"
                                                                        onClick={() =>
                                                                            deleteMenuProduct(
                                                                                product.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <DeleteOutline />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {products == '' && (
                                <p className="pt-2">There are no any product assigned.</p>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    {data?.menu_group && (
                        <Button
                            onClick={() =>
                                selectedUserAction({
                                    action: 'edit',
                                    data: data,
                                })
                            }
                            startIcon={<EditOutlined />}
                            style={{ width: 'fit-content' }}
                        >
                            Edit information
                        </Button>
                    )}

                    <Button
                        startIcon={<Delete />}
                        style={{ width: 'fit-content' }}
                        variant="secondary"
                        onClick={() =>
                            selectedUserAction({
                                action: 'delete',
                                data: data,
                            })
                        }
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ViewMenu;
