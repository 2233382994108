// import { CorporateFare, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import * as z from 'zod';

// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { Menu, User } from '../type';
import { Form, InputField } from '@/components/Form';
import { Button, Spinner } from '@/components/Elements';
// import Delete from '@/components/Icons/Delete';
// import { handleDateFormat } from '@/utils/commonHelperFunc';

import { axios } from '@/lib/axios';

const schema = z.object({
    price_override: z.string(),
});


type LoginValues = {
    price_override: string;
    status: boolean;
};

const EditProduct = ({ menuData, action, closeDialog, selectedUserAction }:any) => {
    const intialMenuData = {
        id: menuData?.id,
        menu_name: menuData?.menu_group_name || '',
        price_override: menuData?.price_override || '',
        active: menuData?.active,
        product_name: menuData?.product.product_name || '',
    };
    const [data, setData] = useState(intialMenuData);
    const [showModal, setShowModal] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;
        setData({ ...data, [name]: checked });
    };

    const updateProduct = async (data:any) => {
        console.log(data);
        const updateData = {
            id: data.id,
            price_override: data.price_override,
            active: data.active,
        };
        const { success, message }:any = await axios.post(`v1/admin/menus/update-menu`, updateData);
        return { success, message };
    };

    const handleSubmit = async () => {
        updateProduct(data)
            .then(() => closeDialog())
            .catch();
    };
    const SaveModal = ()=>{
        return (<>
            {showModal ? (<>
            <div className="modal-dialog" style={{width:"80px"}}>
            <div className="modal-content">
                <div className="modal-body">
                <h5 className="modal-title" id="viewModalLabel" style={{"textAlign":"center"}}>
                        Are You Sure
                    </h5>
                    <div className="view-user-details" style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}>
                        <Button
                                            type="submit"
                                            startIcon={<CheckCircleOutlineIcon />}
                                            style={{ width: 'fit-content' }}
                                            onClick={() => handleSubmit()}
                                        >
                                            Save Changes
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() =>setShowModal(false)} //closeDialog()
                                        >
                                            Cancel
                                        </Button>
                    </div>
                </div>
            </div>
        </div>
        </>) : null}
        </>
        )
    }

    return (<>
        {showModal === false ? (
            <>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="viewModalLabel">
                            {data.menu_name} / Edit Product
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => closeDialog()}
                        ></button>
                    </div>
                    <div style={{ marginTop: '10px' }} className="modal-header">
                        <strong>{data?.product_name}</strong>
                        <div>
                            <strong>
                                Base Price: <span>${data?.product_base_price}</span>
                            </strong>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="view-user-details">
                            <Form<LoginValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
                                {({ register, formState }) => (
                                    <>
                                        <div className="flex justify-between items-center">
                                            <div className="">
                                                <label
                                                    htmlFor="price_override"
                                                    className="control-label"
                                                >
                                                    Price Override
                                                </label>
                                                <div className="form-group">
                                                    <InputField
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        name="price_override"
                                                        placeholder="$80"
                                                        value={data.price_override}
                                                        error={formState.errors['price_override']}
                                                        registration={register('price_override')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="pt-4">
                                                <div className="flex gap-3">
                                                    <label htmlFor="status">Active:</label>
                                                    {data?.active ? 'Yes' : 'No'}
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="flexSwitchCheckChecked"
                                                            name="active"
                                                            defaultChecked={data?.active || false}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="modal-footer"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '1rem',
                                            }}
                                        >
                                            <Button
                                                type="submit"
                                                startIcon={<CheckCircleOutlineIcon />}
                                                style={{ width: 'fit-content' }}
                                                onClick={() => setShowModal(true) }
                                            >
                                                Save Changes
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                style={{ width: 'fit-content' }}
                                                onClick={() =>closeDialog()}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            </>
            ) : null
            }
            {SaveModal()}
            </>
        );
};

export default EditProduct;
