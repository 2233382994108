import { axios } from '@/lib/axios';

export const getOrder = async (id: number) => {
    const { data } = await axios.get(`v1/admin/orders/get-order-details?id=${id}`);
    return data;
    // return {
    //     id: 295,
    //     order_creation_date: '2022-10-21 12:31:07 am',
    //     order_total: 11.12,
    //     order_user_id: 22,
    //     order_type_id: 11,
    //     order_cart_group_id: 384,
    //     order_subtotal: 15,
    //     order_tax: 1.12,
    //     order_delivery_fee: 0,
    //     order_tip: null,
    //     order_instructions: null,
    //     order_promised_time: '2022-10-20T18:45:00.000Z',
    //     coupon_code_id: null,
    //     order_coupon_code_discount: 0,
    //     gift_card_id: 1,
    //     order_gift_card_amount: -5,
    //     location_id: 2,
    //     location_name: 'S. Grand',
    //     location_address_1: '3107 S Grand Blvd',
    //     location_address_2: '',
    //     location_city: 'ST. LOUIS',
    //     location_state: 'MO',
    //     location_zip: '63118',
    //     order_status: 'new',
    //     coupon_code: null,
    //     gift_card_code: '8AFSatQ1TtN89MidvP8MhLaAqVQ4JtAu',
    //     is_open: false,
    //     items: [
    //         {
    //             id: 755,
    //             cart_group_id: 384,
    //             menu_id: 3,
    //             menu_item_modifiers: null,
    //             menu_item_quantity: 5,
    //             menu_item_price: 3,
    //             menu_item_instructions: null,
    //             product_name: 'Red Velvet',
    //             product_description:
    //                 'Who said cookies aren’t fancy? Class up your dessert with the most prestigious cookie on our menu.',
    //             product_image:
    //                 'https://res.cloudinary.com/oper-hotbox-cookies/image/upload/v1657015643/cookies/IMG_2016_ztzsoj.png',
    //         },
    //     ],
    //     order_type: 'Pickup',
    //     order_status_history: [
    //         {
    //             id: 350,
    //             timestamp: '2022-10-21 12:31:07 am',
    //             order_status: 'new',
    //             first_name: 'Nitin',
    //             last_name: 'jha',
    //         },
    //     ],
    // };
};
