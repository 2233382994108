// import { AddCircleOutline } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import { getContactInquiry } from '../api/CRUD/getContactInquiry';
// import { getCorporateInquiry } from '../api/CRUD/getCorporateInquiry';
// import { getCustomInquiry } from '../api/CRUD/getCustomInquiry';
// import { getDonationInquiry } from '../api/CRUD/getDonationInquiry';
import ViewInquiry from '../components/view';
import { dataFields, dataFieldsCareers, HeadCells, HeadCellsCareers } from '../constant';
import { Inquiry } from '../type';
import { getContactInquiry } from '../api/CRUD/getContactInquiry';
import { getCustomInquiry } from '../api/CRUD/getCustomInquiry';
import { useNotificationStore } from '@/stores/notifications';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
// import TableWithSort from '@/components/Elements/Table/TableWithSort';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { Confirm } from '@/components/Elements/Dialog/Confirm';
import { Button, Dialog, Spinner } from '@/components/Elements';
//import { HeadCells } from '@/features/users/constant';

import '../styles/styles.css';
import { axios } from '@/lib/axios';

export const Inquiries = () => {
    const { close, open, isOpen } = useDisclosure();
    const { addNotification } = useNotificationStore();

    const [searchVal, setSearchVal] = useState<string>('');
    const [checkBoxValue, setCheckBoxValue] = useState<string>('All');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [inquiry, setInquiry] = useState<Inquiry[]>([]);
    const [liveinquiry, setLiveInquiry] = useState<Inquiry[]>([]);
    const [contactinquiry, setContactInquiry] = useState<Inquiry[]>([]);
    const [corporateinquiry, setCorporateInquiry] = useState<Inquiry[]>([]);
    const [custominquiry, setCustomInquiry] = useState<Inquiry[]>([]);
    const [donationinquiry, setDonationInquiry] = useState<Inquiry[]>([]);

    const [viewInquiry, setviewInquiry] = useState<Inquiry | null>(null);
    const [locationFilter, setLocationFilter] = useState();
    const [confirmMsg, setConfirmMsg] = useState<User | null>(null);
    const [value, setValue] = useState(0);

    const handleChange = (newValue) => {
        const a = parseInt(newValue.target.id);
        setValue(a);
    };

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    async function getInquiry() {
        setIsLoading(true);
        const resContactMod = [];
        let resCorporateMod = [];
        const resCustomMod = [];
        let resDonationMod = [];
        const resContact = await getContactInquiry();
        // const resContact = [
        //     {
        //         id: 1,
        //         contact_full_name: 'John deo',
        //         contact_phone: '7845122356',
        //         contact_email: 'john@test.com',
        //         contact_comment: 'Hey !! ',
        //         inquiry_read: 0,
        //         inquiry_active: 1,
        //         created_at: '2023-01-11T10:24:14.000Z',
        //         updated_at: '2023-01-11T10:24:14.000Z',
        //     },
        //     {
        //         id: 2,
        //         contact_full_name: 'John deo',
        //         contact_phone: '7845122356',
        //         contact_email: 'john@test.com',
        //         contact_comment: 'Hey !! ',
        //         inquiry_read: 0,
        //         inquiry_active: 1,
        //         created_at: '2023-01-11T10:24:14.000Z',
        //         updated_at: '2023-01-11T10:24:14.000Z',
        //     },
        // ];
        // resContactMod = resContact.map((item) => {
        //     const inquiry = {};
        //     inquiry.id = item.id;
        //     inquiry.contact_full_name = item.contact_full_name;
        //     inquiry.req_type = 'Contact Inquiries';
        //     inquiry.customer = item.contact_full_name;
        //     inquiry.contact_phone = item.contact_phone;
        //     inquiry.contact_email = item.contact_email;
        //     inquiry.contact_comment = item.contact_comment;
        //     inquiry.inquiry_read = item.inquiry_read;
        //     inquiry.inquiry_active = item.inquiry_active;
        //     inquiry.created_at = item.created_at;
        //     inquiry.updated_at = item.updated_at;

        //     return inquiry;
        // });
        // const resCorporate = await getCorporateInquiry();
        const resCorporate = [
            {
                id: 1,
                corporate_request_user_id: 1,
                corporate_request_user_position: 'Dev',
                corporate_request_corporate_name: 'App',
                corporate_request_phone: '7889455612',
                corporate_request_email: 'rnk@gmail.com',
                corporate_request_site: 'web.test.com',
                corporate_request_address_1: 'abc address 12 A',
                corporate_request_address2: 'abc address 12 A',
                corporate_request_city: 'jack',
                corporate_request_state: 'racasm',
                corporate_request_zip: '123212',
                inquiry_read: 0,
                inquiry_status: 'new',
                created_at: '2023-01-11T10:23:11.000Z',
                update_at: '2023-01-11T10:23:11.000Z',
            },
            {
                id: 2,
                corporate_request_user_id: 1,
                corporate_request_user_position: 'Dev',
                corporate_request_corporate_name: 'App',
                corporate_request_phone: '7889455612',
                corporate_request_email: 'rnk@gmail.com',
                corporate_request_site: 'web.test.com',
                corporate_request_address_1: 'abc address 12 A',
                corporate_request_address2: 'abc address 12 A',
                corporate_request_city: 'jack',
                corporate_request_state: 'racasm',
                corporate_request_zip: '123212',
                inquiry_read: 0,
                inquiry_status: 'new',
                created_at: '2023-01-11T10:23:11.000Z',
                update_at: '2023-01-11T10:23:11.000Z',
            },
        ];
        resCorporateMod = resCorporate.map((item) => {
            const inquiry = {};
            inquiry.id = item.id;
            inquiry.corporate_request_user_id = item.corporate_request_user_id;
            inquiry.req_type = 'Corporate Account Request';
            inquiry.customer = item.corporate_request_corporate_name;
            inquiry.corporate_request_user_position = item.corporate_request_user_position;
            inquiry.corporate_request_corporate_name = item.corporate_request_corporate_name;
            inquiry.corporate_request_phone = item.corporate_request_phone;
            inquiry.corporate_request_email = item.corporate_request_email;
            inquiry.corporate_request_site = item.corporate_request_site;
            inquiry.corporate_request_address_1 = item.corporate_request_address_1;
            inquiry.corporate_request_address2 = item.corporate_request_address2;
            inquiry.corporate_request_city = item.corporate_request_city;
            inquiry.corporate_request_state = item.corporate_request_state;
            inquiry.corporate_request_zip = item.corporate_request_zip;
            inquiry.inquiry_read = item.inquiry_read;
            inquiry.inquiry_status = item.inquiry_status;
            inquiry.created_at = item.created_at;
            inquiry.update_at = item.update_at;

            return inquiry;
        });
        const resCustom = await getCustomInquiry();
        // const resCustom = [
        //     {
        //         id: 1,
        //         custom_first_name: 'jhon',
        //         custom_last_name: 'deo',
        //         custom_company: 'app',
        //         custom_phone: '7845122356',
        //         custom_email: 'app@gmail.com',
        //         custom_request_type: 'other',
        //         custom_order_type: 'pickup',
        //         custom_cookie_quantity: '1000+',
        //         custom_special_instructions: 'test',
        //         inquiry_read: 0,
        //         inquiry_active: 1,
        //         created_at: '2023-01-11T10:17:43.000Z',
        //         update_at: '2023-01-11T10:17:43.000Z',
        //     },
        //     {
        //         id: 2,
        //         custom_first_name: 'jhon',
        //         custom_last_name: 'deo',
        //         custom_company: 'app',
        //         custom_phone: '7845122356',
        //         custom_email: 'app@gmail.com',
        //         custom_request_type: 'other',
        //         custom_order_type: 'pickup',
        //         custom_cookie_quantity: '1000+',
        //         custom_special_instructions: 'test',
        //         inquiry_read: 0,
        //         inquiry_active: 1,
        //         created_at: '2023-01-11T10:17:43.000Z',
        //         update_at: '2023-01-11T10:17:43.000Z',
        //     },
        // ];
        // resCustomMod = resCustom.map((item) => {
        //     const inquiry = {};
        //     inquiry.id = item.id;
        //     inquiry.custom_first_name = item.custom_first_name;
        //     inquiry.req_type = 'Custom Order Request';
        //     inquiry.customer = item.custom_first_name + ' ' + item.custom_last_name;
        //     inquiry.custom_last_name = item.custom_last_name;
        //     inquiry.custom_company = item.custom_company;
        //     inquiry.custom_phone = item.custom_phone;
        //     inquiry.custom_email = item.custom_email;
        //     inquiry.custom_request_type = item.custom_request_type;
        //     inquiry.custom_order_type = item.custom_order_type;
        //     inquiry.custom_cookie_quantity = item.custom_cookie_quantity;
        //     inquiry.custom_special_instructions = item.custom_special_instructions;
        //     inquiry.inquiry_read = item.inquiry_read;
        //     inquiry.inquiry_active = item.inquiry_active;
        //     inquiry.created_at = item.created_at;
        //     inquiry.update_at = item.update_at;

        //     return inquiry;
        // });
        // const resDonation = await getDonationInquiry();
        const resDonation = [
            {
                id: 1,
                donation_first_name: 'Rnk',
                donation_last_name: 'Shm',
                donation_phone: '7845122356',
                donation_email: 'test@gmail.com',
                donation_company: 'app',
                donation_company_site: 'app.com',
                donation_city: 'other',
                donation_is_nonprofit: 1,
                donation_event_name: 'test',
                donation_event_site: 'test',
                donation_event_description: 'ste',
                donation_event_datetime: '2022-10-13T17:20:00.000Z',
                donation_event_location_name: 'sdfsd',
                donation_event_attendance: 10,
                donation_event_location_address: 'sdfd',
                donation_is_previous_done: 1,
                donation_is_hbexclusivedessert: 0,
                donation_is_hbexclusivecookie: 0,
                donation_desired: 'other',
                donation_comments: 'fdgfdg',
                inquiry_read: 0,
                inquiry_active: 1,
                created_at: '2023-01-11T10:22:15.000Z',
                updated_at: '2023-01-11T10:22:15.000Z',
            },
            {
                id: 2,
                donation_first_name: 'Rnk',
                donation_last_name: 'Shm',
                donation_phone: '7845122356',
                donation_email: 'test@gmail.com',
                donation_company: 'app',
                donation_company_site: 'app.com',
                donation_city: 'other',
                donation_is_nonprofit: 1,
                donation_event_name: 'test',
                donation_event_site: 'test',
                donation_event_description: 'ste',
                donation_event_datetime: '2022-10-13T17:20:00.000Z',
                donation_event_location_name: 'sdfsd',
                donation_event_attendance: 10,
                donation_event_location_address: 'sdfd',
                donation_is_previous_done: 1,
                donation_is_hbexclusivedessert: 0,
                donation_is_hbexclusivecookie: 0,
                donation_desired: 'other',
                donation_comments: 'fdgfdg',
                inquiry_read: 0,
                inquiry_active: 1,
                created_at: '2023-01-11T10:22:15.000Z',
                updated_at: '2023-01-11T10:22:15.000Z',
            },
        ];
        resDonationMod = resDonation.map((item) => {
            const inquiry = {};
            inquiry.id = item.id;
            inquiry.donation_first_name = item.donation_first_name;
            inquiry.req_type = 'Donation Request';
            inquiry.customer = item.donation_first_name + ' ' + item.donation_last_name;
            inquiry.donation_last_name = item.donation_last_name;
            inquiry.donation_phone = item.donation_phone;
            inquiry.donation_email = item.donation_email;
            inquiry.donation_company = item.donation_company;
            inquiry.donation_company_site = item.donation_company_site;
            inquiry.donation_city = item.donation_city;
            inquiry.donation_is_nonprofit = item.donation_is_nonprofit;
            inquiry.donation_event_name = item.donation_event_name;
            inquiry.donation_event_site = item.donation_event_site;
            inquiry.donation_event_description = item.donation_event_description;
            inquiry.donation_event_datetime = item.donation_event_datetime;
            inquiry.donation_event_location_name = item.donation_event_location_name;
            inquiry.donation_event_attendance = item.donation_event_attendance;
            inquiry.donation_event_location_address = item.donation_event_location_address;
            inquiry.donation_is_previous_done = item.donation_is_previous_done;
            inquiry.donation_is_hbexclusivedessert = item.donation_is_hbexclusivedessert;
            inquiry.donation_is_hbexclusivecookie = item.donation_is_hbexclusivecookie;
            inquiry.donation_desired = item.donation_desired;
            inquiry.donation_comments = item.donation_comments;
            inquiry.inquiry_read = item.inquiry_read;
            inquiry.inquiry_active = item.inquiry_active;
            inquiry.created_at = item.created_at;
            inquiry.updated_at = item.updated_at;

            return inquiry;
        });
        setContactInquiry(resContact);
        setCorporateInquiry(resCorporateMod);
        setCustomInquiry(resCustom);
        setDonationInquiry(resDonationMod);
        setIsLoading(false);
    }
    useEffect(() => {
        getInquiry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close]);

    if (isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    const filteredCustomInquiry = custominquiry?.filter((inquiry: Inquiry) => {
        if (searchVal || checkBoxValue) {
            if (checkBoxValue == 'All') {
                if (searchVal) {
                    return (
                        inquiry?.applicant_first_name
                            ?.toLowerCase()
                            ?.includes(searchVal.toLowerCase()) ||
                        inquiry?.applicant_last_name
                            ?.toLowerCase()
                            ?.includes(searchVal.toLowerCase()) ||
                        inquiry?.applicant_position
                            ?.toLowerCase()
                            ?.includes(searchVal.toLowerCase()) ||
                        inquiry?.applicant_employment_desired
                            ?.toLowerCase()
                            ?.includes(searchVal.toLowerCase()) ||
                        inquiry?.applicant_email?.toLowerCase()?.includes(searchVal.toLowerCase())
                    );
                } else {
                    return inquiry;
                }
            } else if (checkBoxValue == 'New') {
                if (inquiry.inquiry_read == false) {
                    if (searchVal) {
                        return (
                            inquiry?.applicant_first_name
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            inquiry?.applicant_last_name
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            inquiry?.applicant_position
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            inquiry?.applicant_employment_desired
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            inquiry?.applicant_email
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase())
                        );
                    } else {
                        return inquiry;
                    }
                }
            } else if (checkBoxValue == 'Viewed') {
                if (inquiry.inquiry_read == true) {
                    if (searchVal) {
                        return (
                            inquiry?.applicant_first_name
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            inquiry?.applicant_last_name
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            inquiry?.applicant_position
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            inquiry?.applicant_employment_desired
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            inquiry?.applicant_email
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase())
                        );
                    } else {
                        return inquiry;
                    }
                }
            }
            // else if (checkBoxValue == 'Answered') {
            //     if (inquiry.inquiry_read == false) {
            //         return inquiry;
            //     }
            // }
            else if (checkBoxValue == 'Deleted') {
                if (inquiry.inquiry_active == false) {
                    if (searchVal) {
                        return (
                            inquiry?.applicant_first_name
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            inquiry?.applicant_last_name
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            inquiry?.applicant_position
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            inquiry?.applicant_employment_desired
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            inquiry?.applicant_email
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase())
                        );
                    } else {
                        return inquiry;
                    }
                }
            }
        } else {
            return inquiry;
        }
    });

    const filteredCorporateInquiry = corporateinquiry?.filter((liveinquiry: Inquiry) => {
        if (searchVal) {
            if (searchVal) {
                return liveinquiry?.customer?.toLowerCase()?.includes(searchVal.toLowerCase());
            }
        } else {
            return liveinquiry;
        }
    });

    const filteredDonationInquiry = donationinquiry?.filter((liveinquiry: Inquiry) => {
        if (searchVal) {
            if (searchVal) {
                return liveinquiry?.customer?.toLowerCase()?.includes(searchVal.toLowerCase());
            }
        } else {
            return liveinquiry;
        }
    });

    const filteredContactInquiry = contactinquiry?.filter((liveinquiry: any) => {
        if (searchVal || checkBoxValue) {
            if (checkBoxValue == 'All') {
                if (searchVal) {
                    return (
                        liveinquiry?.contact_full_name
                            ?.toLowerCase()
                            ?.includes(searchVal.toLowerCase()) ||
                        liveinquiry?.contact_email
                            ?.toLowerCase()
                            ?.includes(searchVal.toLowerCase()) ||
                        liveinquiry?.contact_comment
                            ?.toLowerCase()
                            ?.includes(searchVal.toLowerCase())
                    );
                } else {
                    return liveinquiry;
                }
            } else if (checkBoxValue == 'New') {
                if (liveinquiry.inquiry_read == false) {
                    if (searchVal) {
                        return (
                            liveinquiry?.contact_full_name
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            liveinquiry?.contact_email
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            liveinquiry?.contact_comment
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase())
                        );
                    } else {
                        return liveinquiry;
                    }
                }
            } else if (checkBoxValue == 'Viewed') {
                if (liveinquiry.inquiry_read == true) {
                    if (searchVal) {
                        return (
                            liveinquiry?.contact_full_name
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            liveinquiry?.contact_email
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            liveinquiry?.contact_comment
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase())
                        );
                    } else {
                        return liveinquiry;
                    }
                }
            }
            // else if (checkBoxValue == 'Answered') {
            //     if (inquiry.inquiry_read == false) {
            //         return inquiry;
            //     }
            // }
            else if (checkBoxValue == 'Deleted') {
                if (liveinquiry.inquiry_active == false) {
                    if (searchVal) {
                        return (
                            liveinquiry?.contact_full_name
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            liveinquiry?.contact_email
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase()) ||
                            liveinquiry?.contact_comment
                                ?.toLowerCase()
                                ?.includes(searchVal.toLowerCase())
                        );
                    } else {
                        return liveinquiry;
                    }
                }
            }
        } else {
            return liveinquiry;
        }
    });

    const deleteCustomInquiry = async (id: any, isTrue?: any) => {
        await axios.post('v1/admin/inquiries/update-careers-inquiry', {
            id: id,
            inquiry_active: isTrue,
        });
    };
    const isReadCustomInquiry = async (id: any, isTrue?: any) => {
        await axios.post('v1/admin/inquiries/update-careers-inquiry', {
            id: id,
            inquiry_read: isTrue,
        });
    };
    const deleteCorporateInquiry = async (id) => {
        await axios.post('v1/update-corporate-request-inquiries', {
            id: id,
            inquiry_active: false,
        });
    };
    const deleteDonationInquiry = async (id) => {
        await axios.post('v1/update-donation-inquiries', {
            id: id,
            inquiry_active: false,
        });
    };
    const deleteContactInquiry = async (id: any, isTrue?: any) => {
        await axios.post('v1/admin/inquiries/update-contact-us-inquiry', {
            id: id,
            inquiry_active: isTrue,
        });
    };
    const isReadContactInquiry = async (id: any, isTrue?: any) => {
        await axios.post('v1/admin/inquiries/update-contact-us-inquiry', {
            id: id,
            inquiry_read: isTrue,
        });
    };

    const handleDelete = async (inquiryData: Inquiry) => {
        if (inquiryData.req_type == 'Custom Order Request') {
            await deleteCustomInquiry(inquiryData.id).then(() => {
                addNotification({
                    type: 'success',
                    title: 'Inquiry Deleted',
                });
            });
            getInquiry();
            setConfirmMsg(null);
            closeDialog();
        } else if (inquiryData.req_type == 'Corporate Account Request') {
            await deleteCorporateInquiry(inquiryData.id).then(() => {
                addNotification({
                    type: 'success',
                    title: 'Inquiry Deleted',
                });
            });
            getInquiry();
            setConfirmMsg(null);
            closeDialog();
        } else if (inquiryData.req_type == 'Donation Request') {
            await deleteDonationInquiry(inquiryData.id).then(() => {
                addNotification({
                    type: 'success',
                    title: 'Inquiry Deleted',
                });
            });
            getInquiry();
            setConfirmMsg(null);
            closeDialog();
        } else if (inquiryData.req_type == 'Contact Inquiries') {
            await deleteContactInquiry(inquiryData.id).then(() => {
                addNotification({
                    type: 'success',
                    title: 'Inquiry Deleted',
                });
            });
            getInquiry();
            setConfirmMsg(null);
            closeDialog();
        }
    };

    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case 'view':
                setviewInquiry(data);
                value == 0
                    ? isReadCustomInquiry(data.id, true)
                    : isReadContactInquiry(data.id, true);
                open();
                break;
            case 'delete':
                setConfirmMsg(data);
                break;
            default:
                return null;
        }
    };

    const closeDialog = () => {
        setviewInquiry(null);
        close();
    };

    const LocationOptions = new Set(liveinquiry?.map((item) => item.location_name));

    function TabPanel(props: any) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 0 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: any) {
        return {
            id: index,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <ContentLayout
            title=""
            topNavBarData={{
                num:
                    value == 0
                        ? custominquiry.length
                        : value == 1
                        ? corporateinquiry.length
                        : value == 2
                        ? donationinquiry.length
                        : contactinquiry?.length,
                title: 'Inquiries',
            }}
        >
            {confirmMsg ? (
                <Confirm
                    btnTitle={'Delete'}
                    onClose={() => setConfirmMsg(null)}
                    onSuccess={() => handleDelete(confirmMsg)}
                />
            ) : null}
            <div className="table-header">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-9">
                            <div className="flex">
                                <div className="input-group search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        value={searchVal}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>

                                <div className="checkboxes flex items-center justify-center ml-4 gap-4 pt-2">
                                    <div className="all-bx">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="all"
                                                name="all"
                                                value="All"
                                                checked={checkBoxValue === 'All'}
                                                onChange={() => {
                                                    setCheckBoxValue('All');
                                                }}
                                            />
                                            <span>All</span>
                                        </label>
                                    </div>
                                    <div className="all-bx">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="new"
                                                name="new"
                                                value="New"
                                                checked={checkBoxValue === 'New'}
                                                onChange={() => {
                                                    setCheckBoxValue('New');
                                                }}
                                            />
                                            <span>New</span>
                                        </label>
                                    </div>
                                    <div className="all-bx">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="Viewed"
                                                name="Viewed"
                                                value="Viewed"
                                                checked={checkBoxValue === 'Viewed'}
                                                onChange={() => {
                                                    setCheckBoxValue('Viewed');
                                                }}
                                            />
                                            <span>Viewed</span>
                                        </label>
                                    </div>
                                    {/* <div className="all-bx">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="Answered"
                                                name="Answered"
                                                value="Answered"
                                                checked={checkBoxValue === 'Answered'}
                                                onChange={() => {
                                                    setCheckBoxValue('Answered');
                                                }}
                                            />
                                            <span>Answered</span>
                                        </label>
                                    </div> */}
                                    <div className="all-bx">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="Deleted"
                                                name="Deleted"
                                                value="Deleted"
                                                checked={checkBoxValue === 'Deleted'}
                                                onChange={() => {
                                                    setCheckBoxValue('Deleted');
                                                }}
                                            />
                                            <span>Deleted</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 0 }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className="prTabs inquiry-dash-tabs"
                        style={{ paddingTop: '10px' }}
                    >
                        <Tab
                            label="Career Inquiries"
                            className="left-inquiry"
                            value={0}
                            {...a11yProps(0)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '6px 0px 0 0',
                                width: '50%',
                                maxWidth: '50%',
                            }}
                        />
                        {/* <Tab
                            label="Corporate Account Request"
                            className="right-inquiry"
                            value={1}
                            {...a11yProps(1)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '0px 6px 0 0',
                                width: '25%',
                                maxWidth: '25%',
                            }}
                        /> */}
                        {/* <Tab
                            label="Donation Request"
                            className="right-inquiry"
                            value={2}
                            {...a11yProps(2)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '0px 6px 0 0',
                                width: '25%',
                                maxWidth: '25%',
                            }}
                        /> */}
                        <Tab
                            label="General Inquiries"
                            className="right-inquiry"
                            value={3}
                            {...a11yProps(3)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '0px 6px 0 0',
                                width: '50%',
                                maxWidth: '50%',
                            }}
                        />
                    </Tabs>
                </Box>

                <TabPanel className="inquiry-tab-panel" value={value} index={0}>
                    <CustomTable
                        tableData={filteredCustomInquiry}
                        headCells={HeadCellsCareers}
                        dataFields={dataFieldsCareers}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />
                </TabPanel>
                <TabPanel className="inquiry-tab-panel" value={value} index={1}>
                    <CustomTable
                        tableData={filteredCorporateInquiry}
                        headCells={HeadCells}
                        dataFields={dataFields}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />
                </TabPanel>
                <TabPanel className="inquiry-tab-panel" value={value} index={2}>
                    <CustomTable
                        tableData={filteredDonationInquiry}
                        headCells={HeadCells}
                        dataFields={dataFields}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />
                </TabPanel>
                <TabPanel className="inquiry-tab-panel" value={value} index={3}>
                    <CustomTable
                        tableData={filteredContactInquiry}
                        headCells={HeadCells}
                        dataFields={dataFields}
                        selectedUserAction={selectedUserAction}
                        // rowOptions={true}
                        // optionMenu={['delete']}
                    />
                </TabPanel>
            </Box>

            <Dialog isOpen={isOpen} onClose={closeDialog} paperWidth={'55%'}>
                <div className="modals-contents">
                    {viewInquiry && (
                        <ViewInquiry
                            inquiryData={viewInquiry}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
