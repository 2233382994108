// eslint-disable-next-line no-restricted-imports

//images
import arrowDown from '../../assets/arrowdown.png';
import avatar from '../../assets/logo.png';
import { useAuth } from '@/lib/auth';
type TopNavBarDataType = {
    topNavBarData: {
        num: number;
        title: string;
    };
};
const TopNavBar = ({ topNavBarData }: TopNavBarDataType) => {
    const { logout, user } = useAuth();

    return (
        <div className="header mb-4">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 align-items-baseline">
                    <li className="breadcrumb-item" style={{ fontSize: '22px' }}>
                        <strong style={{ textTransform: 'capitalize' }}>
                            {topNavBarData.title}
                        </strong>
                    </li>
                    <li
                        className="breadcrumb-item active"
                        aria-current="page"
                        style={{ fontSize: '18px' }}
                    >
                        {topNavBarData.num} {topNavBarData.title}
                    </li>
                </ol>
            </nav>
            <div className="dropdown">
                <a
                    href="#"
                    className="d-flex align-items-center text-decoration-none dropdown-toggle"
                    id="dropdownUser1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <img
                        src={avatar}
                        alt="hugenerd"
                        width="30"
                        height="30"
                        className="rounded-circle"
                    />
                    <span className="d-sm-inline-flex align-items-center mx-1">
                        {user?.data?.first_name} {user?.data?.last_name}
                        <img style={{ marginLeft: '5px' }} src={arrowDown}></img>
                    </span>
                </a>
                <ul
                    className="dropdown-menu dropdown-menu-dark text-small shadow"
                    aria-labelledby="dropdownUser1"
                >
                    {/*<li>
                        <a className="dropdown-item" href="#">
                            New project...
                        </a>
                    </li>
                    <li>
                        <a className="dropdown-item" href="#">
                            Settings
                        </a>
                    </li>
                    <li>
                        <a className="dropdown-item" href="#">
                            Profile
                        </a>
                    </li>*/}
                    {/*<li>
                        <hr className="dropdown-divider" />
                    </li>*/}
                    <li onClick={() => logout()}>
                        <a className="dropdown-item" href="#">
                            Sign out
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default TopNavBar;
