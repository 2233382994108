import { AddCircleOutline } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { getAllDelivery, useDelivery } from '../api/CRUD/getAllDelivery';
import CreateDelivery from '../components/create';
import EditDelivery from '../components/edit';
import ViewDelivery from '../components/view';
import { BodyCells, dataFields, HeadCells } from '../constant';
import { Delivery } from '../type';
import { useNotificationStore } from '@/stores/notifications';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
import TableWithSort from '@/components/Elements/Table/TableWithSort';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { Confirm } from '@/components/Elements/Dialog/Confirm';
import { Button, Dialog, Spinner } from '@/components/Elements';

import '../styles/styles.css';
import { axios } from '@/lib/axios';

export const Deliverys = () => {
    const { close, open, isOpen } = useDisclosure();
    const { addNotification } = useNotificationStore();
    const deliveryQuery = useDelivery();
    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [delivery, setDelivery] = useState<Delivery[]>([]);
    const [createDelivery, setCreateDelivery] = useState<boolean>(false);
    const [editDelivery, setEditDelivery] = useState<Delivery | null>(null);
    const [viewDelivery, setviewDelivery] = useState<Delivery | null>(null);
    const [locationFilter, setLocationFilter] = useState();
    const [confirmMsg, setConfirmMsg] = useState<User | null>(null);

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    async function getDelivery() {
        setIsLoading(true);
        const res = await getAllDelivery();

        setDelivery(res);
        setIsLoading(false);
    }
    useEffect(() => {
        getDelivery();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close]);
    useEffect(() => {
        //getNoOfCorps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryQuery.data, deliveryQuery.isLoading, close]);

    if (isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!delivery) return null;

    const filteredEmployees = delivery?.filter((delivery: Delivery) => {
        if (searchVal !== '') {
            const firstName = delivery?.first_name;
            const lastName = delivery?.last_name;
            const locationName = delivery?.location_name;
            const orderStatus = delivery?.order_status;
            const orderId = delivery?.id;
            const orderTotal = delivery?.order_total;
            if (
                (typeof firstName === 'string' &&
                    firstName.toLowerCase().includes(searchVal.toLowerCase())) ||
                (typeof lastName === 'string' &&
                    lastName.toLowerCase().includes(searchVal.toLowerCase())) ||
                (typeof locationName === 'string' &&
                    locationName.toLowerCase().includes(searchVal.toLowerCase())) ||
                (typeof orderStatus === 'string' &&
                    orderStatus.toLowerCase().includes(searchVal.toLowerCase())) ||
                (typeof orderId === 'number' &&
                    orderId.toString().includes(searchVal.toString())) ||
                (typeof orderTotal === 'number' &&
                    orderTotal.toString().includes(searchVal.toString()))
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    });

    const deleteDelivery = async (id: any, location_id: any) => {
        const updateData = {
            id: id,
            location_id: location_id,
            status: false,
        };

        const { success, message }: any = await axios.post(``, updateData);

        return { success, message };
    };

    const handleDelete = async (deliveryData: Delivery) => {
        await deleteDelivery(deliveryData.id, deliveryData.location_id).then(() => {
            addNotification({
                type: 'success',
                title: 'Record Deleted',
            });
        });

        getDelivery();
        setConfirmMsg(null);
    };

    const selectedUserAction = ({ action, data }: any) => {
        switch (action) {
            case 'view':
                setEditDelivery(null);
                setviewDelivery(data);
                setCreateDelivery(false);
                open();
                break;
            case 'edit':
                setviewDelivery(null);
                setEditDelivery(data);
                setCreateDelivery(false);
                open();
                break;
            case 'add-user':
                setviewDelivery(null);
                setEditDelivery(null);
                setCreateDelivery(false);
                open();
                break;
            case 'add-invoice':
                setviewDelivery(null);
                setEditDelivery(null);
                setCreateDelivery(false);
                open();
                break;
            case 'delete':
                setConfirmMsg(data);
                break;
            default:
                return null;
        }
    };

    const closeDialog = () => {
        setviewDelivery(null);
        setEditDelivery(null);
        setCreateDelivery(false);
        close();
    };

    return (
        <ContentLayout title="" topNavBarData={{ num: delivery.length, title: 'Delivery' }}>
            {confirmMsg ? (
                <Confirm
                    btnTitle={'Delete'}
                    onClose={() => setConfirmMsg(null)}
                    onSuccess={() => handleDelete(confirmMsg)}
                />
            ) : null}

            <div className="table-header">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="flex pos-devces">
                                <div className="input-group search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        value={searchVal}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <CustomTable
                tableData={filteredEmployees}
                headCells={HeadCells}
                dataFields={dataFields}
                bodyCells={BodyCells}
                selectedUserAction={selectedUserAction}
                rowOptions={true}
                optionMenu={[]}
            />
            <Dialog isOpen={isOpen} onClose={closeDialog} paperWidth={'70%'}>
                <div className="modals-contents">
                    {createDelivery && (
                        <CreateDelivery deliveryQuery={deliveryQuery} closeDialog={closeDialog} />
                    )}
                    {viewDelivery && (
                        <ViewDelivery
                            deliveryQuery={deliveryQuery}
                            deliveryData={viewDelivery}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {editDelivery && (
                        <EditDelivery
                            deliveryQuery={deliveryQuery}
                            deliveryData={editDelivery}
                            closeDialog={closeDialog}
                        />
                    )}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
