import moment from 'moment';

import noImgFound from '../assets/placeholder.png';

export const handleDateFormat = (date) => {
    if (!date) {
        return '';
    }
    if (date?.includes('T')) {
        const reverseDateFormat = date?.split('T')[0].replaceAll('-', '/').split('/').reverse();
        [reverseDateFormat[0], reverseDateFormat[1]] = [reverseDateFormat[1], reverseDateFormat[0]];
        return reverseDateFormat.join('/');
    } else {
        // const reverseDateFormat = date?.replaceAll('-', '/');
        return date;
        //  moment(reverseDateFormat).format('MM/DD/YYYY');
    }
};
export const handleDateTimeFormat = (date) => {
    if (!date) {
        return '';
    }
    return moment(date).format('MM/DD/YYYY, HH:mm A');
};

export const handleOnError = (e) => {
    e.target.src = noImgFound;
};
