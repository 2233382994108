import React, { useEffect, useState } from 'react';

import NewOrder1 from './NewOrder1';
import ShowCorps from './ShowCorps';
import ShowUsers from './ShowUsers';
import { Spinner } from '@/components/Elements';
import { getAllCorps } from '@/features/corporation/api/CRUD/getAllCorps';
import { Corporation, User } from '@/features/corporation/type';
import { getUsers } from '@/features/users/api/getUsers';


//import ShowUsers from './ShowUsers';

const CreateOrder = ({ closeDialog, selectedUserAction }:any) => {
    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOrder, setIsOrder] = useState<any>({
        reward: true,
        order_type_id: '17',
        store: '',
        address: '',
        delivery_address: '',
        lat: '',
        long: ''
    });
    const [users, setUsers] = useState<User[]>([]);
    const [corps, setCorps] = useState<Corporation[]>([]);
    const [orderForUser, setOrderForUser] = useState<boolean>(false);
    const [selectedData, setSelected] = useState<any>(false);
    const [newOrder, setNewOrder] = useState<boolean>(false);

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    const getRequeredData = () => {
        setIsLoading(true);
        getUsers().then((data) => setUsers(data));
        getAllCorps().then((data) => setCorps(data));
        setIsLoading(false);
    };

    useEffect(() => {
        getRequeredData();
    }, []);

    if (isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    const filteredUsers = users?.filter((user:any) => {
        return (
            user?.first_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
            user?.last_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
            user?.user_company?.toLowerCase()?.includes(searchVal.toLowerCase())
        );
    });
    const filteredCorps = corps?.filter((user) => {
        return user?.corporate_name?.toLowerCase()?.includes(searchVal.toLowerCase());
    });

    const handleSelected = (val:any) => {
        setSelected(val);
    };

    const handleOrder = (data:any) => {
        const { name, value, type, checked } = data;
        switch (type) {
            case 'checkbox':
                setIsOrder({ ...isOrder, [name]: checked });
                break;
            default:
                setIsOrder({ ...isOrder, [name]: value });
                break;
        }
    };

    const showNewOrder = (show:any) => {
        setNewOrder(show);
    };

    return (
        <div className="modal-dialog">
            {!newOrder ? (
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Create an Order
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => closeDialog()}
                        ></button>
                    </div>
                    {!selectedData && (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    width: 'fit-content',
                                    gap: '1rem',
                                    paddingTop: '10px',
                                }}
                            >
                                <input
                                    type="radio"
                                    name="orderForUser"
                                    id="corp"
                                    checked={!orderForUser}
                                    onChange={() => setOrderForUser(false)}
                                />
                                <label htmlFor="corp">Corporation</label>

                                <input
                                    type="radio"
                                    name="orderForUser"
                                    id="user"
                                    checked={orderForUser}
                                    onChange={() => setOrderForUser(true)}
                                />
                                <label htmlFor="user">User</label>
                            </div>
                            <div className="modal-body create-user">
                                <div className="input-group search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`Search Existing ${orderForUser ? 'User' : 'Corporation'
                                            }`}
                                        value={searchVal}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                    <div
                                        className="input-group-close"
                                        onClick={() => setSearchVal('')}
                                        style={{ zIndex: 10 }}
                                    >
                                        <span className="input-group-text">
                                            <i className="fas fa-times"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {orderForUser ? (
                        <ShowUsers
                            usersData={filteredUsers}
                            showList={searchVal ? true : false}
                            closeDialog={closeDialog}
                            handleSelected={handleSelected}
                            SelectedData={selectedData}
                            handleOrder={handleOrder}
                            orderData={isOrder}
                            setOrderData={setIsOrder}
                            showNewOrder={showNewOrder}
                        />
                    ) : (
                        <ShowCorps
                            usersData={filteredCorps}
                            showList={searchVal ? true : false}
                            closeDialog={closeDialog}
                            handleSelected={handleSelected}
                            SelectedData={selectedData}
                            handleOrder={handleOrder}
                            orderData={isOrder}
                            setOrderData={setIsOrder}
                            showNewOrder={showNewOrder}
                        />
                    )}
                </div>
            ) : (
                <NewOrder1
                    closeDialog={closeDialog}
                    isOrder={isOrder}
                    userData={selectedData}
                    selectedUserAction={selectedUserAction}
                />
            )}
        </div>
    );
};

export default CreateOrder;
