import { Navigate, useRoutes } from 'react-router-dom';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { useAuth } from '@/lib/auth';

const RedirectChecker = () => {
    const auth = useAuth();
    return auth.user ? <Navigate to="/app/users" /> : <Navigate to="/auth/login" />;
};

export const AppRoutes = () => {
    const auth = useAuth();
    const commonRoutes = [
        { path: '/', element: <RedirectChecker /> },
        { path: '*', element: <RedirectChecker /> },
    ];

    const routes = auth.user ? protectedRoutes : publicRoutes;

    const element = useRoutes([...routes, ...commonRoutes]);

    return <>{element}</>;
};
