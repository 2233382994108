// import { CorporateFare, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import * as z from 'zod';

// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Slider from '@mui/material/Slider';
// import { Menu, User } from '../type';
import { Form, InputField } from '@/components/Form';
import { Button, Spinner } from '@/components/Elements';
// import Delete from '@/components/Icons/Delete';
// import { handleDateFormat } from '@/utils/commonHelperFunc';

import { axios } from '@/lib/axios';

const schema = z.object({
    start_date: z.string(),
    end_date: z.string(),
    price_min: z.string(),
    price_max: z.string(),
    location_address: z.string(),
});

type LoginValues = {
    start_date: string;
    end_date: string;
    price_min: string;
    price_max: string;
    location_address: string;
};

const AddTarget = ({ closeDialog, selectedUserAction }) => {
    const [value, setValue] = useState<number[]>([20, 38]);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };

    const intialMenuData = {
        start_date: '',
        end_date: '',
        price_min: '',
        price_max: '',
        location_address: '',
    };
    const [data, setData] = useState(intialMenuData);

    const [orders, setOrders] = useState(true);
    const [customers, setCustomers] = useState(false);
    const [products, setProducts] = useState(false);
    const [loyalty, setLoyalty] = useState(false);
    const [location, setLocation] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const createMenu = async (data) => {
        const { success, message } = await axios.post(``, data);
        return { success, message };
    };

    const handleSubmit = async () => {
        createMenu(data)
            .then(() => closeDialog())
            .catch();
    };

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        Add Targets
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <Form<LoginValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
                            {({ register, formState }) => (
                                <>
                                    <div className="row">
                                        <div className="collect-rewards border-b">
                                            <div className="rewards-right d-flex">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="Orders"
                                                        id="Orders"
                                                        checked={orders}
                                                        value="Orders"
                                                        onClick={() => {
                                                            setOrders(true);
                                                            setCustomers(false);
                                                            setProducts(false);
                                                            setLoyalty(false);
                                                            setLocation(false);
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="Orders"
                                                    >
                                                        Orders
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="Customers"
                                                        id="Customers"
                                                        checked={customers}
                                                        value="Customers"
                                                        onClick={() => {
                                                            setOrders(false);
                                                            setCustomers(true);
                                                            setProducts(false);
                                                            setLoyalty(false);
                                                            setLocation(false);
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="Customers"
                                                    >
                                                        Customers
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="Products"
                                                        id="Products"
                                                        checked={products}
                                                        value="Products"
                                                        onClick={() => {
                                                            setOrders(false);
                                                            setCustomers(false);
                                                            setProducts(true);
                                                            setLoyalty(false);
                                                            setLocation(false);
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="Products"
                                                    >
                                                        Products
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="Loyalty"
                                                        id="Loyalty"
                                                        checked={loyalty}
                                                        value="Loyalty"
                                                        onClick={() => {
                                                            setOrders(false);
                                                            setCustomers(false);
                                                            setProducts(false);
                                                            setLoyalty(true);
                                                            setLocation(false);
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="Loyalty"
                                                    >
                                                        Loyalty
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="Location"
                                                        id="Location"
                                                        checked={location}
                                                        value="Location"
                                                        onClick={() => {
                                                            setOrders(false);
                                                            setCustomers(false);
                                                            setProducts(false);
                                                            setLoyalty(false);
                                                            setLocation(true);
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="Location"
                                                    >
                                                        Location
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 mt-5">
                                            <strong>Date Filter</strong>
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="start_date" className="control-label">
                                                Start Date
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    name="start_date"
                                                    type="date"
                                                    value={data.start_date}
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                    error={formState.errors['start_date']}
                                                    registration={register('start_date')}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="end_date" className="control-label">
                                                End Date
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    name="end_date"
                                                    type="date"
                                                    value={data.end_date}
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                    error={formState.errors['end_date']}
                                                    registration={register('end_date')}
                                                />
                                            </div>
                                        </div>

                                        <hr />

                                        {orders && (
                                            <>
                                                <div className="col-md-6 mt-4">
                                                    <strong>Orders Value</strong>
                                                </div>

                                                <div className="col-md-3 mt-2">
                                                    <div className="form-group">
                                                        <InputField
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="price_min"
                                                            placeholder="$50"
                                                            value={data.price_min}
                                                            error={formState.errors['price_min']}
                                                            registration={register('price_min')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-2">
                                                    <div className="form-group">
                                                        <InputField
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="price_max"
                                                            placeholder="$500"
                                                            value={data.price_max}
                                                            error={formState.errors['price_max']}
                                                            registration={register('price_max')}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-2">
                                                    <div className="form-group">
                                                        <Slider
                                                            value={value}
                                                            onChange={handleSliderChange}
                                                            valueLabelDisplay="auto"
                                                        />
                                                    </div>
                                                </div>

                                                <hr />

                                                <div className="col-md-6 mt-4">
                                                    <strong>Order Type</strong>
                                                </div>

                                                <div className="col-md-6 mt-4 flex gap-2">
                                                    <div className="all-bx Draft">
                                                        <label className="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                id="User"
                                                                name="User"
                                                                value="User"
                                                                checked={false}
                                                                onChange={() => {}}
                                                            />
                                                            <span>Pickup</span>
                                                        </label>
                                                    </div>
                                                    <div className="all-bx Draft">
                                                        <label className="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                id="User"
                                                                name="User"
                                                                value="User"
                                                                checked={false}
                                                                onChange={() => {}}
                                                            />
                                                            <span>Delivery</span>
                                                        </label>
                                                    </div>
                                                    <div className="all-bx Draft">
                                                        <label className="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                id="User"
                                                                name="User"
                                                                value="User"
                                                                checked={false}
                                                                onChange={() => {}}
                                                            />
                                                            <span>In-Store</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {customers && (
                                            <>
                                                <div className="col-md-6 mt-4">
                                                    <strong>Orders Value</strong>
                                                </div>

                                                <div className="col-md-3 mt-2">
                                                    <div className="form-group">
                                                        <InputField
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="price_min"
                                                            placeholder="$50"
                                                            value={data.price_min}
                                                            error={formState.errors['price_min']}
                                                            registration={register('price_min')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-2">
                                                    <div className="form-group">
                                                        <InputField
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="price_max"
                                                            placeholder="$500"
                                                            value={data.price_max}
                                                            error={formState.errors['price_max']}
                                                            registration={register('price_max')}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-2">
                                                    <div className="form-group">
                                                        <Slider
                                                            value={value}
                                                            onChange={handleSliderChange}
                                                            valueLabelDisplay="auto"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {products && (
                                            <>
                                                <div className="col-md-6 mt-4">
                                                    <strong>Ordered Products</strong>
                                                </div>

                                                <div className="col-md-3 mt-2">
                                                    <div className="form-group">
                                                        <InputField
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="price_min"
                                                            placeholder="$50"
                                                            value={data.price_min}
                                                            error={formState.errors['price_min']}
                                                            registration={register('price_min')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-2">
                                                    <div className="form-group">
                                                        <InputField
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="price_max"
                                                            placeholder="$500"
                                                            value={data.price_max}
                                                            error={formState.errors['price_max']}
                                                            registration={register('price_max')}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-2">
                                                    <div className="form-group">
                                                        <Slider
                                                            value={value}
                                                            onChange={handleSliderChange}
                                                            valueLabelDisplay="auto"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {loyalty && (
                                            <>
                                                <div className="col-md-6 mt-4">
                                                    <strong>Loyalty Points</strong>
                                                </div>

                                                <div className="col-md-3 mt-2">
                                                    <div className="form-group">
                                                        <InputField
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="price_min"
                                                            placeholder="$50"
                                                            value={data.price_min}
                                                            error={formState.errors['price_min']}
                                                            registration={register('price_min')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-2">
                                                    <div className="form-group">
                                                        <InputField
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="price_max"
                                                            placeholder="$500"
                                                            value={data.price_max}
                                                            error={formState.errors['price_max']}
                                                            registration={register('price_max')}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-2">
                                                    <div className="form-group">
                                                        <Slider
                                                            value={value}
                                                            onChange={handleSliderChange}
                                                            valueLabelDisplay="auto"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {location && (
                                            <>
                                                <div className="col-md-12 mt-2">
                                                    <label
                                                        htmlFor="location_address"
                                                        className="control-label"
                                                    >
                                                        Location Address
                                                    </label>
                                                    <div className="form-group">
                                                        <InputField
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            name="location_address"
                                                            placeholder="3107 S. Grand, St. Louis, MO 63118"
                                                            value={data.location_address}
                                                            error={
                                                                formState.errors['location_address']
                                                            }
                                                            registration={register(
                                                                'location_address'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div
                                        className="modal-footer"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            startIcon={<CheckCircleOutlineIcon />}
                                            style={{ width: 'fit-content' }}
                                            onClick={() => {
                                                selectedUserAction({
                                                    action: 'send-target',
                                                    data: {
                                                        ...data,
                                                        type: orders
                                                            ? 'Orders'
                                                            : customers
                                                            ? 'Customers'
                                                            : products
                                                            ? 'Products'
                                                            : loyalty
                                                            ? 'Loyalty'
                                                            : location
                                                            ? 'Location'
                                                            : '',
                                                    },
                                                });
                                            }}
                                        >
                                            Add Targets
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => closeDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTarget;
