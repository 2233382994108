// import { AddCircleOutline } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import moment from 'moment';
import editCheck from '../../../assets/edit-check.png';
import { Button, Spinner } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({
    deduction_min_cart: z.string().nonempty('Deduction Min Order is required'),
    deduction_max_dollar: z.string().nonempty('Deduction Max Value is required'),
    coupon_code: z.string().nonempty('Coupon Code is required'),
    coupon_code_uses: z.string().nonempty('Please enter coupoon code uses'),
    coupon_code_used: z.string().nonempty('Please enter coupoon code used'),
    coupon_budget: z.string().nonempty('Please enter Budget'),
});

type LoginValues = {
    deduction_min_cart: '';
    deduction_max_dollar: '';
    coupon_code: '';
    coupon_code_uses: '';
    coupon_code_used: '';
    coupon_budget: '';
};

const EditCoupon = ({ getCoupon, couponData, closeDialog }) => {
    const intialCouponData = {
        id: couponData?.id,
        active: couponData?.active,
        coupon_days_id: couponData?.days?.id,
        coupon_days: couponData?.days?.coupon_days,
        coupon_code__type_name: couponData?.coupon_code__type_name,
        coupon_days_start: '12:06:30',
        coupon_days_end: '08:16:20',
        deduction_id: couponData?.deduction?.id,
        deduction_mode: 'percent',
        deduction_type: couponData?.deduction?.deduction_type,
        deduction_amount: couponData?.deduction?.deduction_amount,
        deduction_min_cart: couponData?.deduction?.deduction_min_cart,
        deduction_max_dollar: couponData?.deduction?.deduction_max_dollar,
        coupon_type_id: couponData?.type?.id,
        coupon_code: couponData?.coupon_code,
        coupon_name: couponData?.coupon_name,
        coupon_description: couponData?.coupon_description,
        coupon_code_uses: couponData?.coupon_code_uses,
        coupon_code_used: couponData?.coupon_code_used,
        coupon_budget: couponData?.coupon_budget,
        coupon_location_id: couponData?.coupon_location_id,
        coupon_mode_id: couponData?.coupon_mode_id,
        coupon_ambassador_id: couponData?.coupon_ambassador_id,
        coupon_user_id: couponData?.coupon_user_id,
        coupon_activation_time: couponData?.coupon_activation_time,
        coupon_activation_time_date: '',
        coupon_activation_time_time: '',
        coupon_expiration_time: couponData?.coupon_expiration_time,
        coupon_expiration_time_date: '',
        coupon_expiration_time_time: '',
    };

    const [data, setData] = useState(intialCouponData);
    const [locations, setLocations] = useState();
    const [couponModes, setCouponModes] = useState();
    const [userList, setUserList] = useState();
    const [monday, setMonday] = useState(
        data?.coupon_days.split('').find((e) => e == 2) ? '2' : ''
    );
    const [tuesday, setTuesday] = useState(
        data?.coupon_days.split('').find((e) => e == 3) ? '3' : ''
    );
    const [wednesday, setWednesday] = useState(
        data?.coupon_days.split('').find((e) => e == 4) ? '4' : ''
    );
    const [thursday, setThursday] = useState(
        data?.coupon_days.split('').find((e) => e == 5) ? '5' : ''
    );
    const [friday, setFriday] = useState(
        data?.coupon_days.split('').find((e) => e == 6) ? '6' : ''
    );
    const [saturday, setSaturday] = useState(
        data?.coupon_days.split('').find((e) => e == 7) ? '7' : ''
    );
    const [sunday, setSunday] = useState(
        data?.coupon_days.split('').find((e) => e == 1) ? '1' : ''
    );

    useEffect(() => {
        getAllLocations();
        getCouponModes();
        getAllUsers();
    }, []);

    //get all locations
    const getAllLocations = async () => {
        const { data } = await axios.get(`v1/admin/locations/get-locations`);
        setLocations(data.locations);
    };

    //////

    //get all coupon modes
    const getCouponModes = async () => {
        const { data } = await axios.get(`v1/admin/miscellaneous/get-modes`);
        setCouponModes(data.mode);
    };

    //////

    //get all users
    const getAllUsers = async () => {
        const { data } = await axios.post(`v1/admin/users/get-users`);
        setUserList(data.users);
    };

    //////

    const updateCoupon = async (data) => {
        const updateData = {
            days: {
                id: data?.coupon_days_id,
                coupon_days: monday + tuesday + wednesday + thursday + friday + saturday + sunday,
                coupon_days_start: data?.coupon_days_start,
                coupon_days_end: data?.coupon_days_end,
            },

            deduction: {
                id: data?.deduction_id,
                deduction_mode: data?.deduction_mode,
                deduction_type: data?.deduction_type,
                deduction_amount: data?.deduction_min_cart,
                deduction_min_cart: data?.deduction_min_cart,
                deduction_max_dollar: data?.deduction_max_dollar,
            },

            coupon_type_id: data?.coupon_type_id,
            coupon_code: data?.coupon_code,
            coupon_name: data?.coupon_code,
            coupon_description: data?.coupon_code,
            coupon_code_uses: +data?.coupon_code_uses,
            coupon_code_used: +data?.coupon_code_used,
            coupon_budget: +data?.coupon_budget,
            coupon_location_id: data?.coupon_location_id,
            coupon_mode_id: data?.coupon_mode_id,
            coupon_user_id: data?.coupon_user_id,
            coupon_activation_time: data?.coupon_activation_time_date
                ? data?.coupon_activation_time_date + ' ' + data?.coupon_activation_time_time
                : data?.coupon_activation_time.split('T')[0] +
                  ' ' +
                  moment.utc(data.coupon_activation_time).format('HH:mm:ss'),
            coupon_expiration_time: data?.coupon_expiration_time_date
                ? data?.coupon_expiration_time_date + ' ' + data?.coupon_expiration_time_time
                : data?.coupon_expiration_time.split('T')[0] +
                  ' ' +
                  moment.utc(data.coupon_expiration_time).format('HH:mm:ss'),
        };

        Object.keys(updateData).forEach((key) => {
            if (updateData[key] === '') {
                delete updateData[key];
            }
        });

        const { success, message } = await axios.post(`v1/admin/coupon-code/update-coupon-code/`, {
            ...updateData,
            id: data?.id,
        });
        return { success, message };
    };

    const handleSubmit = async () => {
        updateCoupon(data)
            .then(() => {
                getCoupon();
                showSuccess();
            })
            .catch();
        closeDialog();
    };

    const showSuccess = () => {
        useNotificationStore.getState().addNotification({
            type: 'success',
            title: 'Success',
            message: 'Coupon Edited Successfully!',
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    return locations == null ? (
        <>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="h-full w-full flex items-center justify-center">
                        <Spinner size="xl" />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="invoiceModalLabel">
                        Edit code #{data?.id}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="add-user-form">
                        <Form<LoginValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
                            {({ register, formState }) => (
                                <>
                                    <div
                                        className="info-header d-flex justify-content-between align-items-baseline"
                                        style={{ paddingTop: '0px', paddingBottom: '5px' }}
                                    >
                                        <div className="left-info">
                                            <h4>Details</h4>
                                        </div>
                                        <div className="flex gap-3">
                                            <label htmlFor="active">Active:</label>
                                            {data?.active ? 'Yes' : 'No'}
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                                    name="active"
                                                    defaultChecked={data?.active || false}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="coupon_type_id"
                                                className="control-label"
                                            >
                                                Type
                                            </label>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="coupon_type_id"
                                                    required
                                                    onChange={handleChange}
                                                    value={data.coupon_type_id}
                                                    error={formState.errors['coupon_type_id']}
                                                    registration={register('coupon_type_id')}
                                                >
                                                    <option value="1">Global</option>
                                                    <option value="2">Location</option>
                                                    <option value="3">Mode</option>
                                                    <option value="4">User</option>
                                                    <option value="5">Budget</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="coupon_code" className="control-label">
                                                Coupon Code
                                            </label>
                                            <div className="form-group  add-code-cpn">
                                                <InputField
                                                    className="form-control"
                                                    name="coupon_code"
                                                    placeholder="ebfXX45N"
                                                    value={data.coupon_code}
                                                    onChange={handleChange}
                                                    error={formState.errors['coupon_code']}
                                                    registration={register('coupon_code')}
                                                />
                                            </div>
                                        </div>

                                        {data.coupon_type_id == 2 && (
                                            <div className="col-md-12">
                                                <label
                                                    htmlFor="coupon_location_id"
                                                    className="control-label"
                                                >
                                                    Location
                                                </label>
                                                <div className="form-group">
                                                    <select
                                                        className="form-control"
                                                        name="coupon_location_id"
                                                        required
                                                        onChange={handleChange}
                                                        value={data.location_id}
                                                        error={formState.errors['location_id']}
                                                        registration={register('location_id')}
                                                    >
                                                        <option value="">Select or Type</option>
                                                        {locations.map(({ id, location_name }) => (
                                                            <option value={id} key={id}>
                                                                {location_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}

                                        {data.coupon_type_id == 3 && (
                                            <div className="col-md-12">
                                                <label
                                                    htmlFor="coupon_location_id"
                                                    className="control-label"
                                                >
                                                    Choose Mode
                                                </label>
                                                <div className="form-group">
                                                    <select
                                                        className="form-control"
                                                        name="coupon_mode_id"
                                                        required
                                                        onChange={handleChange}
                                                        value={data.coupon_mode_id}
                                                        error={formState.errors['coupon_mode_id']}
                                                        registration={register('coupon_mode_id')}
                                                    >
                                                        <option value="">Select or Type</option>
                                                        {couponModes.map(({ id, mode_name }) => (
                                                            <option value={id} key={id}>
                                                                {mode_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}

                                        {data.coupon_type_id == 4 && (
                                            <div className="col-md-12">
                                                <label
                                                    htmlFor="coupon_location_id"
                                                    className="control-label"
                                                >
                                                    User Name
                                                </label>
                                                <div className="form-group">
                                                    <select
                                                        className="form-control"
                                                        name="coupon_user_id"
                                                        required
                                                        onChange={handleChange}
                                                        value={data.coupon_user_id}
                                                        error={formState.errors['coupon_user_id']}
                                                        registration={register('coupon_user_id')}
                                                    >
                                                        <option value="">Select or Type</option>
                                                        {userList.map(
                                                            ({ id, first_name, last_name }) => (
                                                                <option value={id} key={id}>
                                                                    {first_name} {last_name}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        )}

                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_activation_time_date"
                                                className="control-label"
                                            >
                                                Activation Date
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    name="coupon_activation_time_date"
                                                    type="date"
                                                    value={
                                                        data.coupon_activation_time_date ||
                                                        data.coupon_activation_time.split('T')[0]
                                                    }
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_activation_time_time"
                                                className="control-label"
                                            >
                                                Activation Time
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    name="coupon_activation_time_time"
                                                    type="time"
                                                    value={
                                                        data.coupon_activation_time_time ||
                                                        moment
                                                            .utc(data.coupon_activation_time)
                                                            .format('HH:mm')
                                                    }
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_expiration_time_date"
                                                className="control-label"
                                            >
                                                Expiration Date
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    name="coupon_expiration_time_date"
                                                    type="date"
                                                    value={
                                                        data.coupon_expiration_time_date ||
                                                        data.coupon_expiration_time.split('T')[0]
                                                    }
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_expiration_time_time"
                                                className="control-label"
                                            >
                                                Expiration Time
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    name="coupon_expiration_time_time"
                                                    type="time"
                                                    value={
                                                        data.coupon_expiration_time_time ||
                                                        moment
                                                            .utc(data.coupon_expiration_time)
                                                            .format('HH:mm')
                                                    }
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <label
                                                htmlFor="deduction_type"
                                                className="control-label"
                                            >
                                                Deduction Type
                                            </label>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="deduction_type"
                                                    required
                                                    onChange={handleChange}
                                                    value={data.deduction_type}
                                                    error={formState.errors['deduction_type']}
                                                    registration={register('deduction_type')}
                                                >
                                                    <option value="">Select or Type</option>
                                                    <option value="subtotal">SubTotal</option>
                                                    <option value="delivery">Delivery</option>
                                                    <option value="percentage">Percentage</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label
                                                htmlFor="deduction_min_cart"
                                                className="control-label"
                                            >
                                                Deduction Min Order
                                            </label>
                                            <div className="form-group  add-code-cpn">
                                                <InputField
                                                    className="form-control"
                                                    name="deduction_min_cart"
                                                    placeholder="$50"
                                                    value={data.deduction_min_cart}
                                                    onChange={handleChange}
                                                    error={formState.errors['deduction_min_cart']}
                                                    registration={register('deduction_min_cart')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="deduction_max_dollar"
                                                className="control-label"
                                            >
                                                Deduction Max Value
                                            </label>
                                            <div className="form-group  add-code-cpn">
                                                <InputField
                                                    className="form-control"
                                                    name="deduction_max_dollar"
                                                    placeholder="$100"
                                                    value={data.deduction_max_dollar}
                                                    onChange={handleChange}
                                                    error={formState.errors['deduction_max_dollar']}
                                                    registration={register('deduction_max_dollar')}
                                                />
                                            </div>
                                        </div>
                                        {data.deduction_type == 'percentage' && (
                                            <div className="col-md-3">
                                                <label
                                                    htmlFor="deduction_max_dollar"
                                                    className="control-label"
                                                >
                                                    Deduction Percentage
                                                </label>
                                                <div className="form-group  add-code-cpn">
                                                    <InputField
                                                        className="form-control"
                                                        name="deduction_percentage"
                                                        placeholder="20%"
                                                        value={data.deduction_percentage}
                                                        onChange={handleChange}
                                                        error={
                                                            formState.errors['deduction_percentage']
                                                        }
                                                        registration={register(
                                                            'deduction_percentage'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="info-header"
                                        style={{ paddingTop: '0px', paddingBottom: '5px' }}
                                    >
                                        <div className="left-info">
                                            <h4 className="pb-2">Advanced Options</h4>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_code_uses"
                                                className="control-label"
                                            >
                                                Uses
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="coupon_code_uses"
                                                    placeholder="$100"
                                                    value={data.coupon_code_uses}
                                                    onChange={handleChange}
                                                    error={formState.errors['coupon_code_uses']}
                                                    registration={register('coupon_code_uses')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_code_used"
                                                className="control-label"
                                            >
                                                Used
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="coupon_code_used"
                                                    placeholder="$100"
                                                    value={data.coupon_code_used}
                                                    onChange={handleChange}
                                                    error={formState.errors['coupon_code_used']}
                                                    registration={register('coupon_code_used')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="coupon_budget"
                                                className="control-label"
                                            >
                                                Budget
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="coupon_budget"
                                                    placeholder="$100"
                                                    value={data.coupon_budget}
                                                    onChange={handleChange}
                                                    error={formState.errors['coupon_budget']}
                                                    registration={register('coupon_budget')}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex gap-3">
                                            <label htmlFor="active">Always Available:</label>
                                            {data?.active ? 'Yes' : 'No'}
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                                    name="active"
                                                    defaultChecked={data?.active || false}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="flex gap-4">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="monday"
                                                        name="monday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e) => e == 2)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => {
                                                            setMonday(e.target.checked ? '2' : '');
                                                        }}
                                                    />
                                                    <span>Monday</span>
                                                </label>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="tuesday"
                                                        name="tuesday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e) => e == 3)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => {
                                                            setTuesday(e.target.checked ? '3' : '');
                                                        }}
                                                    />
                                                    <span>Tuesday</span>
                                                </label>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="wednesday"
                                                        name="wednesday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e) => e == 4)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => {
                                                            setWednesday(
                                                                e.target.checked ? '4' : ''
                                                            );
                                                        }}
                                                    />
                                                    <span>Wednesday</span>
                                                </label>
                                            </div>
                                            <div className="flex gap-4">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="thursday"
                                                        name="thursday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e) => e == 5)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => {
                                                            setThursday(
                                                                e.target.checked ? '5' : ''
                                                            );
                                                        }}
                                                    />
                                                    <span>Thursday</span>
                                                </label>

                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="friday"
                                                        name="friday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e) => e == 6)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => {
                                                            setFriday(e.target.checked ? '6' : '');
                                                        }}
                                                    />
                                                    <span>Friday</span>
                                                </label>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="saturday"
                                                        name="saturday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e) => e == 7)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => {
                                                            setSaturday(
                                                                e.target.checked ? '7' : ''
                                                            );
                                                        }}
                                                    />
                                                    <span>Saturday</span>
                                                </label>
                                            </div>
                                            <div className="flex gap-4">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="sunday"
                                                        name="sunday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e) => e == 1)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => {
                                                            setSunday(e.target.checked ? '1' : '');
                                                        }}
                                                    />
                                                    <span>Sunday</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_days_start"
                                                className="control-label"
                                            >
                                                Start Active Time
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    name="coupon_days_start"
                                                    type="time"
                                                    value={data.coupon_days_start}
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_days_end"
                                                className="control-label"
                                            >
                                                End Active Time
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    name="coupon_days_end"
                                                    type="time"
                                                    value={data.coupon_days_end}
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="modal-footer"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            startIcon={<img src={editCheck} alt="editCheck" />}
                                            style={{ width: 'fit-content' }}
                                            // onClick={() => handleSubmit()}
                                        >
                                            Save Changes
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => closeDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCoupon;
